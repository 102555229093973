import React from 'react';
import PropTypes from 'prop-types'
import cx from 'classnames'
import _ from 'lodash'
import FontAwesomeIcon from '@fortawesome/react-fontawesome'
import {faBook, faCalculator, faListOl, faCogs, faGlasses, faLightbulb, faPaperPlane, faPalette, faPuzzlePiece, faDoorOpen, faEye, faArrowUp, faLock, faBookOpen, faCheckCircle, faAsterisk, faClipboardList, faEdit, faHeart, faCloud, faComments, faUsers, faSmile, faExclamation, faPencilAlt, faMusic } from '@fortawesome/fontawesome-free-solid'

import styles from './styles.css'
import {Colors} from '../colors'
import Tile from '../tile'

const demoStringDictionary = {
  "instructions" : "Select a quadrant on the model to explore the associated clusters.",
  "selected" : "Traits you selected",
  "implied" : "Implied traits",
  "unselected" : "Unselected traits"
}

export default class Clusters extends React.Component
 {
  constructor(props) {
    super(props);
    this.renderLabels = this.renderLabels.bind(this);
    this.t = this.t.bind(this);
    this._internalt = this._internalt.bind(this);

    this.tabsList = {
      A: {
        label: 'blue',
        content:
        <span>
          <Tile className={styles.traits}>
            <FontAwesomeIcon icon={["fas","book"]} className={styles.clustersIcon} style={{color: Colors['blue']}}/>
            <p className={this._traitClasses("factual")}> { this.t("factual") } </p>
            <p className={this._traitClasses("critical")}> { this.t("critical") } </p>
            <p className={this._traitClasses("rational")}> { this.t("rational") } </p>
            <p className={this._traitClasses("realistic")}> { this.t("realistic") } </p>
            <p className={this._traitClasses("rigorous_thinking")}> { this.t("rigorous thinking") } </p>
            <p className={this._traitClasses("knows_the_basis")}> { this.t("knows_the_basis") } </p>
          </Tile>

          <Tile className={styles.traits}>
            <FontAwesomeIcon icon={["fas","calculator"]} className={styles.clustersIcon} style={{color: Colors['blue']}}/>
            <p className={this._traitClasses("financial")}> { this.t("financial") } </p>
            <p className={this._traitClasses("numerical")}> { this.t("numerical") } </p>
            <p className={this._traitClasses("quantitative")}> { this.t("quantitative") } </p>
            <p className={this._traitClasses("mathematical")}> { this.t("mathematical") } </p>
            <p className={this._traitClasses("knows_about_money")}> { this.t("knows_about_money") } </p>
          </Tile>

          <Tile className={styles.traits}>
            <FontAwesomeIcon icon={["fas","cogs"]} className={styles.clustersIcon} style={{color: Colors['blue']}}/>
            <p className={this._traitClasses("technical")}> { this.t("technical") } </p>
          </Tile>

          <Tile className={styles.traits}>
            <FontAwesomeIcon icon={["fas","list-ol"]} className={styles.clustersIcon} style={{color: Colors['blue']}}/>
            <p className={this._traitClasses("logical")}> { this.t("logical") } </p>
            <p className={this._traitClasses("analytical")}> { this.t("analytical") } </p>
            <p className={this._traitClasses("problem_solving")}> { this.t("problem solving") } </p>
            <p className={this._traitClasses("test_and_prove")}> { this.t("test_and_prove") } </p>
            <p className={this._traitClasses("knows_how_things_work")}> { this.t("knows_how_things_work") } </p>
          </Tile>

          <Tile className={styles.traits}>
            <FontAwesomeIcon icon={["fas","glasses"]} className={styles.clustersIcon} style={{color: Colors['blue']}}/>
            <p className={this._traitClasses("intellectual_thinking")}> { this.t("intellectual_thinking") } </p>
          </Tile>
        </span>,
        quad:
          <svg width="80" height="80" viewBox="0 0 130 130">
            <path d="M0,129.35A129.35,129.35,0,0,1,129.35,0V129.48" style={{fill: Colors['blue']}}/>
          </svg>
      },
      D: {
        label: 'yellow',
        content:
        <span>
          <Tile className={styles.traits}>
            <FontAwesomeIcon icon={["fas","lightbulb"]} className={styles.clustersIcon} style={{color: Colors['yellow']}}/>
            <p className={this._traitClasses("originates")}> { this.t("originates") } </p>
            <p className={this._traitClasses("imaginative")}> { this.t("imaginative") } </p>
            <p className={this._traitClasses("innovative")}> { this.t("innovative") } </p>
            <p className={this._traitClasses("creative")}> { this.t("creative") } </p>
            <p className={this._traitClasses("curious")}> { this.t("curious") } </p>
          </Tile>

          <Tile className={styles.traits}>
            <FontAwesomeIcon icon={["fas","paper-plane"]} className={styles.clustersIcon} style={{color: Colors['yellow']}}/>
            <p className={this._traitClasses("playful")}> { this.t("playful") } </p>
            <p className={this._traitClasses("breaks_rules")}> { this.t("breaks_rules") } </p>
            <p className={this._traitClasses("risk_taking")}> { this.t("risk_taking") } </p>
            <p className={this._traitClasses("original")}> { this.t("original") } </p>
          </Tile>

          <Tile className={styles.traits}>
            <FontAwesomeIcon icon={["fas","palette"]} className={styles.clustersIcon} style={{color: Colors['yellow']}}/>
            <p className={this._traitClasses("artistic")}> { this.t("artistic") } </p>
            <p className={this._traitClasses("spatial")}> { this.t("spatial") } </p>
            <p className={this._traitClasses("visual")}> { this.t("visual") } </p>
          </Tile>

          <Tile className={styles.traits}>
          <FontAwesomeIcon icon={["fas","puzzle-piece"]} className={styles.clustersIcon} style={{color: Colors['yellow']}}/>
            <p className={this._traitClasses("integration")}> { this.t("integration") } </p>
            <p className={this._traitClasses("synthesizing")}> { this.t("synthesizing") } </p>
            <p className={this._traitClasses("holistic")}> { this.t("holistic") } </p>
          </Tile>

          <Tile className={styles.traits}>
            <FontAwesomeIcon icon={["fas","eye"]} className={styles.clustersIcon} style={{color: Colors['yellow']}}/>
            <p className={this._traitClasses("conceptual")}> { this.t("conceptual") } </p>
            <p className={this._traitClasses("metaphorical")}> { this.t("metaphorical") } </p>
          </Tile>

          <Tile className={styles.traits}>
            <FontAwesomeIcon icon={["fas","exclamation"]} className={styles.clustersIcon} style={{color: Colors['yellow']}}/>
            <p className={this._traitClasses("likes_surprises")}> { this.t("likes_surprises") } </p>
            <p className={this._traitClasses("simultaneous")}> { this.t("simultaneous") } </p>
            <p className={this._traitClasses("impetuous")}> { this.t("impetuous") } </p>
          </Tile>


          <Tile className={styles.traits}>
            <FontAwesomeIcon icon={["fas","arrow-up"]} className={styles.clustersIcon} style={{color: Colors['yellow']}}/>
            <p className={this._traitClasses("strategic")}> { this.t("strategic") } </p>
            <p className={this._traitClasses("future_oriented")}> { this.t("future_oriented") } </p>
            <p className={this._traitClasses("imaginative")}> { this.t("imaginative") } </p>
            <p className={this._traitClasses("intuitive_solutions")}> { this.t("intuitive_solutions") } </p>
          </Tile>
        </span>,
        quad:
        <svg width="80" height="80" viewBox="0 0 130 130">
          <path d="M0,129.22V0A129.35,129.35,0,0,1,129.35,129.35" style={{fill: Colors['yellow']}} />
        </svg>

      },
      B: {
        label: 'green',
        content:
        <span>
          <Tile className={styles.traits}>
            <FontAwesomeIcon icon={["fas","lock"]} className={styles.clustersIcon} style={{color: Colors['green']}}/>
            <p className={this._traitClasses("traditional")}> { this.t("traditional") } </p>
            <p className={this._traitClasses("conservative")}> { this.t("conservative") } </p>
            <p className={this._traitClasses("safekeeping")}> { this.t("safekeeping") } </p>
            <p className={this._traitClasses("controlled")}> { this.t("controlled") } </p>
            <p className={this._traitClasses("dominant")}> { this.t("dominant") } </p>
          </Tile>

          <Tile className={styles.traits}>
            <FontAwesomeIcon icon={["fas","book-open"]} className={styles.clustersIcon} style={{color: Colors['green']}}/>
            <p className={this._traitClasses("speaker")}> { this.t("speaker") } </p>
            <p className={this._traitClasses("reader")}> { this.t("reader") } </p>
          </Tile>

          <Tile className={styles.traits}>
            <FontAwesomeIcon icon={["fas","check-circle"]} className={styles.clustersIcon} style={{color: Colors['green']}}/>
            <p className={this._traitClasses("reliable")}> { this.t("reliable") } </p>
            <p className={this._traitClasses("implementation")}> { this.t("implementation") } </p>
            <p className={this._traitClasses("gets_things_done")}> { this.t("gets_things_done") } </p>
          </Tile>

          <Tile className={styles.traits}>
            <FontAwesomeIcon icon={["fas","asterisk"]} className={styles.clustersIcon} style={{color: Colors['green']}}/>
            <p className={this._traitClasses("detailed")}> { this.t("detailed") } </p>
          </Tile>

          <Tile className={styles.traits}>
            <FontAwesomeIcon icon={["fas","clipboard-list"]} className={styles.clustersIcon} style={{color: Colors['green']}}/>
            <p className={this._traitClasses("on_time")}> { this.t("on_time") } </p>
            <p className={this._traitClasses("linear")}> { this.t("linear") } </p>
            <p className={this._traitClasses("sequential")}> { this.t("sequential") } </p>
            <p className={this._traitClasses("step_by_step")}> { this.t("step_by_step") } </p>
            <p className={this._traitClasses("procedural")}> { this.t("procedural") } </p>
          </Tile>

          <Tile className={styles.traits}>
            <FontAwesomeIcon icon={["fas","edit"]} className={styles.clustersIcon} style={{color: Colors['green']}}/>
            <p className={this._traitClasses("neat")}> { this.t("neat") } </p>
            <p className={this._traitClasses("organized")}> { this.t("organized") } </p>
            <p className={this._traitClasses("administrative")}> { this.t("administrative") } </p>
            <p className={this._traitClasses("preventative")}> { this.t("preventative") } </p>
            <p className={this._traitClasses("planning")}> { this.t("planning") } </p>
          </Tile>
        </span>,
        quad:
        <svg width="80" height="80" viewBox="0 0 130 130">
          <path d="M129.35.13V129.35A129.35,129.35,0,0,1,0,0" style={{fill: Colors['green']}}/>
        </svg>
      },
      C: {
        label: 'red',
        content:
        <span>
          <Tile className={styles.traits}>
            <FontAwesomeIcon icon={["fas","heart"]} className={styles.clustersIcon} style={{color: Colors['red']}}/>
            <p className={this._traitClasses("emotional")}> { this.t("emotional") } </p>
            <p className={this._traitClasses("feeling")}> { this.t("feeling") } </p>
          </Tile>

          <Tile className={styles.traits}>
            <FontAwesomeIcon icon={["fas","cloud"]} className={styles.clustersIcon} style={{color: Colors['red']}}/>
            <p className={this._traitClasses("spiritual")}> { this.t("spiritual") } </p>
          </Tile>

          <Tile className={styles.traits}>
            <FontAwesomeIcon icon={["fas","comments"]} className={styles.clustersIcon} style={{color: Colors['red']}}/>
            <p className={this._traitClasses("talker")}> { this.t("talker") } </p>
            <p className={this._traitClasses("expressive")}> { this.t("expressive") } </p>
            <p className={this._traitClasses("communicator")}> { this.t("communicator") } </p>
            <p className={this._traitClasses("teacher_trainer")}> { this.t("teacher_trainer") } </p>
          </Tile>

          <Tile className={styles.traits}>
            <FontAwesomeIcon icon={["fas","smile"]} className={styles.clustersIcon} style={{color: Colors['red']}}/>
            <p className={this._traitClasses("symbolic")}> { this.t("symbolic") } </p>
            <p className={this._traitClasses("intuitive_people")}> { this.t("intuitive_people") } </p>
          </Tile>

          <Tile className={styles.traits}>
            <FontAwesomeIcon icon={["fas","pencil-alt"]} className={styles.clustersIcon} style={{color: Colors['red']}}/>
            <p className={this._traitClasses("writer")}> { this.t("writer") } </p>
            <p className={this._traitClasses("reader")}> { this.t("reader") } </p>
          </Tile>

          <Tile className={styles.traits}>
            <FontAwesomeIcon icon={["fas","users"]} className={styles.clustersIcon} style={{color: Colors['red']}}/>
            <p className={this._traitClasses("friendly")}> { this.t("friendly") } </p>
            <p className={this._traitClasses("empathetic")}> { this.t("empathetic") } </p>
            <p className={this._traitClasses("interpersonal")}> { this.t("interpersonal") } </p>
            <p className={this._traitClasses("intuitive_people")}> { this.t("intuitive_people") } </p>
            <p className={this._traitClasses("sensitive_to_others")}> { this.t("sensitive_to_others") } </p>
            <p className={this._traitClasses("people_oriented")}> { this.t("people_oriented") } </p>
            <p className={this._traitClasses("supportive")}> { this.t("supportive") } </p>
          </Tile>

          <Tile className={styles.traits}>
            <FontAwesomeIcon icon={["fas","music"]} className={styles.clustersIcon} style={{color: Colors['red']}}/>
            <p className={this._traitClasses("musical")}> { this.t("musical") } </p>
          </Tile>
        </span>,
        quad:
        <span>
          <svg width="80" height="80" viewBox="0 0 130 130">
            <path d="M129.35.13A129.35,129.35,0,0,1,0,129.48V0" style={{fill: Colors['red']}}/>
          </svg>
        </span>
      }
    };

    const activeTabs = "A";

    const quadStyle = null;

    this.state = {
      tabsItem: this.tabsList[activeTabs],
      active: activeTabs,
    };
  }

  changeTab(item, activeTabs) {
    this.setState({
      tabsItem: item,
      active: activeTabs,
    });
  }

  _traitClasses(trait){

    var traits = this.props.traits[0]

    return cx(styles.trait, {
      [styles.selected]: _.includes(traits.selected, trait),
      [styles.implied]: _.includes(traits.implied, trait)
    })
  }

  _internalt(key){
    const defined_value = demoStringDictionary[key]
    if(defined_value){
      return defined_value
    } else {
      return _.startCase(key)
    }
  }

  renderLabels(props) {

    const label = Object.keys(this.tabsList).map((value, index) => {
      if (value === this.state.active) {
        var active = {
          opacity: '1'
        };
      }

        return (
          <span>
            <div style={active} className={styles.quad}
              onClick={this.changeTab.bind(this, this.tabsList[value], value)}
              key={this.tabsList[value].label}
            >
              {this.tabsList[value].quad}
            </div>
          </span>
        )
    });
    return label;
  }

  t(key){
    if(this.props.t){
      return this.props.t(key)
    } else {
      return this._internalt(key)
    }
  }

  render(props) {
    return (
      <span>
        <div className={styles.clustersWrapper}>
          <div className={styles.flexCenter}>

            <div className={styles.labels}>
              {this.renderLabels()}
            </div>

            <Tile className={styles.traits}>
            <p className={styles.normal}>
              { this.t("instructions") }
            </p>
            <hr/>
            <p className={styles.selected}>{ this.t("selected")}</p>
            <p className={styles.implied}> { this.t("implied") }</p>
            <p>{ this.t("unselected") }</p>
            </Tile>
          </div>
            <Tile border={this.state.tabsItem.label} className={styles.content}>
              {this.state.tabsItem.content}
            </Tile>
        </div>
      </span>
    )
  }
}
