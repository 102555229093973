import React from 'react';

import styles from './styles.css'
import Tile from '../tile'
import Markdown from 'react-markdown/with-html'

export default function Walkaround (props) {

  let maxWidth = {
    maxWidth: props.maxWidth + 'px'
  }

  return (
    <div className={styles.grid} style={maxWidth}>
      <Tile quad="A">
        { typeof(props.A) == 'string' ?
          <Markdown
            escapeHtml={false}
            source={props.A}/> :
          <div>{props.A}</div>
        }
      </Tile>
      <Tile quad="B">
      { typeof(props.B) == 'string' ?
        <Markdown
          escapeHtml={false}
          source={props.B}/> :
        <div>{props.B}</div>
      }
      </Tile>
      <Tile quad="C">
      { typeof(props.C) == 'string' ?
        <Markdown
          escapeHtml={false}
          source={props.C}/> :
        <div>{props.C}</div>
      }
      </Tile>
      <Tile quad="D" className={styles.D}>
      { typeof(props.D) == 'string' ?
        <Markdown
          escapeHtml={false}
          source={props.D}/> :
        <div>{props.D}</div>
      }
      </Tile>
    </div>
  )
}
