import cx from 'classnames'
import React from 'react';
import PropTypes from 'prop-types'
import _ from 'lodash'
import FontAwesomeIcon from '@fortawesome/react-fontawesome'
import {faUndo, faSync} from '@fortawesome/fontawesome-free-solid'

import styles from './styles.css'
import {Colors} from '../colors'
import Tile from '../tile'

export default class FlipCard extends React.Component {

  constructor(props) {
    super();
    this.state = {
      isFlipped: props.flipped
    };
  }

  flip(e) {
    this.setState({
      isFlipped: !this.state.isFlipped
    })
  }

  render(props){

    let cardWrapper = {
      backgroundColor: 'transparent',
      width: this.props.width+'px',
      height: '400px',
      perspective: '1000px'
    }

    let height = {
      height: this.props.height+'px'
    }

    return (

      <div style={cardWrapper}>
        <div className={cx(styles.cardInner, this.state.isFlipped && styles.flipped)}>

          <div style={height} className={styles.cardFront} onClick={(e) => this.flip()}>
            <Tile quad={this.props.quad}>
            <div className={styles.cardContent}>
              {this.props.cardFront}
              <FontAwesomeIcon icon={["fas","undo"]}/>
            </div>
            </Tile>
          </div>

          <div style={height} className={styles.cardBack}  onClick={(e) => this.flip()}>
            <Tile quad={this.props.quad}>
              <div className={styles.cardContent}>
                {this.props.cardBack}
                <FontAwesomeIcon icon={["fas","undo"]}/>
              </div>
            </Tile>
          </div>

        </div>
      </div>
    )
  }
}

FlipCard.propTypes = {
  pos1Label: PropTypes.string,
  pos2Label: PropTypes.string
}
