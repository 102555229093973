import React from 'react'
import _ from 'lodash'
import PropTypes from 'prop-types'


const numericalValidator = (props, propName) => parseInt(props[propName]) != NaN ? null : new Error("Prop`" + propName + "`suppplied to`" + componentName + "`must be numerical")

export class ProfilePolygon extends React.Component{

  static propTypes = {
    /**
      Raw scores on 130pt scale
    */
    scores: PropTypes.shape({
      A: numericalValidator,
      B: numericalValidator,
      C: numericalValidator,
      D: numericalValidator
    }),
    strokeWidth: PropTypes.number,
    strokeColor: PropTypes.string,
    strokeDasharray: PropTypes.string,
    drawCircles: PropTypes.bool,
    circleFill: PropTypes.string
  };


  _percentages(){
    return {
      A: (this.props.scores.A/130) * 100,
      B: (this.props.scores.B/130) * 100,
      C: (this.props.scores.C/130) * 100,
      D: (this.props.scores.D/130) * 100
    }
  }

  _pointsString(){
    let points = this._points()
    return `${points.B} ${points.C} ${points.D} ${points.A}`
  }

  _points(){
    let percents = this._percentages()
    return {
      A: [-percents.A,0],
      B: [0,percents.B],
      C: [percents.C, 0],
      D: [0, -percents.D]
    }
  }

  render(){
    let circles = _.toPairs(this._points()).map((p)=>{
      let key = p[0]
      let xy = p[1]
      let dot_fill = this.props.circleFill || "black"
      return <circle key={key} cx={xy[0]} cy={xy[1]} r="5" stroke="black" fill={dot_fill} />
    })

    return (
      <svg viewBox="-100 -100 200 200" width="100" height="100">
        <g transform="rotate(45)">
          <polygon points={this._pointsString()}
            stroke={this.props.strokeColor || "black"}
            strokeWidth={this.props.strokeWidth || "1"}
            strokeDasharray={this.props.strokeDasharray || "0"} fill="none" />
          { this.props.drawCircles && circles }
        </g>
        {this.props.children}
      </svg>
    )
  }
}
