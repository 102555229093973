import React from 'react';
import styles from './styles.css'

export default function () {

  var year = new Date().getFullYear()

  return (
    <p className={styles.copyright}>&copy; {year} Herrmann Global, LLC</p>
  )
}
