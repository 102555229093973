import React from 'react';
import PropTypes from 'prop-types';

import styles from './styles.css'

export default function Logo (props) {

  let mark =
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 418.17 418.35">
    <title>Herrmann Logo</title>
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_1-2" data-name="Layer 1">
        <path fill="#fdd641" d="M403.55,198.93h-.07l-101.86-.43a14.63,14.63,0,0,1-14.57-14.63V110.28a14.63,14.63,0,0,1,29.25,0v59l70.13.3c-4.39-20.05-16.17-56.15-47-88.09-31.13-32.27-68.28-44.84-88.88-49.59V163.56a14.63,14.63,0,1,1-29.25,0V14.62A14.63,14.63,0,0,1,237.08,0c2.84.22,70.22,6,123.43,61.18S418,180.75,418.15,183.46a14.63,14.63,0,0,1-14.6,15.47Z"/>
        <path fill="#ee374f"  d="M236,418.35a14.64,14.64,0,0,1-14.62-14.63V254.78a14.63,14.63,0,0,1,29.25,0V386.35c20.67-4.81,57.95-17.46,88.88-49.53a184.32,184.32,0,0,0,47-88.08l-70.19.3v59a14.63,14.63,0,1,1-29.25,0V234.47a14.62,14.62,0,0,1,14.57-14.62l101.86-.44h.07a14.64,14.64,0,0,1,14.6,15.47c-.16,2.72-4.46,67.12-57.64,122.25S239.92,418.08,237.08,418.3C236.7,418.33,236.33,418.35,236,418.35Z"/>
        <path fill="#2dc2ee"  d="M14.63,198.93A14.63,14.63,0,0,1,0,183.46c.16-2.71,4.47-67.12,57.64-122.24S178.25.26,181.1,0a14.63,14.63,0,0,1,15.75,14.58V163.56a14.63,14.63,0,1,1-29.25,0V32c-20.67,4.81-58,17.46-88.89,49.53a184.47,184.47,0,0,0-47,88.09l70.19-.3v-59a14.63,14.63,0,0,1,29.25,0v73.59a14.63,14.63,0,0,1-14.56,14.63l-101.87.43Z"/>
        <path fill="#63bf83"  d="M182.22,418.35c-.37,0-.75,0-1.12-.05-2.85-.22-70.23-6-123.44-61.17S.18,237.6,0,234.88a14.64,14.64,0,0,1,14.61-15.47h.06l101.87.44a14.62,14.62,0,0,1,14.56,14.62v73.59a14.63,14.63,0,1,1-29.25,0V249l-70.13-.3c4.4,20.05,16.17,56.15,47,88.08,31.14,32.28,68.28,44.85,88.89,49.59V254.78a14.63,14.63,0,0,1,29.25,0V403.72a14.64,14.64,0,0,1-14.63,14.63Z"/>
      </g>
    </g>
  </svg>

  let full =
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1504.94 418.35">
      <title>Herrmann Logotype</title>
      <g id="Layer_2" data-name="Layer 2">
        <g id="Layer_1-2" data-name="Layer 1">
          <path fill="#fdd641" d="M403.55,198.93h-.07l-101.86-.43a14.63,14.63,0,0,1-14.57-14.63V110.28a14.63,14.63,0,0,1,29.25,0v59l70.13.3c-4.39-20.05-16.17-56.15-47-88.09-31.13-32.27-68.28-44.84-88.88-49.59V163.56a14.63,14.63,0,1,1-29.25,0V14.62A14.63,14.63,0,0,1,237.08,0c2.84.22,70.22,6,123.43,61.18S418,180.75,418.15,183.46a14.63,14.63,0,0,1-14.6,15.47Z"/>
          <path fill="#ee374f" d="M236,418.35a14.64,14.64,0,0,1-14.62-14.63V254.78a14.63,14.63,0,0,1,29.25,0V386.35c20.67-4.81,57.95-17.46,88.88-49.53a184.32,184.32,0,0,0,47-88.08l-70.19.3v59a14.63,14.63,0,1,1-29.25,0V234.47a14.62,14.62,0,0,1,14.57-14.62l101.86-.44h.07a14.64,14.64,0,0,1,14.6,15.47c-.16,2.72-4.46,67.12-57.64,122.25S239.92,418.08,237.08,418.3C236.7,418.33,236.33,418.35,236,418.35Z"/>
          <path fill="#2dc2ee" d="M14.63,198.93A14.63,14.63,0,0,1,0,183.46c.16-2.71,4.47-67.12,57.64-122.24S178.25.26,181.1,0a14.63,14.63,0,0,1,15.75,14.58V163.56a14.63,14.63,0,1,1-29.25,0V32c-20.67,4.81-58,17.46-88.89,49.53a184.47,184.47,0,0,0-47,88.09l70.19-.3v-59a14.63,14.63,0,0,1,29.25,0v73.59a14.63,14.63,0,0,1-14.56,14.63l-101.87.43Z"/>
          <path fill="#63bf83" d="M182.22,418.35c-.37,0-.75,0-1.12-.05-2.85-.22-70.23-6-123.44-61.17S.18,237.6,0,234.88a14.64,14.64,0,0,1,14.61-15.47h.06l101.87.44a14.62,14.62,0,0,1,14.56,14.62v73.59a14.63,14.63,0,1,1-29.25,0V249l-70.13-.3c4.4,20.05,16.17,56.15,47,88.08,31.14,32.28,68.28,44.85,88.89,49.59V254.78a14.63,14.63,0,0,1,29.25,0V403.72a14.64,14.64,0,0,1-14.63,14.63Z"/>
          <path fill="#4d4f4e" d="M578.81,299.37V223.66h-90v75.71H458.5V126.43h30.34V196.7h90V126.43h30.34V299.37Z"/>
          <path fill="#4d4f4e" d="M690.3,302.48A72.34,72.34,0,0,1,664,297.82a59.08,59.08,0,0,1-20.74-13.36,61.83,61.83,0,0,1-13.61-20.87,71.2,71.2,0,0,1-4.93-27,70.11,70.11,0,0,1,4.67-25.53,64,64,0,0,1,84.26-35,56,56,0,0,1,19.45,14.13,63.12,63.12,0,0,1,12.32,21.52A83.37,83.37,0,0,1,749.67,239v6.74H653.22A40.75,40.75,0,0,0,657,258.92a37.22,37.22,0,0,0,8,11,36.39,36.39,0,0,0,12.06,7.52,43.18,43.18,0,0,0,15.81,2.73,55.15,55.15,0,0,0,19.19-3.64,44.89,44.89,0,0,0,16.59-10.37l12.45,17.89a63.5,63.5,0,0,1-22.82,13.75A83.52,83.52,0,0,1,690.3,302.48Zm33.19-76.23A37,37,0,0,0,721,215a33.34,33.34,0,0,0-17.24-18.54q-6.48-3.12-15.81-3.11a35.6,35.6,0,0,0-15,3,32.23,32.23,0,0,0-10.76,7.78,35.64,35.64,0,0,0-6.61,10.63,35.14,35.14,0,0,0-2.6,11.53Z"/>
          <path fill="#4d4f4e" d="M763.67,299.37V174.14H790.9v18.15a62.1,62.1,0,0,1,17.89-15.17A45.12,45.12,0,0,1,831.6,171v27a39.71,39.71,0,0,0-8.29-.77,35.47,35.47,0,0,0-9.21,1.29,47.92,47.92,0,0,0-9.33,3.63,41.83,41.83,0,0,0-8.17,5.45,27.7,27.7,0,0,0-5.7,6.48v85.3Z"/>
          <path fill="#4d4f4e" d="M840.68,299.37V174.14H867.9v18.15a62.23,62.23,0,0,1,17.89-15.17A45.13,45.13,0,0,1,908.61,171v27a39.87,39.87,0,0,0-8.3-.77,35.34,35.34,0,0,0-9.2,1.29,47.77,47.77,0,0,0-9.34,3.63,42.13,42.13,0,0,0-8.17,5.45,27.7,27.7,0,0,0-5.7,6.48v85.3Z"/>
          <path fill="#4d4f4e" d="M1071.43,299.37V217.7q0-10.11-4.8-16.34t-15.94-6.22a30.37,30.37,0,0,0-17,5.19,41.52,41.52,0,0,0-12.06,11.4v87.64H994.42V217.7q0-10.11-4.66-16.34t-15.82-6.22a29.22,29.22,0,0,0-16.85,5.32,50,50,0,0,0-12.19,11.27v87.64H917.68V174.14H944.9V191a42.3,42.3,0,0,1,6-6.35,49.37,49.37,0,0,1,9.33-6.48,67.91,67.91,0,0,1,11.93-5.06A44.89,44.89,0,0,1,985.87,171q14,0,22.56,6.35a31.6,31.6,0,0,1,11.66,16.46,44.54,44.54,0,0,1,6.88-8.29,50.21,50.21,0,0,1,9.85-7.39A55.22,55.22,0,0,1,1049,173a48.27,48.27,0,0,1,13.62-1.94q17.1,0,26.57,9.33t9.47,28.52v90.49Z"/>
          <path fill="#4d4f4e" d="M1209.11,299.37V282.26a54.32,54.32,0,0,1-17.5,14.78,47.57,47.57,0,0,1-22.69,5.44,55.47,55.47,0,0,1-22.17-4.41,48.05,48.05,0,0,1-17.63-13,61.69,61.69,0,0,1-11.54-20.74,86,86,0,0,1-4.15-27.75,84.3,84.3,0,0,1,4.15-27.22,61.79,61.79,0,0,1,11.54-20.74,50,50,0,0,1,17.5-13.1,53.91,53.91,0,0,1,22.3-4.53,49.29,49.29,0,0,1,22.3,5.18,50.31,50.31,0,0,1,17.89,15.3V174.14h27.22V299.37Zm-32.15-21a35.92,35.92,0,0,0,9.46-1.3,41,41,0,0,0,9.08-3.63,45.07,45.07,0,0,0,7.77-5.31,30,30,0,0,0,5.84-6.61V212a37,37,0,0,0-5.84-6.48,41.54,41.54,0,0,0-7.77-5.44,41,41,0,0,0-9.08-3.63,36.34,36.34,0,0,0-9.46-1.3A32.87,32.87,0,0,0,1151,206.81a37.57,37.57,0,0,0-7.13,13.09,58.08,58.08,0,0,0,0,33.45,38.83,38.83,0,0,0,7.13,13.22,33.08,33.08,0,0,0,11.15,8.69A33.78,33.78,0,0,0,1177,278.37Z"/>
          <path fill="#4d4f4e" d="M1343.42,299.37v-78.3q0-14.77-6.88-20.35T1318,195.14a35.1,35.1,0,0,0-18.8,5.19,50.88,50.88,0,0,0-13.36,11.4v87.64h-27.22V174.14h27.22V191a58.08,58.08,0,0,1,17.9-13.74,61.4,61.4,0,0,1,12.31-4.54,57.24,57.24,0,0,1,14.13-1.68q20.22,0,30.34,10.37t10.11,29.82v88.15Z"/>
          <path fill="#4d4f4e" d="M1477.72,299.37v-78.3q0-14.77-6.87-20.35t-18.54-5.58a35.15,35.15,0,0,0-18.8,5.19,50.66,50.66,0,0,0-13.35,11.4v87.64h-27.22V174.14h27.22V191a58.19,58.19,0,0,1,17.89-13.74,61.72,61.72,0,0,1,12.32-4.54A57.12,57.12,0,0,1,1464.5,171q20.22,0,30.33,10.37t10.11,29.82v88.15Z"/>
        </g>
      </g>
    </svg>

  let maxWidth

  if (props.maxWidth) {
    maxWidth = { maxWidth: props.maxWidth + 'px' }
  } else {
    maxWidth = { maxWidth: '100%' }
  }

  if (props.type == 'icon') {
    return ( <div style={maxWidth}>{mark}</div> )
  } else {
    return ( <div style={maxWidth}>{full}</div> )
  }
}

Logo.propTypes = {
  type:PropTypes.oneOf([
    'full', 'icon']).isRequired,
  maxWidth:PropTypes.number
}
