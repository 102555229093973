import React from 'react'
import cx from 'classnames'

import styles from './styles.css'
import QuadIcon from '../quadrant-icons'

const descriptions ={
  A: "bar chart icon",
  B: "alarm clock",
  C: "hand",
  D: "lightbulb"
}

export default class QuadrantLabel extends React.Component{

  render(){
    let letterOnRight = this.props.letterOnRight
    const letter = this.props.letter

    return (
      <div className={cx((this.props.content == undefined && styles.smallSize), styles.quadLabelContainer, styles[letter], (this.props.letter == true && styles.quadLast))} key={letter}>
        <h3 className={cx(styles.letter, styles[letter])}>
          {letter}
        </h3>
        <div class={styles.iconWrapper}>
          <QuadIcon quad={this.props.letter} maxwidth="30"/>
        </div>
        { this.props.content &&
          <div className={cx(styles.quadLabel, styles[letter])}>
            <h4> {this.props.content} </h4>
          </div>
        }
      </div>
    )
  }
}
