import React, { Component } from "react";
import { TimelineMax, CSSPlugin } from "gsap/all";
import Tile from '../tile'

export default class FortuneTeller extends Component {

	constructor(props){
		super(props);
		this.red = null;
		this.blue = null;
		this.green = null;
		this.yellow = null;

		this.inside1 = null;
    this.inside2 = null;
    this.inside3 = null;
    this.inside4 = null;

		this.outside1 = null;
    this.outside2 = null;
    this.outside3 = null;
    this.outside4 = null;

		this.fortune = null;
    this.state={};
	}

	componentDidMount(props){

		function createVertical(red, green, blue, yellow, inside1, inside2, inside3, inside4) {

			var vertical = new TimelineMax()

			vertical.to(red, 0.5, {attr:{points: "2320 1756 2139.49 974.7 1170 1860 1172 1941.48 2320 1756"}})
			vertical.to(green, 0.5, {attr:{points: "40 1746 204.51 970.2 1170.11 1860 1172 1936.98 40 1746"}}, "-=0.5")
			vertical.to(blue, 0.5, {attr:{points: "0 180 204.51 969.3 1172 70 1172 2.52 0 180"}}, "-=0.5")
			vertical.to(yellow, 0.5, {attr:{points:"2320 158 2139.49 973.8 1172 70 1172 7.02 2320 158"}}, "-=0.5")
			vertical.to(inside1, 0.5, {attr:{points: "1173.77 965.36 1173.52 0 2139.13 965.62 1173.77 965.36"}}, "-=0.5")
			vertical.to(inside2, 0.5, {attr:{points: "2138.37 966.38 1172.75 1932 1173 966.12"}}, "-=0.5")
			vertical.to(inside3, 0.5, {attr:{points: "1172.5 962.07 1172.75 1927.43 207.13 961.81 1172.5 962.07"}}, "-=0.5")
			vertical.to(inside4, 0.5, {attr:{points:"207.89 965.62 1173.51 0 1173.26 965.88"}}, "-=0.5")

			return vertical
		}

		function createClose(red, green, blue, yellow, inside1, inside2, inside3, inside4) {

			var close = new TimelineMax()

			close.to(red, .5, {attr:{points: "2248 2184 2091.49 1094.7 1125.89 1094.35 1124 2061.48 2248 2184"}})
			close.to(green, .5, {attr:{points: "0 2179.5 156.51 1090.2 1122.11 1089.86 1124 2056.98 0 2179.5"}}, "-=0.5")
			close.to(blue, .5, {attr:{points: "0 0 156.51 1089.3 1122.11 1089.65 1124 122.52 0 0"}}, "-=0.5")
			close.to(yellow, .5, {attr:{points:"2248 4.5 2091.49 1093.8 1125.89 1094.14 1124 127.02 2248 4.5"}}, "-=0.5")
			close.to(inside1, 0.5, {attr:{points: "1125.77 1085.36 1125.52 120 2091.13 1085.62 1125.77 1085.36"}}, "-=0.5")
			close.to(inside2, 0.5, {attr:{points: "2090.37 1086.38 1124.75 2052 1125 1086.12"}}, "-=0.5")
			close.to(inside3, 0.5, {attr:{points: "1124.5 1082.07 1124.75 2047.43 159.13 1081.81 1124.5 1082.07"}}, "-=0.5")
			close.to(inside4, 0.5, {attr:{points:"159.89 1085.62 1125.51 120 1125.26 1085.88"}}, "-=0.5")

			return close
		}

		function createSideways(red, green, blue, yellow, inside1, inside2, inside3, inside4) {

			var sideways = new TimelineMax()

			sideways.to(red, .5, {attr:{points: "1780.49 2401 1934.98 1211.7 1836.49 1211 967.49 2178.48 1780.49 2401"}})
			sideways.to(green, .5, {attr:{points: "180.49 2401 0 1207.2 103.49 1207 967.49 2173.98 180.49 2401"}}, "-=0.5")
			sideways.to(blue, .5, {attr:{points: "150.49 0 0 1206.3 103.49 1207 967.49 239.52 150.49 0"}}, "-=0.5")
			sideways.to(yellow, .5, {attr:{points:"1807.49 0 1934.98 1210.8 1836.49 1211 967.49 244.02 1807.49 0"}}, "-=0.5")
			sideways.to(inside1, 0.5, {attr:{points: "969.26 1202.36 969 237 1934.62 1202.62 969.26 1202.36"}}, "-=0.5")
			sideways.to(inside2, 0.5, {attr:{points: "1933.86 1203.38 968.24 2169 968.49 1203.12"}}, "-=0.5")
			sideways.to(inside3, 0.5, {attr:{points: "967.99 1199.07 968.24 2164.43 2.62 1198.81 967.99 1199.07"}}, "-=0.5")
			sideways.to(inside4, 0.5, {attr:{points:"3.38 1202.62 969 237 968.75 1202.88"}}, "-=0.5")

		}

		function removeColors(red, green, blue, yellow, inside1, inside2, inside3, inside4, outside1, outside2, outside3, outside4) {

			var remove = new TimelineMax()

			remove.to(red, .5, {opacity: 0, display: "none"})
			remove.to(green, .5, {opacity: 0, display: "none"}, "-=0.5")
			remove.to(blue, .5, {opacity: 0, display: "none"}, "-=0.5")
			remove.to(yellow, .5, {opacity: 0, display: "none"}, "-=0.5")
			remove.to(outside1, 0, {x:50, y:-120})
			remove.to(outside2, 0, {x:50, y:-120}, "-=0.5")
			remove.to(outside3, 0, {x:50, y:-120}, "-=0.5")
			remove.to(outside4, 0, {x:50, y:-120}, "-=0.5")
			remove.to(outside1, 0, {opacity:1})
			remove.to(outside2, 0, {opacity:1}, "-=0.5")
			remove.to(outside3, 0, {opacity:1}, "-=0.5")
			remove.to(outside4, 0, {opacity:1}, "-=0.5")
		}

		let stuff = [this.red, this.green, this.blue, this.yellow, this.inside1, this.inside2, this.inside3, this.inside4, this.outside1, this.outside2, this.outside3, this.outside4, this.fortune]

		 this.yellowTimeline = new TimelineMax({repeat: 0, yoyo: true, paused: true})
     this.yellowTimeline.eventCallback("onComplete", this.props.started)


		 this.yellowTimeline.call(createClose, stuff)
		 this.yellowTimeline.call(createSideways, stuff, this, "+=0.5")
		 this.yellowTimeline.call(createClose, stuff, this, "+=0.5")
		 this.yellowTimeline.call(createVertical, stuff, this, "+=0.5")
		 this.yellowTimeline.call(createClose, stuff, this, "+=0.5")
		 this.yellowTimeline.call(createSideways, stuff, this, "+=0.5")
		 this.yellowTimeline.call(createClose, stuff, this, "+=0.5")
		 this.yellowTimeline.call(createVertical, stuff, this, "+=0.5")
		 this.yellowTimeline.call(createClose, stuff, this, "+=0.5")
		 this.yellowTimeline.call(createSideways, stuff, this, "+=0.5")
		 this.yellowTimeline.call(createClose, stuff, this, "+=0.5")
		 this.yellowTimeline.call(createVertical, stuff, this, "+=0.5")


		 this.redTimeline = new TimelineMax({repeat: 0, yoyo: true, paused: true})
     this.redTimeline.eventCallback("onComplete", this.props.started)

     this.redTimeline.call(createClose, stuff)
		 this.redTimeline.call(createVertical, stuff, this, "+=0.5")
		 this.redTimeline.call(createClose, stuff, this, "+=0.5")
		 this.redTimeline.call(createSideways, stuff, this, "+=0.5")
		 this.redTimeline.call(createClose, stuff, this, "+=0.5")
		 this.redTimeline.call(createVertical, stuff, this, "+=0.5")

		 this.blueTimeline = new TimelineMax({repeat: 0, yoyo: true, paused: true})
     this.blueTimeline.eventCallback("onComplete", this.props.started)


		 this.blueTimeline.call(createClose, stuff)
		 this.blueTimeline.call(createSideways, stuff, this, "+=0.5")
		 this.blueTimeline.call(createClose, stuff, this, "+=0.5")
		 this.blueTimeline.call(createVertical, stuff, this, "+=0.5")
		 this.blueTimeline.call(createClose, stuff, this, "+=0.5")
		 this.blueTimeline.call(createSideways, stuff, this, "+=0.5")
		 this.blueTimeline.call(createClose, stuff, this, "+=0.5")
		 this.blueTimeline.call(createVertical, stuff, this, "+=0.5")

		 this.greenTimeline = new TimelineMax({repeat: 0, yoyo: true, paused: true})
     this.greenTimeline.eventCallback("onComplete", this.props.started)

		 this.greenTimeline.call(createClose, stuff)
		 this.greenTimeline.call(createVertical, stuff, this, "+=0.5")
		 this.greenTimeline.call(createClose, stuff, this, "+=0.5")
		 this.greenTimeline.call(createSideways, stuff, this, "+=0.5")
		 this.greenTimeline.call(createClose, stuff, this, "+=0.5")
		 this.greenTimeline.call(createVertical, stuff, this, "+=0.5")
		 this.greenTimeline.call(createClose, stuff, this, "+=0.5")
		 this.greenTimeline.call(createSideways, stuff, this, "+=0.5")
		 this.greenTimeline.call(createClose, stuff, this, "+=0.5")
		 this.greenTimeline.call(createVertical, stuff, this, "+=0.5")
    
		 this.oneTimeline = new TimelineMax({repeat: 0, yoyo: true, paused: true})

		 this.oneTimeline.call(removeColors, stuff)
		 this.oneTimeline.to(this.inside1, 0.1, {scaleY:-1, scaleX: -1, fill:"#FDD742", transformOrigin:"center center"}, 0.5)
		 this.oneTimeline.to(this.fortune, 0.5, {opacity: 1, display: "block"})

		 this.twoTimeline = new TimelineMax({repeat: 0, yoyo: true, paused: true})

		 this.twoTimeline.call(removeColors, stuff)
		 this.twoTimeline.to(this.inside2, 0.1, {scaleY:-1, scaleX: -1, fill:"#EF354E", transformOrigin:"center center"}, 0.5)
		 this.twoTimeline.to(this.fortune, 0.5, {opacity: 1, display: "block"})

		 this.threeTimeline = new TimelineMax({repeat: 0, yoyo: true, paused: true})

		 this.threeTimeline.call(removeColors, stuff)
		 this.threeTimeline.to(this.inside3, 0.1, {scaleY:-1, scaleX: -1, fill:"#63C084", transformOrigin:"center center"}, 0.5)
		 this.threeTimeline.to(this.fortune, 0.5, {opacity: 1, display: "block"})

		 this.fourTimeline = new TimelineMax({repeat: 0, yoyo: true, paused: true})

		 this.fourTimeline.call(removeColors, stuff)
		 this.fourTimeline.to(this.inside4, 0.1, {scaleY:-1, scaleX: -1, fill:"#2CC3F0", transformOrigin:"center center"}, 0.5)
		 this.fourTimeline.to(this.fortune, 0.5, {opacity: 1, display: "block"})
	}

	render(props){

		
		function randomFortune(fortunes, quadrant) {
      const items = fortunes[quadrant];
      return items[Math.floor(Math.random() * items.length)];
		}

    var maxWidth = {
      maxWidth: this.props.maxWidth + 'px',
      width: '100%'
    }

		function resetTimelines(blue, yellow, green, red, oneTimeline, twoTimeline, threeTimeline, fourTimeline) {
			yellow.progress(0)
			yellow.pause(0)
			red.progress(0)
			red.pause(0)
			green.progress(0)
			green.pause(0)
			blue.progress(0)
			blue.pause(0)

			oneTimeline.progress(0)
			oneTimeline.pause(0)
			twoTimeline.progress(0)
			twoTimeline.pause(0)
			threeTimeline.progress(0)
			threeTimeline.pause(0)
			fourTimeline.progress(0)
			fourTimeline.pause(0)

			removeColors.progress(0)
			removeColors.pause(0)
		}

		return (
			<>

    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2500 2500" style={maxWidth}>
			<g id="Layer_2" data-name="Layer 2">
				<g id="Layer_1-2" data-name="Layer 1">

				/* inside flaps*/

					<polygon ref={ x => this.outside1 = x } fill="#FDD742" points="1125.77 1085.36 1125.52 120 2091.13 1085.62 1125.77 1085.36" opacity="0"/>
					<polyline ref={ x => this.outside2 = x } fill="#EF354E" points="2090.37 1086.38 1124.75 2052 1125 1086.12" opacity="0"/>
					<polygon ref={ x => this.outside3 = x } fill="#63C084" points="1124.5 1082.07 1124.75 2047.43 159.13 1081.81 1124.5 1082.07" opacity="0"/>
					<polyline ref={ x => this.outside4 = x } fill="#2CC3F0" points="159.89 1085.62 1125.51 120 1125.26 1085.88" opacity="0"/>


					/* outside flaps */
					<a onClick={() => {this.oneTimeline.play(); this.setState({quadrant: "D"}); this.props.done && this.props.done();}}>
						<polygon ref={ x => this.inside1 = x } fill="#C7A52E" points="1125.77 1085.36 1125.52 120 2091.13 1085.62 1125.77 1085.36"/>
					</a>
					<a onClick={() => {this.twoTimeline.play(); this.setState({quadrant: "C"}); this.props.done && this.props.done();}}>
						<polyline ref={ x => this.inside2 = x } fill="#BA2031" points="2090.37 1086.38 1124.75 2052 1125 1086.12"/>
					</a>
					<a onClick={() => {this.threeTimeline.play(); this.setState({quadrant: "B"}); this.props.done && this.props.done();}}>
						<polygon ref={ x => this.inside3 = x } fill="#28A462" points="1124.5 1082.07 1124.75 2047.43 159.13 1081.81 1124.5 1082.07"/>
					</a>
					<a onClick={() => {this.fourTimeline.play(); this.setState({quadrant: "A"}); this.props.done && this.props.done();}}>
						<polyline ref={ x => this.inside4 = x } fill="#0F8FB0" points="159.89 1085.62 1125.51 120 1125.26 1085.88"/>
					</a>



					/* colors */
				  <a onClick={() => {this.redTimeline.play();}}>
						<polygon ref={ x => this.red = x } fill="#EF354E" points="2248 2184 2091.49 1094.7 1125.89 1094.35 1124 2061.48 2248 2184"/>
					</a>
					<a onClick={() => {this.yellowTimeline.play();}}>
						<polygon ref={ x => this.yellow = x } fill="#FDD742" points="2248 4.5 2091.49 1093.8 1125.89 1094.14 1124 127.02 2248 4.5"/>
					</a>
					<a onClick={() => {this.greenTimeline.play();}}>
						<polygon ref={ x => this.green = x } fill="#63C084" points="0 2179.5 156.51 1090.2 1122.11 1089.86 1124 2056.98 0 2179.5"/>
					</a>
					<a onClick={() => {this.blueTimeline.play();}}>
						<polygon ref={ x => this.blue = x } fill="#2CC3F0" points="0 0 156.51 1089.3 1122.11 1089.65 1124 122.52 0 0"/>
					</a>
				</g>
			</g>
		</svg>
		<div ref={ x => this.fortune = x } style={{display: "none", opacity: "0", maxWidth: (this.props.maxWidth+"px"), width: "100%", textAlign: "center", fontFamily: "sans-serif", fontSize: "16px"}}>
			<Tile>
				{this.state.quadrant && this.props.fortunes && randomFortune(this.props.fortunes, this.state.quadrant)}
			</Tile>
		</div>

		</>
    );
	}

}
