import cx from 'classnames'
import React from 'react';
import FontAwesomeIcon from '@fortawesome/react-fontawesome'
import {faSearch} from '@fortawesome/fontawesome-free-solid'
import PropTypes from 'prop-types';

import styles from './styles.css'
import Button from '../button'

function Input(props){
  let {placeholder, types, min, max, maxWidth, ...inputProps} = props
  var dimensions = {
    maxWidth: props.maxWidth + "px",
  }

  return (
    <div>
      <input
        className={styles.input}
        style={dimensions}
        placeholder={props.placeholder}
        type={props.type}
        min={props.min}
        max={props.max}
        {...inputProps}
      />
    </div>
  )
}

function Fields(props){
  let{ arrangement="vertical" } = props
  return (
    <div className={styles[arrangement]}>
      {props.children}
    </div>
  )
}

function Actions(props){
  return (
    <div className={cx(styles.horizontal, styles.actions)}>
      {props.children}
    </div>
  )
}

export default class Form extends React.Component {
  static Input = Input;
  static Fields = Fields;
  static Actions = Actions;


  render(props){
    return (
      <form className={styles.form}>
         { this.props.children }
         <div className={styles.actions}>

         </div>
      </form>
    )
  }
}

Input.propTypes = {
  type:PropTypes.oneOf([
    'number', 'date', 'password', 'email', 'text', 'radio', 'checkbox', 'range', 'url', 'tel', 'time']),
  min:PropTypes.number,
  max:PropTypes.number,
  maxWidth:PropTypes.number,
  placeholder:PropTypes.string,
}
