import cx from 'classnames'
import React from 'react';
import PropTypes from 'prop-types'
import _ from 'lodash'

import styles from './styles.css'
import {Colors} from '../colors'

const background = {
  green: Colors['green'],
  blue: Colors['blue'],
  yellow: Colors['yellow'],
  red: Colors['red'],
}

const Buttons = function (props) {

  let {variant="primary", isLocked, buttonText, ...passThrough} = props

  var variantColor = {
    backgroundColor: background[variant],
    borderColor: background[variant]
  }

  return (
    <button
      className={cx(styles.colorButton, {[styles.disabled]: isLocked})}
      style={variantColor}
      {...passThrough}
      >
      {buttonText}

    </button>
  )
}

export default function ColorButton (props){
  let {isLocked, buttonText, ...passThrough} = props
  return (
    <Buttons
      isLocked={isLocked}
      buttonText={buttonText}
      {...passThrough}
    />
  )
}
