import React, { Component } from "react";
import { TimelineMax, CSSPlugin } from "gsap/all";

class Sparkle extends Component {

	constructor(props){
		super(props);
		this.group1red = null;
    this.group1blue = null;
    this.group1green = null;
    this.group1yellow = null;
		this.group2red = null;
    this.group2blue = null;
    this.group2green = null;
    this.group2yellow = null;
		this.sparkleTween = null;
	}

	componentDidMount(props){

		let delay
		if (this.props.delay) {
			delay = this.props.delay
		} else {
			delay = 0
		}

     this.sparkleTween = new TimelineMax({repeat: this.props.repeat}).delay(delay)
		 	.to(this.group1red, 0, { opacity: 1 }, 0)
      .to(this.group1red, 0.3, { attr: { x1: 430 },  ease:'power2.easeInOut'}, 0)
			.to(this.group1red, 0.3, { attr: { x2: 430 },  ease:'power2.easeInOut'}, 0.2)
			.to(this.group1red, 0, { opacity: 0 }, 0.5)

			.to(this.group1green, 0, { opacity: 1 }, 0)
			.to(this.group1green, 0.3, { attr: { y1: 430 },  ease:'power2.easeInOut'}, 0)
			.to(this.group1green, 0.3, { attr: { y2: 430 },  ease:'power2.easeInOut'}, 0.2)
			.to(this.group1green, 0, { opacity: 0 }, 0.5)

			.to(this.group1blue, 0, { opacity: 1 }, 0)
			.to(this.group1blue, 0.3, { attr: { x2: 8 },  ease:'power2.easeInOut'}, 0)
			.to(this.group1blue, 0.3, { attr: { x1: 8 },  ease:'power2.easeInOut'}, 0.2)
			.to(this.group1blue, 0, { opacity: 0 }, 0.5)

			.to(this.group1yellow, 0, { opacity: 1 }, 0)
			.to(this.group1yellow, 0.3, { attr: { y1: 8 },  ease:'power2.easeInOut'}, 0)
			.to(this.group1yellow, 0.3, { attr: { y2: 8 },  ease:'power2.easeInOut'}, 0.2)
			.to(this.group1yellow, 0, { opacity: 0 }, 0.5)

			.to(this.group2red, 0, { opacity: 1 }, 0.3)
			.to(this.group2red, 0.2, { attr: { x1: 66.6, y1: 73.7 },  ease:'power2.easeInOut'}, 0.3)
			.to(this.group2red, 0.2, { attr: { x2: 66.6, y2: 73.7 },  ease:'power2.easeInOut'}, 0.4)
			.to(this.group2red, 0, { opacity: 0 }, 0.6)

			.to(this.group2green, 0, { opacity: 1 }, 0.3)
			.to(this.group2green, 0.2, { attr: { x1: 364.7, y1: 73.3 },  ease:'power2.easeInOut'}, 0.3)
			.to(this.group2green, 0.2, { attr: { x2: 364.7, y2: 73.3 },  ease:'power2.easeInOut'}, 0.4)
			.to(this.group2green, 0, { opacity: 0 }, 0.6)

			.to(this.group2blue, 0, { opacity: 1 }, 0.3)
			.to(this.group2blue, 0.2, { attr: { x2: 364.8, y2: 372.5 },  ease:'power2.easeInOut'}, 0.3)
			.to(this.group2blue, 0.2, { attr: { x1: 364.8, y1: 372.5 },  ease:'power2.easeInOut'}, 0.4)
			.to(this.group2blue, 0, { opacity: 0 }, 0.6)

			.to(this.group2yellow, 0, { opacity: 1 }, 0.3)
			.to(this.group2yellow, 0.2, { attr: { y1: 370.5, x1: 65.8},  ease:'power2.easeInOut'}, 0.3)
			.to(this.group2yellow, 0.2, { attr: { y2: 370.5, x2: 65.8},  ease:'power2.easeInOut'}, 0.4)
			.to(this.group2yellow, 0, { opacity: 0 }, 0.6)

	}

	render(props){

    var maxWidth = {
      maxWidth: this.props.maxWidth + 'px',
      width: '100%'
    }

		return (

			<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 438.2 437.5" style="enable-background:new 0 0 438.2 437.5;" style={maxWidth}>
				<g id="Group2">
					<line ref={ x => this.group2green = x } opacity="0" fill="#FFFFFF" stroke="#3AC581" strokeWidth="16" strokeLinecap="round" strokeMiterlimit="10" x1="250.8" y1="187.2" x2="250.8" y2="187.2"/>
					<line ref={ x => this.group2yellow = x } opacity="0" fill="#FFFFFF" stroke="#FED903" strokeWidth="16" strokeLinecap="round" strokeMiterlimit="10" x1="179.7" y1="256.7" x2="179.7" y2="256.7"/>
					<line ref={ x => this.group2blue = x } opacity="0" fill="#FFFFFF" stroke="#02C2F4" strokeWidth="16" strokeLinecap="round" strokeMiterlimit="10" x1="251" y1="258.7" x2="251" y2="258.7"/>
					<line ref={ x => this.group2red = x } opacity="0" fill="#FFFFFF" stroke="#FD023C" strokeWidth="16" strokeLinecap="round" strokeMiterlimit="10" x1="180.5" y1="187.5" x2="180.5" y2="187.5"/>
				</g>
				<g id="Group1">
					<line ref={ x => this.group1yellow = x } opacity="0" fill="#FFFFFF" stroke="#FED903" strokeWidth="16" strokeLinecap="round" strokeMiterlimit="10" x1="218.5" y1="169" x2="218.5" y2="169"/>
					<line ref={ x => this.group1green = x } opacity="0" fill="#FFFFFF" stroke="#3AC581" strokeWidth="16" strokeLinecap="round" strokeMiterlimit="10" x1="217.3" y1="268.5" x2="217.3" y2="268.5"/>
					<line ref={ x => this.group1red = x } opacity="0" fill="#FFFFFF" stroke="#FD023C" strokeWidth="16" strokeLinecap="round" strokeMiterlimit="10" x1="269.2" y1="219.5" x2="269.2" y2="219.5"/>
					<line ref={ x => this.group1blue = x } opacity="0" fill="#FFFFFF" stroke="#02C2F4" strokeWidth="16" strokeLinecap="round" strokeMiterlimit="10" x1="169" y1="219" x2="169" y2="219"/>
				</g>
			</svg>

    )
	}

}

export default Sparkle
