import cx from 'classnames'
import React from 'react';
import PropTypes from 'prop-types'
import _ from 'lodash'

import styles from './styles.css'
import {Colors} from '../colors'

export default class Switch extends React.Component {

  constructor(props) {
    super();
    this.state = {
      isChecked: props.checked
    };
  }

  check(e) {
    this.setState({
      isChecked: !this.state.isChecked
    })
  }

  render(props){

    return (
      <label className={styles.switch}>

        { this.state.isChecked &&
        <input className={styles.switchInput} type="checkbox" onClick={(e) => this.check()} onChange={this.props.onChange} checked/>
        }

        { !this.state.isChecked &&
        <input className={styles.switchInput} type="checkbox" onClick={(e) => this.check()} onChange={this.props.onChange}/>
        }

        <span className={styles.switchLabel} data-on={this.props.pos1Label} data-off={this.props.pos2Label}></span>
        <span className={styles.switchHandle}></span>
      </label>
    )
  }
}

Switch.propTypes = {
  pos1Label: PropTypes.string,
  pos2Label: PropTypes.string
}
