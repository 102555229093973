// TODO: Add event pass through
import cx from 'classnames'
import React from 'react';
import PropTypes from 'prop-types'
import {Colors} from '../colors'

import styles from './styles.css'

export default function GhostButton(props) {

  const text = {
    navy: Colors['navy'],
    white: "#FFFFFF",
    grey: Colors['grey']
  }

  var color = {
    color: text[props.textColor],
    borderColor: text[props.textColor]
  }

  return (
    <button className={styles.ghostButton} style={color}>
      {props.buttonText}
    </button>
  )
}

GhostButton.propTypes = {
  buttonText:PropTypes.string,
  textColor:PropTypes.oneOf([
    'grey', 'white', 'navy'])
}
