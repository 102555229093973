import React from 'react';
import PropTypes from 'prop-types'
import FontAwesomeIcon from '@fortawesome/react-fontawesome'

import Logo from '../logo'
import styles from './styles.css'
import GhostButton from '../ghost-button'

export default function (props) {

  let headerHeight
  if (props.tileOverlap == true) {
    headerHeight = {height: "59px"}
  } else {
    headerHeight = {height: "0px"}
  }

  return (
    <div className={styles.header} style={headerHeight} >
    </div>
  )
}
