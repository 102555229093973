import React from 'react';
import PropTypes from 'prop-types'

import styles from './styles.css'

export default function Subheader(props) {
  return (
    <h5 className={styles.tileMenu}>
      {props.title}
    </h5>
  )
}

Subheader.propTypes = {
  title:PropTypes.string
}
