import cx from 'classnames'
import React from 'react';
import PropTypes from 'prop-types';

import styles from './styles.css'

export default function ProgressBar (props) {

  var barWidth = {
    width: props.percentage + '%',
  }

  var dimensions = {
    maxWidth: props.maxWidth + 'px',
    maxHeight: props.maxHeight + 'px'
  }

  var hover = {
    visibility: props.hoverVisibility
  }

  return (
    <div className={styles.progressBarWrapper} style={dimensions}>
      <div className={styles.tooltip}>
        <p className={styles.skills}>
          {props.title}
          <span style={barWidth}></span>
          <span className={styles.tooltiptext} style={hover}>
            {props.percentage}%
          </span>
        </p>
      </div>
    </div>
  )
}

ProgressBar.propTypes = {
  percentage:PropTypes.number.isRequired,
  maxWidth:PropTypes.number,
  maxHeight:PropTypes.number,
  visibility:PropTypes.string,
  title:PropTypes.string
}
