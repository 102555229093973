import React, { Component } from "react";
import { TimelineMax, CSSPlugin } from "gsap/all";

class DecoderRings extends Component {

	constructor(props){
		super(props);
		this.ring1 = null;
    this.ring2 = null;
    this.ring3 = null;
    this.ring4 = null;
		this.logoTween = null;
	}

	componentDidMount(props){

     this.logoTween = new TimelineMax({repeat: this.props.repeat})
      .to(this.ring1, .5, {rotation:-60, ease:'power1.easeInOut', repeat:1, yoyo:true, transformOrigin:'center'}, 0.5)

      .to(this.ring2, 0.75, {rotation:90, ease:'back.easeInOut', repeat:1, yoyo:true, transformOrigin:'center'}, 0.5)

      .to(this.ring3, 0.75, {rotation:-180, ease:'back.easeInOut', repeat:1, yoyo:true, transformOrigin:'center'}, 0.5)

      .to(this.ring4, .90, {rotation:360, ease:'power0.easeInOut', repeat:1, transformOrigin:'center'}, 0.5)

      .to(this.ring1, 0.2, {ease: 'power0.easeInOut', scaleX:0.5, scaleY:0.5}, 2.5)
      .to(this.ring2, 0.2, {ease: 'power0.easeInOut', scaleX:0.6, scaleY:0.6}, 2.5)
      .to(this.ring3, 0.2, {ease: 'power0.easeInOut', scaleX:0.7, scaleY:0.7}, 2.5)

      .to(this.ring4, 0.5, {ease: 'back.easeInOut', scaleX:1, scaleY:1}, 2.7)
      .to(this.ring3, 0.5, {ease: 'back.easeInOut', scaleX:1, scaleY:1}, 2.7)
      .to(this.ring2, 0.5, {ease: 'back.easeInOut', scaleX:1, scaleY:1}, 2.7)
      .to(this.ring1, 0.5, {ease: 'back.easeInOut', scaleX:1, scaleY:1}, 2.7)
	}

	render(props){

    var maxWidth = {
      maxWidth: this.props.maxWidth + 'px',
      width: '100%'
    }

		return (

        <svg version="1.1" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px" viewBox="0 0 1366 768" style={maxWidth}>
        <g>
          <g ref={ x => this.ring1 = x }>
            <path fill="#02C2F4" d="M687.5,114.8l0-77.8C499.4,37,347,189.4,347,377.5h73.8C423,232,541.5,114.8,687.5,114.8
              C687.5,114.8,687.5,114.8,687.5,114.8z"/>
            <path fill="#FED903" d="M687.5,37l0,77.8c0,0,0,0,0,0c146,0,264.5,117.2,266.7,262.7h73.8C1028,189.4,875.5,37,687.5,37z"/>
            <path fill="#FD023C" d="M954.2,377.5c0,1.3,0.1,2.7,0.1,4c0,147.3-119.4,266.7-266.7,266.7c0,0,0,0,0,0l0,69.7
              c188.1,0,340.5-152.4,340.5-340.5H954.2z"/>
            <path fill="#3AC581" d="M687.5,648.3c-147.3,0-266.7-119.4-266.7-266.7c0-1.4,0-2.7,0.1-4H347C347,565.6,499.5,718,687.5,718
              L687.5,648.3C687.5,648.3,687.5,648.3,687.5,648.3z"/>
          </g>
          <g ref={ x => this.ring2 = x }>
            <path fill="#02C2F4" d="M688,207.5l0-74.5c0,0,0,0,0,0c-136.3,0-246.9,109.5-249,245.2h75C515.8,283.6,593,207.5,688,207.5
              C688,207.5,688,207.5,688,207.5z"/>
            <path fill="#FED903" d="M688,133C688,133,688,133,688,133l0,74.5c0,0,0,0,0,0c95,0,172.2,76.1,174,170.7h75
              C934.9,242.5,824.3,133,688,133z"/>
            <path fill="#FD023C" d="M937,378.2h-75c0,1.1,0,2.2,0,3.3c0,96.1-77.9,174-174,174c0,0,0,0,0,0l0,75.5c0,0,0,0,0,0
              c137.5,0,249-111.5,249-249C937,380.7,937,379.5,937,378.2z"/>
            <path fill="#3AC581" d="M688,555.5c-96.1,0-174-77.9-174-174c0-1.1,0-2.2,0-3.3h-75c0,1.3,0,2.5,0,3.8c0,137.5,111.5,249,249,249
              c0,0,0,0,0,0L688,555.5C688,555.5,688,555.5,688,555.5z"/>
          </g>
          <g ref={ x => this.ring3 = x }>
            <path fill="#02C2F4" d="M688,296c0.1,0,0.2,0,0.3,0l0-72c0,0,0,0,0,0c-86.1,0-156.1,69-157.7,154.8H604C604.7,333,642,296,688,296z"
              />
            <path fill="#FED903" d="M772,378.8h74C844.4,293,774.4,224,688.2,224c0,0,0,0,0,0l0,72C734.1,296.1,771.3,333,772,378.8z"/>
            <path fill="#FD023C" d="M846,378.8h-74c0,0.4,0,0.8,0,1.2c0,46.3-37.5,83.9-83.8,84l0,75.5c0,0,0,0,0,0
              c87.1,0,157.8-70.6,157.8-157.8C846,380.8,846,379.8,846,378.8z"/>
            <path fill="#3AC581" d="M604,380c0-0.4,0-0.8,0-1.2h-73.5c0,1,0,2,0,3c0,87.1,70.6,157.8,157.8,157.8c0,0,0,0,0,0l0-75.5
              c-0.1,0-0.2,0-0.3,0C641.6,464,604,426.4,604,380z"/>
          </g>
          <g ref={ x => this.ring4 = x }>
            <path fill="#02C2F4" d="M688.2,312c-0.1,0-0.1,0-0.2,0c-37.2,0-67.4,29.9-68,67h68.2L688.2,312z"/>
            <path fill="#FED903" d="M756,379c-0.5-37-30.6-66.9-67.8-67l0,67H756z"/>
            <path fill="#FD023C" d="M756,380c0-0.3,0-0.7,0-1h-67.8l0,69C725.7,447.9,756,417.5,756,380z"/>
            <path fill="#3AC581" d="M620,379c0,0.3,0,0.7,0,1c0,37.6,30.4,68,68,68c0.1,0,0.1,0,0.2,0l0-69H620z"/>
          </g>
        </g>
        </svg>

    )
	}

}

export default DecoderRings;
