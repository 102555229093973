import cx from 'classnames'
import React from 'react';
import PropTypes from 'prop-types'
import _ from 'lodash'

import styles from './styles.css'
import {Colors} from '../colors'

const background = {
  primary: "#FFF",
  secondary: Colors['navy'],
  hovered: Colors['lightNavy']
}

const text = {
  primary: Colors['navy'],
  secondary: "#FFF",
  hovered: Colors['lightNavy']
}

const border = {
  primary: Colors['navy'],
  secondary: "#FFF",
  hovered: Colors['lightNavy']
}

const Buttons = function (props) {
  let {variant="primary", isLocked, buttonText, ...passThrough} = props

  var variantColor = {
    backgroundColor: background[variant],
    color: text[variant],
    borderColor: border[variant]
  }

  var hovered = {
    backgroundColor: Colors['lightNavy'],
    color: Colors['navy'],
    transitionDuration: 0.5
  }

  return (
    <button
      className={cx(styles.standard, {[styles.disabled]: isLocked})}
      style={variantColor}
      {...passThrough}
    >
      {buttonText}
    </button>
  )
}

export default function Button (props){
  let {isLocked, buttonText, ...passThrough} = props
  return (
    <Buttons
      isLocked={isLocked}
      buttonText={buttonText}
      {...passThrough}
    />
  )
}

Button.propTypes = {
  variant:PropTypes.oneOf(['primary', 'secondary', 'disabled']),
  buttonText:PropTypes.string,
  isLocked:PropTypes.bool,
  onClick: PropTypes.func,
  type:PropTypes.oneOf([
    'submit', 'number', 'date', 'password', 'email', 'text', 'radio', 'checkbox', 'range', 'url', 'tel', 'time'])
}
