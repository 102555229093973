import cx from 'classnames'
import React from 'react';

import styles from './styles.css'
import Button from '../button'

export default function (props) {

  return (
    <Button
      variant="primary"
      buttonText="Return to Home" />
  )
}
