import React from 'react';
import FontAwesomeIcon from '@fortawesome/react-fontawesome'
import {faSortDown, faSortUp} from '@fortawesome/fontawesome-free-solid'
import PropTypes from 'prop-types'
import Markdown from 'react-markdown/with-html'
import _ from "lodash";

import styles from './styles.css'
import {Colors} from '../colors';

export default function ScoreTable(props) {

  let preferenceCode = _.values(props.preferenceCode)
  let profileScores = _.values(props.profileScores)
  let underPressure = _.values(props.underPressure)

  return (

    <table className={styles.scoreTable}>
      <tr>
        <th></th>
        <th className={styles.A}>A</th>
        <th className={styles.B}>B</th>
        <th className={styles.C}>C</th>
        <th className={styles.D}>D</th>
      </tr>
      { props.preferenceCode != undefined &&
        <tr>
          <td>{props.preferenceCodeTitle}</td>
          {
            preferenceCode.map(function(score, i){
              if (score == 0) {
                  score = 1
                }
              return (
                <td key={i}>{score}</td>
              )
            })
          }
        </tr>
      }
      { props.profileScores != undefined &&
        <tr>
          <td>{props.profileScoreTitle}</td>
          {
            profileScores.map(function(score, i){
              return (
                <td key={i}>{score}</td>
              )
            })
          }
        </tr>
      }
      { props.underPressure != undefined &&
        <tr>
          <td>{props.underPressureTitle}</td>
          {
            underPressure.map(function(score, i){
              return (
                <td key={i}>{score}</td>
              )
            })
          }
        </tr>
      }
      <tr className={styles.borderRow}>
        <td></td>
        <td className={styles.A}></td>
        <td className={styles.B}></td>
        <td className={styles.C}></td>
        <td className={styles.D}></td>
      </tr>
    </table>

  )
}
