import React from 'react';
import PropTypes from 'prop-types';

import styles from './styles.css'

export default function Loading (props) {

  var maxWidth = {
    maxWidth: props.maxWidth + 'px'
  }

  let backgroundColor
  if (props.backgroundColor != undefined) {
    let backgroundColor = props.backgroundColor
  } else {
    let backgroundColor = '#FFF'
  }

  return (
    <>
    <div style={maxWidth} className={styles.container}>
      <svg viewBox="0 0 1028 1028">
        <title>Loading</title>
        <g id="Layer_2" data-name="Layer 2">
          <g id="Layer_1_copy_16" data-name="Layer 1 copy 16">
            <path stroke="#63c084" fill="none" strokeWidth="4%" d="M514,988C252.22,988,40,775.78,40,514"/>
            <path stroke="#ef354e" fill="none" strokeWidth="4%" d="M988,514c0,261.78-212.22,474-474,474"/>
            <path stroke="#fdd742" fill="none" strokeWidth="4%" d="M514,40c261.78,0,474,212.22,474,474"/>
            <path stroke="#3fa9f5" fill="none" strokeWidth="4%" d="M40,514C40,252.22,252.22,40,514,40"/>
          </g>
          <circle className={styles.circle} cx="515" cy="514" r="473" stroke='#FFF' strokeWidth="10%" fillOpacity="0" />
        </g>
      </svg>
      <div className={styles.nested1}>
        <svg viewBox="0 0 1028 1028">
          <title>Loading</title>
          <g id="Layer_2" data-name="Layer 2">
            <g id="Layer_1_copy_16" data-name="Layer 1 copy 16">
              <path stroke="#63c084" fill="none" strokeWidth="5%" d="M514,988C252.22,988,40,775.78,40,514"/>
              <path stroke="#ef354e" fill="none" strokeWidth="5%" d="M988,514c0,261.78-212.22,474-474,474"/>
              <path stroke="#fdd742" fill="none" strokeWidth="5%" d="M514,40c261.78,0,474,212.22,474,474"/>
              <path stroke="#3fa9f5" fill="none" strokeWidth="5%" d="M40,514C40,252.22,252.22,40,514,40"/>
            </g>
            <circle className={styles.circle} cx="515" cy="514" r="473" stroke="white" strokeWidth="10%" fillOpacity="0" />
          </g>
        </svg>
      </div>
      <div className={styles.nested2}>
        <svg viewBox="0 0 1028 1028">
          <title>Loading</title>
          <g id="Layer_2" data-name="Layer 2">
            <g id="Layer_1_copy_16" data-name="Layer 1 copy 16">
              <path stroke="#63c084" fill="none" strokeWidth="8%" d="M514,988C252.22,988,40,775.78,40,514"/>
              <path stroke="#ef354e" fill="none" strokeWidth="8%" d="M988,514c0,261.78-212.22,474-474,474"/>
              <path stroke="#fdd742" fill="none" strokeWidth="8%" d="M514,40c261.78,0,474,212.22,474,474"/>
              <path stroke="#3fa9f5" fill="none" strokeWidth="8%" d="M40,514C40,252.22,252.22,40,514,40"/>
            </g>
            <circle className={styles.circle} cx="515" cy="514" r="473" stroke="white" strokeWidth="10%" fillOpacity="0" />
          </g>
        </svg>
      </div>
    </div>
    </>
  )
}
