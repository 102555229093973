import cx from 'classnames'
import React from 'react';
import PropTypes from 'prop-types'

import styles from './styles.css'

export default function Callout (props) {

  var maxWidth = {
    maxWidth: props.maxWidth + "px"
  }

  return (
    <div className={styles.callout} style={maxWidth}>
      {props.children}
    </div>
  )
}

Callout.propTypes = {
  maxWidth:PropTypes.number
}
