import React from 'react';
import PropTypes from 'prop-types';

export default function QuadIcon (props) {

  let chart =
  <svg viewBox="0 0 50 50">
  	<rect x="1.9" y="33.1" fill="#38C3EE" width="10.4" height="10.7"/>
  	<circle fill="none" stroke="#38C3EE" stroke-width="2.4733" stroke-miterlimit="10" cx="7" cy="26.4" r="2.6"/>
  	<rect x="13.9" y="25.2" fill="#38C3EE" width="10.4" height="18.6"/>
  	<circle fill="none" stroke="#38C3EE" stroke-width="2.4733" stroke-miterlimit="10" cx="19.1" cy="12.1" r="2.6"/>
  	<rect x="25.9" y="34.5" fill="#38C3EE" width="10.4" height="9.3"/>
  	<circle fill="none" stroke="#38C3EE" stroke-width="2.4733" stroke-miterlimit="10" cx="31.1" cy="27.9" r="2.6"/>
  	<rect x="37.9" y="23" fill="#38C3EE" width="10.4" height="20.8"/>
  	<circle fill="none" stroke="#38C3EE" stroke-width="2.4733" stroke-miterlimit="10" cx="43.1" cy="7.5" r="2.6"/>
  	<polyline fill="none" stroke="#38C3EE" stroke-width="2.4733" stroke-miterlimit="10" points="8.7,23.7 17,14.7 15.1,16.8 "/>
  	<line fill="none" stroke="#38C3EE" stroke-width="2.4733" stroke-miterlimit="10" x1="21.5" y1="14.3" x2="30.3" y2="25.7"/>
  	<line fill="none" stroke="#38C3EE" stroke-width="2.4733" stroke-miterlimit="10" x1="41.9" y1="9.4" x2="31.8" y2="25.8"/>
  </svg>

  let hands =
  <svg viewBox="0 0 948.4 892.03">
    <g id="Layer_1-2" data-name="Layer 1">
      <path fill="#ef384f" d="M617.63,485.66c-16.79-17.49-46.21-17.49-63,0L548,492.53c-17.48,18.14-17.47,48.82,0,67l136.46,142c16.72,17.39,46.28,17.44,63,0l6.6-6.89c17.76-18.47,17.76-48.53,0-67Z"/>
      <path fill="#ef384f" d="M642.73,294.5c-30.72,29.81-110.25,41-160.12-10.23l-47.07-48.2c-24.7-23.32-51.77-16.35-93.88-3.14-47.39,14.89-106.39,33.47-170.88-19.48L187,193.74c53.81,44.2,101.21,29.32,147.06,14.88,40.42-12.71,82.17-25.85,119.39,9.28l47.44,48.58c37.2,38.17,101,32.22,124.14,9.73,9.43-9.16,9.07-18-1.21-28.62L437,54.16C354.85-29,226.78,1.56,181,28.23c-31.67,18.52-63.38,45-91.68,76.65-80.23,89.73-136.88,233-32.49,341.44l57.93,60.17L335.83,265.38l18.79,17.22L114,545c-21.25,23.19-13.79,58.85,6.5,77.59,8.92,8.26,32.73,24.07,61.63-7l121.16-130L322,502.91,313.52,512l.05,0L200.8,633c-26.87,28.87-17.55,56.79.12,73.26,18.26,17.07,48.42,23.69,68.34,2.39l6-6.36q1.69-2,3.55-4a11.57,11.57,0,0,1,1.15-1.07L393.21,576.12l18.61,17.42L294.36,719.09c-21.86,25.83-14.28,50,.71,64.42,17.81,17.12,48.19,22.57,68.16,2.21l48.63-49.63c.59-.67,1.19-1.33,1.81-2l6.63-6.89c1.47-1.54,3-3,4.59-4.41a12.08,12.08,0,0,1,1.22-1c12.49-10.6,28.08-17,44.17-15.92-23.49-28.42-22.3-71.44,3.56-98.34l6.62-6.87a68.75,68.75,0,0,1,49.88-21.35h1.34l-2-2.12c-27.15-28.19-27.16-74.08,0-102.3l6.62-6.89c26.5-27.57,73.28-27.52,99.76,0l17.4,18.12A73.72,73.72,0,0,1,673.75,434l6.59-6.87a68.75,68.75,0,0,1,49.91-21.33h0a68.58,68.58,0,0,1,49.8,21.33l49.49,51.51,60.48-62.83c99.46-103.32,60-246.64-30.45-334.78C775.4-1.1,645.86-38.83,546.45,61.88l-33.3,34.48,129,133.51C662.16,250.65,662.4,275.41,642.73,294.5Z"/>
      <path fill="#ef384f" d="M730.27,431.31h0a43.4,43.4,0,0,0-31.54,13.52l-6.59,6.86c-17.46,18.17-17.48,48.88,0,67l85.67,89.18c16.69,17.4,46.26,17.45,63,0l6.65-6.89c17.73-18.46,17.73-48.52,0-67l-85.69-89.18A43.34,43.34,0,0,0,730.27,431.31Z"/>
      <path fill="#ef384f" d="M561.83,618.3a43.32,43.32,0,0,0-31.48-13.54h0a43.41,43.41,0,0,0-31.52,13.54h0l-6.62,6.86c-17.73,18.47-17.73,48.53,0,67l93.57,97.35a43.43,43.43,0,0,0,63,0l6.63-6.9c17.45-18.16,17.45-48.84,0-67Z"/>
      <path fill="#ef384f" d="M432.06,818.79l57.44,59.75A43.41,43.41,0,0,0,521,892h0a43.36,43.36,0,0,0,31.51-13.49l6.6-6.87c17.47-18.19,17.47-48.87,0-67L501.7,744.88c-15.86-16.47-43-17.42-60.12-2.74L431,753C414.64,771.25,415,801,432.06,818.79Z"/>
      <polygon fill="#fff" points="313.52 511.97 200.8 632.95 313.57 512.01 313.52 511.97"/>
      <polygon fill="#fff" points="303.33 485.54 182.16 615.58 294.93 494.64 313.52 511.97 321.97 502.91 303.33 485.54"/>
      <path fill="#fff" d="M275.22,702.24l4.7-5a11.57,11.57,0,0,0-1.15,1.07Q276.91,700.25,275.22,702.24Z"/>
      <path fill="#fff" d="M432.06,751.78c-.38.39-.74.8-1.1,1.2l10.62-10.84c-1,.86-2,1.77-2.92,2.74Z"/>
      <path fill="#fff" d="M413.67,734.11c-.62.65-1.22,1.31-1.81,2L424.68,723l.21-.2c-1.59,1.41-3.12,2.87-4.59,4.41Z"/>
      <polygon fill="#fff" points="827.92 480.41 846.28 498.08 847.26 497.06 829.59 478.67 827.92 480.41"/>
    </g>
  </svg>

  let alarm =
    <svg viewBox="0 0 50 50">
      <path fill="#64C086" d="M23.5,4.7c10.4,0,18.4,5.1,21.6,13.2c3.5,8.7,2.1,16.8-4.3,23.7c-1.3,1.4-1.6,2.2,0,3.4c1.2,0.9,1.5,2,0.3,3.2c-1.2,1.2-2.3,1-3.2-0.2c-1.5-2-3-2-5.3-1.1c-5.2,2.1-10.7,2-15.9-0.4c-1.6-0.7-2.6-0.5-3.6,0.8c-0.2,0.3-0.6,0.6-0.9,0.9c-1,1-2,1.1-3.1,0c-1-1.1-0.9-2.2,0.2-3c1.9-1.4,1.5-2.2,0-3.8c-6.4-7-7.8-15.1-4-23.7 C9.1,9.2,16,5.1,23.5,4.7z"/>
      <path fill="#64C086" d="M29.7,3.7c2.7-1.9,5.2-2.3,7.8-1.8c1,0.2,1.8,0.4,2.6-0.7c0.7-1,1.7-0.8,2.7-0.2c0.8,0.6,1.3,1.5,0.7,2.4c-0.7,1.1-0.4,1.7,0.3,2.6c1.7,2.3,2.1,4.8,1.5,8.3C41.1,8.9,36.4,5.2,29.7,3.7z"/>
      <path fill="#64C086" d="M20.9,3.7c-6.7,1.5-11.6,5-15.4,10.5C4.8,10.9,5.3,8.3,7,6c0.6-0.8,1-1.4,0.3-2.4C6.5,2.6,7.1,1.7,8,1c1-0.7,2-0.7,2.6,0.3C11.3,2.3,12,2.2,13,2C15.6,1.4,18.1,1.8,20.9,3.7z"/>
      <path fill="#FFFFFF" d="M42.8,26.7c-0.1,9.7-8,17.6-17.6,17.6c-9.7,0-17.6-8.1-17.6-17.9c0.1-9.7,8-17.6,17.6-17.5C34.9,8.8,42.8,16.8,42.8,26.7z"/>
      <path fill="#64C086" d="M29.9,28.8c-2,0-4,0-6,0c-1.1,0-1.5-0.4-1.5-1.5c0-4.1,0-8.2,0-12.3c0-1.3,0.7-2.1,1.9-2.1c1.4,0,2.1,0.8,2.1,2.2c0,2.6,0.1,5.2,0,7.7c-0.1,1.5,0.5,1.9,1.9,1.8c2.5-0.1,5,0,7.5,0c1.3,0,2.3,0.4,2.3,2c0,1.5-0.9,2.1-2.2,2.1C33.9,28.8,31.9,28.8,29.9,28.8C29.9,28.8,29.9,28.8,29.9,28.8z"/>
    </svg>

  let light =
  <svg viewBox="0 0 50 50">
  	<g>
  		<path fill="#F4BD45" d="M25.6,39.5c-1.2,0-2.5,0.1-3.7,0c-1.2-0.1-2-0.7-0.9-1.9c0.8-0.8,0.2-1.5,0-2.2c-0.8-2.4-2.6-4-4.1-6c-4-5.4-2.7-12.6,3-16.1c4.5-2.8,10.4-2.1,14.1,1.8c3.7,3.9,4.1,9.6,0.9,14.1c-1.3,1.8-3,3.3-3.9,5.4c-0.4,1-1.2,2-0.1,3.2c0.8,1-0.1,1.5-1.1,1.6C28.4,39.5,27,39.5,25.6,39.5z"/>
  		<path fill="#F4BD45" d="M42.9,23.4c-0.7,0-1.4,0-2.1,0c-0.6,0-1-0.4-1-1c0-0.6,0.4-1,0.9-1c1.4-0.1,2.9-0.1,4.3,0c0.6,0,1.1,0.4,1.1,1.1c0,0.7-0.6,1-1.2,1C44.2,23.4,43.6,23.4,42.9,23.4C42.9,23.4,42.9,23.4,42.9,23.4z"/>
  		<path fill="#F4BD45" d="M12.2,7.7c0.7,0,4.1,3.4,4,4.1c0,0.4-0.2,0.8-0.6,0.9c-0.5,0.2-0.9,0.1-1.3-0.2c-1-1-2-1.9-2.9-2.9c-0.3-0.3-0.5-0.8-0.2-1.2C11.5,7.9,11.8,7.7,12.2,7.7z"/>
  		<path fill="#F4BD45" d="M36.4,31.9c0.7,0,4.1,3.3,4.1,4c-0.1,1-0.9,1.4-1.7,0.8c-1.2-0.9-2.2-2-3.2-3.1C35,32.9,35.6,31.9,36.4,31.9z"/>
  		<path fill="#F4BD45" d="M15.3,31.9c0.4,0,0.7,0.2,0.9,0.6c0.2,0.5,0.1,0.9-0.2,1.2c-1,1-1.9,1.9-2.9,2.9c-0.4,0.3-0.8,0.4-1.3,0.2c-0.4-0.2-0.6-0.5-0.6-0.9C11.2,35.3,14.6,31.9,15.3,31.9z"/>
  		<path fill="#FBF9F4" d="M23.7,14.7c-0.2,0.5-0.6,0.8-1,1c-1.9,1-3.2,2.5-3.9,4.5c-0.2,0.6-0.4,1.3-1.2,1c-0.9-0.3-0.5-1.1-0.3-1.7c0.9-2.4,2.5-4.1,4.7-5.3C22.8,13.8,23.4,13.7,23.7,14.7z"/>
  		<path fill="#F4BD45" d="M39.1,11.7c-0.5,0.5-1.1,1-1.7,1.4c-0.5,0.3-1,0.3-1.4-0.1c-0.4-0.5-0.4-1,0.1-1.4c1-1,2.1-1.9,3.3-2.8c0.5-0.4,1.1-0.4,1.5,0.2c0.4,0.6,0.2,1.1-0.3,1.5C40.1,10.9,39.6,11.3,39.1,11.7C39.1,11.8,39.1,11.8,39.1,11.7z"/>
  		<path fill="#F4BD45" d="M26.6,5.3c0,0.7,0.1,1.4,0,2.1c0,0.6-0.4,1-1,1c-0.6,0-1-0.4-1.1-0.9c-0.1-1.4-0.1-2.9-0.1-4.3c0-0.6,0.4-1.1,1.1-1.1c0.7,0,1,0.5,1,1.2C26.7,4,26.6,4.6,26.6,5.3C26.6,5.3,26.6,5.3,26.6,5.3z"/>
  		<path fill="#F4BD45" d="M8.8,23.4c-0.7,0-1.4,0-2.1,0c-0.6,0-1-0.4-1-1c0-0.6,0.4-1,0.9-1c1.4-0.1,2.9-0.1,4.3,0c0.6,0,1.1,0.4,1.1,1.1c0,0.7-0.6,1-1.2,1C10.1,23.4,9.5,23.4,8.8,23.4C8.8,23.4,8.8,23.4,8.8,23.4z"/>
  	</g>
  	<path fill="#F4BD45" d="M30.2,42.9h-8.8c-0.6,0-1-0.5-1-1v-0.1c0-0.6,0.5-1,1-1h8.8c0.6,0,1,0.5,1,1v0.1C31.3,42.4,30.8,42.9,30.2,42.9z"/>
  	<path fill="#F4BD45" d="M29.7,46.2c0,1.4-1.7,2.5-3.8,2.5s-3.8-1.1-3.8-2.5"/>
  	<path fill="#F4BD45" d="M30.2,46.2h-8.8c-0.6,0-1-0.5-1-1v-0.1c0-0.6,0.5-1,1-1h8.8c0.6,0,1,0.5,1,1v0.1C31.3,45.8,30.8,46.2,30.2,46.2z"/>
  </svg>

  const icons = {
    A: chart,
    B: alarm,
    C: hands,
    D: light
  }

  var maxWidth = {
    maxWidth: props.maxWidth + 'px',
    width: '100%'
  }

  return (
    <div style={maxWidth}>
      {icons[props.quad]}
    </div>
  )
}

QuadIcon.propTypes = {
  quad:PropTypes.oneOf([
    'A', 'B', 'C', 'D']).isRequired,
  maxWidth:PropTypes.number
}
