import React from 'react';
import _ from 'lodash'
import {pie} from 'arcs'
import PropTypes from 'prop-types';

import {ColorsByQuadrant} from '../colors'
import {map} from '../../brains'
import Logo from '../../components/logo'
import styles from './portrait.css'

function quadrantPieChart(x,y,radius,scores){
  return _.chain(scores).toPairs().reduce((paths,kv)=>{
    const percentage = kv[1]/_.chain(scores).values().sum().value()
    const angleSize = percentage * 360
    const startAngle = _.last(paths) ? _.last(paths).endAngle : 0
    const endAngle = startAngle - angleSize
    paths.push({
      path: pie(x,y,radius,endAngle,startAngle),
      startAngle,
      endAngle,
      quadrant: kv[0]
    })
    return paths
  },[]).map(p=> {
      return (
        <path key={p.path}
          d={p.path}
          fill={ColorsByQuadrant[p.quadrant.toUpperCase()]} />
      )
    }
  )
  .value()
}

export default function ProfilePercentagePhoto(props){

  let brain
  let portraitUrl
  let profile

  if (props.profileScores != undefined) {
    profile = props.profileScores
  } else {
    brain = map[props.name]
    profile = brain.profile
    portraitUrl = props.assetsUrl ? (props.assetsUrl + "/" + brain.img) : brain.img
  }

  let maxWidth = {
    maxWidth: props.maxWidth+'px',
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }

  let fontSize = {
    fontSize: props.maxWidth / 3+'px'
  }

  return (
    <div style={maxWidth}>
      <svg className={styles.svg} width={props.maxWidth+'px'} viewBox="0 0 100 100">
        {console.log(profile)}
        {quadrantPieChart(50, 50, 50, profile) }
        { props.profileScores == undefined &&
          <image x="10" y="10" xlinkHref={portraitUrl} width="80" height="80" />
        }
      </svg>
      { props.name == "custom" &&
        <div className={styles.bgStyle}>
          { props.initials &&
            <h2 style={fontSize}>{props.initials}</h2>
          }
          {!props.initials &&
            <Logo type="icon"/>
          }
        </div>
      }
    </div>
  )
}

ProfilePercentagePhoto.propTypes = {
  name: PropTypes.oneOf(_.keys(map) || "custom"),
}
