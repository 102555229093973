import React from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'
import {pie} from 'arcs'

import {ChartColors} from '../colors'

/**
  Renders a "radar chart" style graphic where each quadrant has a value of 1,2,3,4 correspoinding
  to the number of circles filled in for that quadrant
*/

const color = "black"
const dash ="2,2"
const angles = {
  A: [-90,0],
  B: [-180,-90],
  C: [90,180],
  D: [0,90]
}

export default class RadarChart extends React.Component{

  static propTypes = {
    values: PropTypes.shape({
      A: PropTypes.oneOf([0,1,2,3,4]),
      B: PropTypes.oneOf([0,1,2,3,4]),
      C: PropTypes.oneOf([0,1,2,3,4]),
      D: PropTypes.oneOf([0,1,2,3,4])
    }),
    showBorders: PropTypes.bool,
    useGradients: PropTypes.bool
  };

  static defaultProps = {
    showBorders: true
  };

  _axis(){
    return (
      <g>
        <line stroke={color} strokeDasharray={dash} x1="0" x2="0" y1="130" y2="-130" ></line>
        <line stroke={color} strokeDasharray={dash} y1="0" y2="0" x1="130" x2="-130" ></line>
      </g>
    )
  }

  _circles(){
    let circles = []
    for(let i=0; i<=135; i+=33){
      circles.push(<circle key={`${i}_cir`} cx="0" cy="0" r={i} stroke={color} strokeDasharray={dash} fill="none" />)
    }
    return circles
  }

  _quadrants(){
    const quadrants = _.chain(this.props.values).toPairs().map(kv=> {
      return _.rangeRight(kv[1]+1).map(i=>{
        const code = 4 - i
        const radius = i * 33.3
        const angle = angles[kv[0]]
        const colorKey = this.props.useGradients ? code : "default"
        const color = `#${ChartColors[kv[0]][colorKey]}`
        return (
          <path key={`${kv[0]} ${i}`} d={pie(0,0,radius,angle[0],angle[1])} fill={ color } />
        )
      })

    }).value()
    return quadrants
  }

  render(){
    return (
      <svg viewBox="-134 -134 268 268" width="100" height="100" fill="lightgray">
        {!this.props.useGradients && this._quadrants()}
        {this.props.showBorders && this._circles()}
        {this.props.showBorders && this._axis()}
        {this.props.useGradients && this._quadrants()}
        <svg x="-130" y="-130" viewBox="-100 -100 200 200" width="100%">
          {this.props.children}
        </svg>
      </svg>
    )
  }
}
