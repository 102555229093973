import React from 'react';
import FontAwesomeIcon from '@fortawesome/react-fontawesome'
import {faSortDown, faSortUp} from '@fortawesome/fontawesome-free-solid'
import PropTypes from 'prop-types'
import Markdown from 'react-markdown/with-html'

import styles from './styles.css'
import {Colors} from '../colors';

class CreateAccordion extends React.Component {

  constructor(props) {
    super()
    this.state = {
      contentVisible: false,
    };
  }

  _toggleContent(viewContent){
    this.setState({contentVisible: !viewContent});
  }

  _renderContent(props) {
    return (
      <div className={styles.contentWrapper}>
        <Markdown
          escapeHtml={false}
          source={this.props.content}/>
      </div>
    )
  }

  render(props) {

    var divStyle = {
      maxWidth: this.props.maxWidth,
      borderColor: Colors[this.props.borderColor]
    }

    var color = {
      color: Colors[this.props.textColor]
    }

    return (
      <span>
        <div className={styles.accordion} style={divStyle}>
          <a className={styles.accordionHeader}
          onClick={()=>this._toggleContent(this.state.contentVisible)}>
            <h5 style={color}>{this.props.title}</h5>
          </a>
          {this.state.contentVisible && this._renderContent()}
        </div>
      </span>
    )
  }
}

export default function Accordion(props) {

  return (
    <CreateAccordion maxWidth={props.maxWidth}
      maxWidth={props.maxWidth}
      borderColor={props.borderColor}
      textColor={props.textColor}
      title={props.title}
      content={props.content}
    />
  )
}

Accordion.propTypes = {
  maxWidth:PropTypes.number,
  borderColor:PropTypes.oneOf([
    'red', 'green', 'blue', 'yellow', 'navy', 'white']),
  textColor:PropTypes.oneOf([
    'red', 'green', 'blue', 'yellow', 'navy', 'white']),
  title:PropTypes.string,
  content:PropTypes.node
}
