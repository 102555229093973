import cx from 'classnames'
import React from 'react'
import FontAwesomeIcon from '@fortawesome/react-fontawesome'
import {faExclamationCircle, faExclamationTriangle, faCheckCircle} from '@fortawesome/fontawesome-free-solid'

import Button from '../button'
import {Colors} from '../colors';

import styles from './styles.css'

export default function AlertDisplay(props) {

  const variant = props.type;

  const colorOptions = {
    error: "#db5757",
    warning: "#f68b55",
    confirmation: "#88cc00"
  }

  const icon = {
    error: "exclamation-circle",
    warning: "exclamation-triangle",
    confirmation: "check-circle"
  }

  const color = {
    borderColor: colorOptions[variant],
    color: colorOptions[variant]
  }

  return (
    <div className={styles.errorWrapper} style={color}>
      <div className={styles.errorCopy}>
        <FontAwesomeIcon icon={["fas", icon[variant]]} className={styles.icon}/>
        <p>{props.message} {props.code}</p>
        {
          props.button &&
          <Button
            variant="secondary"
            buttonText={props.button.text}
            onClick={props.button.action}
          />
        }
      </div>
      {
        props.xAction &&
        <a onClick={props.xAction}>
          x
        </a>
      }
    </div>
  )
}
