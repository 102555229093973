import cx from 'classnames'
import React from 'react';
import PropTypes from 'prop-types';
import FontAwesomeIcon from '@fortawesome/react-fontawesome'
import {faHome, faCog, faAddressCard, faUser, faSignOutAlt, faChevronDown} from '@fortawesome/fontawesome-free-solid'
import Logo from '../logo'

import ProfilePercentagePhoto from '../profile-percentage-photo'
import GhostButton from '../ghost-button'
import styles from './styles.css'

export default class Nav extends React.Component {

  constructor(props) {
    super()
    this.state = {
      contentVisible: props.startOpen,
      width: 0
    };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth});
    if (window.innerWidth > 900 ) {
      this.setState({ contentVisible: true })
    }
  }

  _toggleContent(viewContent){
    this.setState({contentVisible: !viewContent});
  }

  render() {

    let allowCloseFunction
    if (this.state.width < 900 && this.props.allowClosed) {
      allowCloseFunction = true
    } else {
      allowCloseFunction = false
    }

    function renderNavElement(icon, text) {
      return(
        <li>
          <FontAwesomeIcon icon={["fas", icon]} className={styles.icon}/>
          <span role="tooltip" id="settings-label">{text}</span>
        </li>
      )
    }

    return(
      <div className={cx(this.state.contentVisible == true && styles.navOpen, styles.navBar)}>
        { allowCloseFunction &&
          <a onClick={()=>this._toggleContent(this.state.contentVisible)}>
            <div className={styles.navHeader}>
              <ProfilePercentagePhoto
                initials={this.props.userInitials}
                maxWidth={27}
                name="custom"
                profileScores={this.props.profileScores}
              />
              <FontAwesomeIcon icon={["fas", "chevron-down"]} className={cx(styles.icon, this.state.contentVisible == true && styles.rotated)}/>
            </div>
          </a>
        }
        { allowCloseFunction == false &&

            <div className={styles.navHeader}>
              <ProfilePercentagePhoto
                initials={this.props.userInitials}
                maxWidth={27}
                name="custom"
                profileScores={this.props.profileScores}
              />
            </div>

        }
        <ul className={cx(styles.navContent)}>
          <a href="/" title={this.props.homeLabel} aria-labelledby="home-label">
            {renderNavElement("home", this.props.homeLabel)}
          </a>
          {this.props.showResultsIcon &&
          <a href="/thinker/hbdi-digital/four-quadrants" title={this.props.resultsLabel} aria-labelledby="results-label">
            {renderNavElement("address-card", this.props.resultsLabel)}
          </a>
          }
          {this.props.showProfileIcon &&
          <a href="/thinker/profile" title={this.props.profileLabel} aria-labelledby="profile-label">
            {renderNavElement("user", this.props.profileLabel)}
          </a>
          }
          <a href="/account/settings" title={this.props.settingsLabel} aria-labelledby="settings-label">
            {renderNavElement("cog", this.props.settingsLabel)}
          </a>

          <ul className={cx(styles.alignBottom, this.state.contentVisible == true && styles.expanded)}>
            <a href="/logout" title={this.props.logoutLabel}>
              {renderNavElement("sign-out-alt", this.props.logoutLabel)}
            </a>
            <Logo
              type="icon"
              maxWidth={20}
            />
          </ul>
        </ul>



      </div>
    )
  }
}
