import cx from 'classnames'
import React from 'react';
import PropTypes from 'prop-types';

import styles from './styles.css'

export default function TextArea (props) {
  let {maxWidth, height, placeholder, value, ...passThrough} = props

  var dimensions = {
    maxWidth: maxWidth + "px",
    height: height + "px"
  }

  return (
      <textarea
        className={styles.textarea}
        style={dimensions}
        value={value}
        placeholder={placeholder}
        {...passThrough} >
        {props.children}
        </textarea>
  )
}

TextArea.propTypes = {
  maxHeight:PropTypes.number,
  maxWidth:PropTypes.number,
  placeholder:PropTypes.string
}
