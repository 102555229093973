import cx from 'classnames'
import React from 'react';
import FontAwesomeIcon from '@fortawesome/react-fontawesome'
import {faSearch} from '@fortawesome/fontawesome-free-solid'
import PropTypes from 'prop-types';

import styles from './styles.css'
import Button from '../button'

export default class Search extends React.Component {

  constructor(props) {
    super()
    this.state = {
      inputContent: ""
    };
    this._updateInput = this._updateInput.bind(this)
  }

  _updateInput(e) {
    this.setState({inputContent: e.target.value});
  }

  _handleKeyPress(e, props) {
    if (e.key == 'Enter') {
      props.onSearch(this.state.inputContent);
    }
  }

  render() {
    var dimensions = {
      maxWidth: this.props.maxWidth + "px",
    }

    return (
      <div className={styles.flex}>
        <input
          className={styles.search}
          style={dimensions}
          placeholder={this.props.placeholder}
          onChange={this._updateInput}
          onKeyPress={(e)=>this._handleKeyPress(e, this.props)}
        />
        <Button
          type=""
          buttonText={this.props.buttonText}
          onClick={(e)=>this.props.onSearch(this.state.inputContent)}/>
      </div>
    )
  }
}

Search.propTypes = {
  buttonText:PropTypes.string,
  maxWidth:PropTypes.number,
  placeholder:PropTypes.string,
}
