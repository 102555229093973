import React from 'react';
import FontAwesomeIcon from '@fortawesome/react-fontawesome'
import {faChevronCircleRight, faChevronCircleLeft} from '@fortawesome/fontawesome-free-solid'
import PropTypes from 'prop-types'
import Markdown from 'react-markdown/with-html'
import cx from 'classnames'

import styles from './styles.css'
import {Colors} from '../colors';

class CreateDrawer extends React.Component {

  constructor(props) {
    super()
    this.state = {
      contentVisible: props.startOpen,
    };
  }

  _toggleContent(viewContent){
    this.setState({contentVisible: !viewContent});
  }

  render(props) {

    let width
    if (this.state.contentVisible) {
      width = {maxWidth: this.props.maxWidthOpen}
    } else {
      width = {maxWidth: this.props.maxWidthClosed}
    }

    return (
      <div className={cx(styles.drawer, !this.state.contentVisible && styles.closed, this.state.contentVisible && styles.open)} style={width}>
        <a onClick={()=>this._toggleContent(this.state.contentVisible)}>
            <FontAwesomeIcon className={cx(styles.icon, !this.state.contentVisible && styles.iconClosed)} icon={["fas","chevron-circle-left"]}/>
        </a>
        <span className={cx(!this.state.contentVisible && styles.fadeIn, this.state.contentVisible && styles.fadeOut)}>
          {!this.state.contentVisible && this.props.closed}
        </span>
        <span className={cx(!this.state.contentVisible && styles.fadeOut, this.state.contentVisible && styles.fadeIn)}>
          {this.state.contentVisible && this.props.open}
        </span>
      </div>
    )
  }
}

export default function Drawer(props) {
  return (
    <CreateDrawer
      maxWidthOpen={props.maxWidthOpen}
      maxWidthClosed={props.maxWidthClosed}
      open={props.open}
      closed={props.closed}
      startOpen={props.startOpen}
    />
  )
}

Drawer.propTypes = {
  maxWidthOpen:PropTypes.number,
  maxWidthClosed:PropTypes.number,
  startOpen:PropTypes.boolean
}
