import _ from 'lodash'

import {quadrants} from '../styles'

import {
  lincoln,
  aristotle,
  armstrong,
  bach,
  bigge,
  seattle,
  carter,
  churchill,
  chopin,
  confucius,
  corbusier,
  curie,
  einstein,
  gates,
  geronimo,
  gandhi,
  henson,
  hoover,
  jackson,
  kant,
  lagarde,
  lamb,
  lee,
  teresa,
  obama,
  oprah,
  picasso,
  queen,
  ramanujan,
  sousa,
  thatcher,
  vonnegut,
  walensa
} from './graphics'

/**
  Each brain is defined in the following
  {
    id: unique id used throughout programatically,
    img: link to the image asset,
    x: x position on a standard whole brain model,
    y: y position on a standard whole brain model,
    name: display name,
    link: link to wikipedia page/more info link,
    profile: [a,b,c,d] profile ranks using the following scale (number=> code)
      1=>3, 2=>2, 3=>1, 4=>1+
  }
*/

const brains = [
  {
    id: "aristotle",
    img: aristotle,
    x: -63,
    y: -20,
    link: "https://en.wikipedia.org/wiki/Aristotle",
    profile: {A:3,B:3,C:2,D:2},
    traits: ["artistic","innovative","breaks_rules","visual","implements","expressive"]
  },
  {
    id: "armstrong",
    img: armstrong,
    x: 75,
    y: 35,
    name: "Louis Armstrong",
    link: "https://en.wikipedia.org/wiki/Louis_Armstrong",
    profile: {A:1,B:2,C:4,D:3},
    traits: ["musical","intuitive_emotionally","artistic","innovative","implements"]
  },
  {
    id: "bach",
    img: bach,
    x: -3,
    y: -25,
    link: "https://en.wikipedia.org/wiki/Johann_Sebastian_Bach",
    traits: ["precise","prolific","independently_minded","critical","knows_the_basis_of_things","technical"],
    profile: {A:4,B:3,C:3,D:3}
  },
  {
    id: "bigge",
    img: bigge,
    x: -3,
    y: -25,
    link: "https://en.wikipedia.org/wiki/John_Bigge",
    traits: ["controlled","detailed","dominant","organized","preventitive","critical"],
    profile: {A:2,B:4,C:2,D:2}
  },
  {
    id: "carter",
    img: carter,
    x: -3,
    y: -25,
    link: "https://en.wikipedia.org/wiki/Jimmy_Carter",
    traits: ["implements","preventitive","people_oriented","spiritual","strategic"],
    profile: {A:2,B:3,C:3,D:3}
  },
  {
    id: "chopin",
    img: chopin,
    x: 0,
    y: 0,
    link: "https://en.wikipedia.org/wiki/Frédéric_Chopin",
    profile: {A:2,B:3,C:3,D:3},
    traits: ["empathetic","innovative","musical","prolific","writer","expressive"]
  },
  {
    id: "churchill",
    img: churchill,
    x: 25,
    y: -29,
    link: "https://en.wikipedia.org/wiki/Winston_Churchill",
    profile: {A:3,B:3,C:3,D:4},
    traits: ["synthesizing","future_oriented","strategic","technical","breaks_rules","logical","writer"]
  },
  {
    id: "confucius",
    img: confucius,
    x: -7,
    y: 75,
    link: "https://en.wikipedia.org/wiki/Confucius",
    profile: {A:2,B:4,C:4,D:2},
    traits: ["teaching","friendly","musical","artistic","curious","playful"]
  },
  {
    id: "corbusier",
    img: corbusier,
    x: -7,
    y: 75,
    link: "https://en.wikipedia.org/wiki/Le_Corbusier",
    profile: {A:2,B:3,C:2,D:3},
    traits: ["prolific","future_oriented","artistic","detailed","implements"]
  },
  {
    id: "curie",
    img: curie,
    x: 20,
    y: -60,
    link: "https://en.wikipedia.org/wiki/Marie_Curie",
    traits: ["synthesizing", "future_oriented","strategic", "technical","breaks_rules","logical"],
    profile: {A:3,B:2,C:2,D:3}
  },
  {
    id: "einstein",
    img: einstein,
    x: 38,
    y: -80,
    link: "https://en.wikipedia.org/wiki/Albert_Einstein",
    profile: {A:3,B:1,C:2,D:4},
    traits: ["independently_minded","talker","innovative","intellectual_thinking","writer","synthesizing"]
  },
  {
    id: "gates",
    img: gates,
    x: -25,
    y: -63,
    link: "https://en.wikipedia.org/wiki/Bill_Gates",
    profile: {A:4,B:3,C:2,D:3},
    traits: ["precise","detailed","prolific","independently_minded","critical","knows_the_basis_of_things","technical"]
  },
  {
    id: "geronimo",
    img: geronimo,
    x: -40,
    y: 75,
    link: "https://en.wikipedia.org/wiki/Geronimo",
    profile: {A:2,B:4,C:3,D:1},
    traits: ["spiritual","empathetic","organized","preventitive","people_oriented","dominant"]
  },
  {
    id: "gandhi",
    img: gandhi,
    x: 55,
    y: 65,
    link: "https://en.wikipedia.org/wiki/Mahatma_Gandhi",
    profile: {A:2,B:2,C:4,D:2},
    traits: ["empathetic","sensitive_to_others","spiritual","teaching","independently_minded","future_oriented"]
  },
  {
    id: "henson",
    img: henson,
    x: 85,
    y: 0,
    link: "https://en.wikipedia.org/wiki/Jim_Henson",
    traits: ["teaching", "friendly","musical","artistic","curious","playful"],
    profile: {A:1,B:1,C:4,D:4}
  },
  {
    id: "hoover",
    img: hoover,
    x: -65,
    y: 15,
    link: "https://en.wikipedia.org/wiki/J._Edgar_Hoover",
    traits: ["factual","problem_solving","detailed","organized","controlled","dominant"],
    profile: {A:3,B:4,C:1,D:1}
  },
  {
    id: "jackson",
    img: jackson,
    x: -65,
    y: 15,
    link: "https://en.wikipedia.org/wiki/Mary_Jackson_(engineer)",
    traits: ["intellectual_thinking","knows_the_basis_of_things","people_oriented","mathematical","technical","empathetic"],
    profile: {A:4,B:2,C:3,D:2}
  },
  {
    id: "kant",
    img: kant,
    x: 0,
    y: 0,
    link: "https://en.wikipedia.org/wiki/Immanuel_Kant",
    traits: ["factual","problem_solving","detailed","dominant","logical","critical"],
    profile: {A:3,B:3,C:2,D:2}
  },
  {
    id: "lagarde",
    img: lagarde,
    x: -30,
    y: -35,
    link: "https://en.wikipedia.org/wiki/Christine_Lagarde",
    profile: {A:4,B:3,C:2,D:3},
    traits: ["factual","detailed","financial","logical","synthesizing","implements","speaker"]
  },
  {
    id: "lamb",
    img: lamb,
    x: -30,
    y: -35,
    link: "https://en.wikipedia.org/wiki/William_Kaye_Lamb",
    profile: {A:2,B:4,C:2,D:2},
    traits: ["detailed","organized","controlled","historian"]
  },
  {
    id: "lee",
    img: lee,
    x: 50,
    y: -20,
    profile: {A:2,B:2,C:3,D:4},
    traits: ["writer","sensitive_to_others","artistic","visual","independently_minded","breaks_rules"]
  },
  {
    id: "lincoln",
    img: lincoln,
    x: 0,
    y: 0,
    link: "https://en.wikipedia.org/wiki/Abraham_Lincoln",
    profile: {A:3,B:3,C:3,D:3},
    traits: ["problem_solving","implements","preventitive","people_oriented","expressive","future_oriented","strategic"]
  },
  {
    id: "obama",
    img: obama,
    x: 0,
    y: 0,
    link: "https://en.wikipedia.org/wiki/Barack_Obama",
    profile: {A:3,B:2,C:3,D:3},
    traits: ["financial","intellectual_thinking","expressive","people_oriented","visionary"]
  },
  {
    id: "oprah",
    img: oprah,
    x: 33,
    y: 0,
    link: "https://en.wikipedia.org/wiki/Oprah_Winfrey",
    traits: ["intuitive_emotionally", "talker", "expressive", "intellectual_thinking","writer","synthesizing"],
    profile: {A:3,B:3,C:4,D:4}
  },
  {
    id: "picasso",
    img: picasso,
    x: 70,
    y: -40,
    link: "https://en.wikipedia.org/wiki/Pablo_Picasso",
    traits: ["artistic","innovative","breaks_rules","visual","implements","expressive"],
    profile: {A:2,B:2,C:2,D:4}
  },
  {
    id: "queen",
    img: queen,
    x: -5,
    y: 35,
    link: "https://en.wikipedia.org/wiki/Elizabeth_II",
    profile: {A:2,B:3,C:3,D:2},
    traits: ["controlled","dominant","expressive","people_oriented","preventitive"]
  },
  {
    id: "ramanujan",
    img: ramanujan,
    x: -5,
    y: 35,
    link: "https://en.wikipedia.org/wiki/Srinivasa_Ramanujan",
    profile: {A:4,B:2,C:2,D:2},
    traits: ["intellectual_thinking","factual","mathematical","logical","scientific"]
  },
  {
    id: "sousa",
    img: sousa,
    x: -5,
    y: 35,
    link: "https://en.wikipedia.org/wiki/John_Philip_Sousa",
    profile: {A:3,B:4,C:3,D:2},
    traits: ["musical","writer","sensitive_to_others","logical","reliable"]
  },
  {
    id: "teresa",
    img: teresa,
    x: 25,
    y: 85,
    link: "https://en.wikipedia.org/wiki/Mother_Teresa",
    traits: ["spiritual","empathetic","organized","preventitive","people_oriented","sensitive_to_others"],
    profile: {A:2,B:3,C:4,D:2}
  },
  {
    id: "thatcher",
    img: thatcher,
    x: -20,
    y: 15,
    link: "https://en.wikipedia.org/wiki/Margaret_Thatcher",
    profile: {A:3,B:4,C:3,D:3},
    traits: ["logical","detailed","organized","future_oriented","strategic","expressive"]
  },
  {
    id: "seattle",
    img: seattle,
    x: 58,
    y: -65,
    profile: {A:3,B:1,C:2,D:4},
    traits: ["future_oriented","dominant","strategic","problem_solving","expressive","independently_minded"]
  },
  {
    id: "vonnegut",
    img: vonnegut,
    x: 58,
    y: -65,
    link: "https://en.wikipedia.org/wiki/Kurt_Vonnegut",
    profile: {A:2,B:4,C:2,D:4},
    traits: ["prolific","detailed","dominant","imaginative","independently_minded"]
  },
  {
    id: "walensa",
    img: walensa,
    x: 10,
    y: 55,
    link: "https://en.wikipedia.org/wiki/Lech_Wa%C5%82%C4%99sa",
    profile: {A:2,B:3,C:3,D:2},
    traits: ["dominant","speaker","problem_solving","people_oriented","organized","expressive","breaks_rules"]
  }
]

export const list = brains.map(b => _.defaults(b, { traits: ["lorem","ipsum","dolor", "sit", "amet", "interdum", "tortor"]}))

export const map = _.fromPairs(brains.map((b) => [b.id, b]))

export const traitClasses={
  administrative: quadrants.B,
  artistic: quadrants.D,
  breaks_rules: quadrants.D,
  controlled: quadrants.B,
  critical: quadrants.A,
  curious: quadrants.D,
  detailed: quadrants.B,
  dominant: quadrants.B,
  empathetic: quadrants.C,
  expressive: quadrants.C,
  factual: quadrants.A,
  financial: quadrants.A,
  friendly: quadrants.C,
  future_oriented: quadrants.D,
  implements: quadrants.B,
  historian: quadrants.B,
  imaginative: quadrants.D,
  independently_minded: quadrants.D,
  innovative: quadrants.D,
  intellectual_thinking: quadrants.A,
  intuitive_emotionally: quadrants.C,
  knows_the_basis_of_things: quadrants.A,
  logical: quadrants.A,
  musical: quadrants.C,
  mathematical: quadrants.A,
  organized: quadrants.B,
  people_oriented: quadrants.C,
  playful: quadrants.D,
  practical: quadrants.B,
  precise: quadrants.A,
  preventitive: quadrants.B,
  problem_solving: quadrants.A,
  prolific: quadrants.D,
  writer: quadrants.C,
  reliable: quadrants.B,
  scientific: quadrants.A,
  sensitive_to_others: quadrants.C,
  social_activist: quadrants.C,
  speaker: quadrants.B,
  spiritual: quadrants.C,
  strategic: quadrants.D,
  synthesizing: quadrants.D,
  talker: quadrants.C,
  technical: quadrants.A,
  teaching: quadrants.C,
  visual: quadrants.D,
  visionary: quadrants.D
}
