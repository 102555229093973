import cx from 'classnames'
import React from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import Markdown from 'react-markdown/with-html'

import styles from './styles.css'
import Button from '../button'
import {Colors} from '../colors';

export default class Modal extends React.Component {

  constructor(props) {
    super()
    this.state = {
      modalVisible: true,
    };
  }

  _toggleModal(viewModal){
    this.setState({modalVisible: !viewModal});
  }

  render() {

    let border = {borderColor: Colors[this.props.color]}

    return (
      <>
      <div className={cx(styles.modalWrapper, {[styles.dismissed]: this.state.modalVisible == false})}>
        <div className={styles.modal} style={border}>
          { this.props.close == true &&
            <div className={styles.closeWrapper}>
              <a onClick={()=>this._toggleModal(this.state.modalVisible)} className={styles.close}> X </a>
            </div>
          }
          <div className={styles.content}>
            {this.props.children}
          </div>
        </div>
      </div>
      </>
    )
  }
}

Modal.propTypes = {
  title:PropTypes.string,
  description:PropTypes.string,
  color:PropTypes.oneOf([
    'red', 'green', 'blue', 'yellow', 'navy']),
  buttonText:PropTypes.string,
  buttonLink:PropTypes.string
}
