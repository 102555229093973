import cx from 'classnames'
import React from 'react';

import styles from '../global-styles.css'

export default function () {
  return (
    <span className={styles.textExample}>
      <h1>h1 Header</h1>
      <p>For top-of-page navigation use.</p>
      <h2>h2 Header</h2>
      <p>For content headers.</p>
      <h3>h3 Header</h3>
      <p>For content subheaders.</p>
      <h4>h4 Header</h4>
      <p>For navigation subheaders.</p>
      <h5>h5 Header</h5>
      <p>For body text content subheaders.</p>
      <hr/>
      <p>Body text.</p>
      <p>Body text with an <a>in-text link</a></p>
      <ul>
        <li>Bulleted text.</li>
        <li>More bullets.</li>
      </ul>
      <p className={styles.innerText}>Placeholder Text</p>
    </span>
  )
}
