import React, { Component } from "react";
import { TimelineMax, CSSPlugin } from "gsap/all";

class BrainyBunch extends Component {

	render(props){

    var maxWidth = {
      maxWidth: this.props.maxWidth + 'px',
      width: '100%'
    }

		return (

			<svg version="1.1" id="Layer_1" x="0px" y="0px"
				 viewBox="0 0 1150 900" style="enable-background:new 0 0 1150 900;" style={maxWidth}>

			<g id="blue">
				<path fill="#030202" d="M515.3,306.7c-46-6-90.1,8.8-85.8,40.2c0,0,5.6,39,7.8,54.8c-14.6-1.2-27.6,8.2-29.5,21.8
					c-1.6,11.3,5,21.9,15.4,26.9c-22.2,19.3-2.7,40.2-2.7,40.2c18.7,17.9,42.6-11.1,42.6-11.1c15.1,48.8,53.7,2.1,53.7,2.1l18.5-105.5
					C550.8,305,515.3,306.7,515.3,306.7z"/>
				<path fill="#6CBD8D" d="M523.6,407.2L523.6,407.2c-1.9,0-3.4-1.6-3.3-3.5l0,0c0-1.9,1.6-3.4,3.5-3.3h0c1.9,0,3.4,1.6,3.3,3.5l0,0
					C527,405.7,525.4,407.2,523.6,407.2z"/>
				<path fill="#EDC2D3" d="M519.2,434.7c-12.8-5.6-38.5,13.7-8.9,34.1l47,32.6c0,0,29.2,8.7,1.9-26.6
					C559.2,474.9,533.4,440.2,519.2,434.7z"/>
				<path fill="#EDC2D3" d="M525.8,420.2c12.4,39.7,27.5,86.3,40.9,80.2c0,0,27-11.6-34.7-85.5C528.9,411.3,524.3,415.6,525.8,420.2z"/>
				<path fill="#EDC2D3" d="M518.4,430.4c1.7,1.3,4.1,1.4,6.2,0.8c2.1-0.6,3.9-1.8,5.6-3c2.7-1.9,5.4-4,6.8-7c1.7-3.6,1.2-7.9,0.7-11.9
					c-0.3-2.3-0.6-4.6-1-6.9c-0.1-1-0.3-2.1-0.9-2.9c-0.5-0.8-1.3-1.4-2.1-2c-2.5-1.8-5.1-3.7-7.6-5.5c-0.9-0.7-1.9-1.4-3.1-1.6
					c-3.2-0.6-5.6,2.5-7.9,4.8c-1.8,1.7-3.9,3.1-6.2,3.9c-0.5,0.2-1,0.4-1.3,0.8c-0.8,1.2,1,2.5,2.4,2.6c2.8,0.3,5.6-0.6,7.8-2.5
					c0.7-0.1,0.3,1.2-0.3,1.5c-2,1-4.3,1.2-6.5,1.7c-2.2,0.5-4.4,1.5-5.5,3.4c-1.7,2.9,0.4,7.2,3.7,7.7c-1.1,2.5,0.6,5.9,3.4,6.4
					c-0.6-0.1,0,2.4,0.1,2.6c0.5,1,1.6,1.3,2.3,2.1C516.5,427,516.6,429.2,518.4,430.4z"/>
				<path fill="#EDC2D3" d="M429.3,469.3c-3.6,22.4-5.8,44.9-6.6,67.6c-0.1,3.5-0.2,7.2,1.4,10.4s5.5,5.4,8.7,4c2.1-0.9,3.4-3,4.4-5
					c12.2-22.8,15.9-49.1,18.2-74.9c0.7-8.1,1-27-11.5-26.6C433.5,445,430.5,461.5,429.3,469.3z"/>
				<path fill="#EDC2D3" d="M422.8,539.7c-1.5,2.6-2,5.6-2.5,8.5c-1.8,11.5-6.9,40.6-6.2,52.2c0.3,5.3,1,10.8,3.4,15.6
					c4-0.2,6.4-4.4,7.9-8.1c4.5-11.1,13.6-42.3,14.9-54.3C441.1,544.8,430.9,525.4,422.8,539.7z"/>
				<path fill="#EDC2D3" d="M390.7,617.5c-1.1,0.7-2.4,1.8-2.1,3c0.3,1.5,2.3,1.7,3.8,1.5c5.1-0.6,10.1-1.6,15-3
					c-5.3,7.6-8.8,16.3-10.1,25.4c-0.3,1.8-0.2,4.2,1.6,4.8c4.2-7.2,8.3-14.4,12.5-21.5c-1.1,7.1-1.8,14.2-2.1,21.3
					c-0.1,2,0.6,4.8,2.6,4.6c1.6-0.1,2.3-2,2.7-3.5c1.6-6.8,3.1-13.6,4.7-20.4c2.2,7.3,5.4,14.3,9.4,20.8c1.9,0.1,2.2-2.7,1.7-4.5
					c-1.8-7.3-3.6-14.5-5.4-21.8c4.2,3.6,8.8,6.7,13.8,9.2c0.9,0.5,2.4,0.8,2.9-0.2c0.3-0.6,0-1.3-0.3-1.8c-4.5-8.3-13-20.4-23-22.9
					C409.5,606.2,397.8,613.1,390.7,617.5z"/>
				<g>
					<polygon fill="#4DC7EE" points="503.1,435 459.7,434.3 436.6,451.2 468.3,530.3 501.1,528.2 535.5,464.8 		"/>
					<circle fill="#4DC7EE" cx="484.7" cy="529.2" r="16.4"/>
				</g>
				<polyline fill="#EDC2D3" points="475.5,431.3 492.1,429.5 489.4,451.4 486.6,451.4 463.5,438.8 	"/>
				<g>
					<polygon fill="#EDC2D3" points="473.6,415.1 477.2,438 492.7,435.2 489.9,412.1 		"/>
					<circle fill="#EDC2D3" cx="484.9" cy="436" r="7.9"/>
					<circle fill="#EDC2D3" cx="481.7" cy="413.1" r="8.3"/>
				</g>
				<polygon fill="#FFFFFF" points="489.4,451.4 492.1,429.5 515.8,433.9 	"/>
				<polygon fill="#FFFFFF" points="485.9,451.5 475.5,431.3 454.7,441.9 	"/>
				<path fill="#EDC2D3" d="M456.3,640.1c0,0,11.6,62.5,32,95.6c7.8,12.7,27.3,7.4,27.8-7.5l4-122.6C521.3,563.3,444.2,555.5,456.3,640.1z
					"/>
				<path fill="#EDC2D3" d="M524.5,850.2c-45.3-71-44.5-106.5-34.2-124.1c6.8-11.7,24.8-7.5,25.7,6.1L524.5,850.2"/>
				<polygon fill="#EDC2D3" points="543.9,867.6 525.5,834.8 524.9,826.3 512,828.2 514.5,841 513.8,841.6 515,843.4 517.4,856
					519.3,849.8 527.4,861.8 	"/>
				<polygon fill="#070808" points="534.8,873 533,858.7 512,828.2 527.7,825.9 527.4,823.8 510.7,826.2 510.4,826.3 510.7,828.4
					510.7,828.4 517.8,875.5 517.8,875.5 517.8,875.5 518.6,872.3 522.1,857.3 	"/>
				<polygon fill="#070808" points="529.3,857.4 534.8,873 564.7,869 	"/>
				<path fill="#EDC2D3" d="M446.7,626.5c0,0-10.6,62.6-2.9,100.7c2.9,14.6,23.1,16.3,28.7,2.5l46-113.8
					C534.1,576.7,464.4,542.9,446.7,626.5z"/>
				<path fill="#EDC2D3" d="M440.5,849.6c-20.1-81.8-8-115.1,7.4-128.5c10.2-8.9,25.9,0.9,22.4,14L440.5,849.6"/>
				<polygon fill="#EDC2D3" points="456.6,872.1 447,835.8 448.4,827.4 435.5,826 434.7,839.1 433.9,839.5 434.6,841.5 433.8,854.3
					437.2,848.9 442,862.5 	"/>
				<polygon fill="#070808" points="446.4,875.1 448.2,860.8 435.5,826 451.3,827.8 451.5,825.6 434.7,823.8 434.4,823.8 434.2,825.9
					434.2,825.9 429.3,873.3 429.3,873.3 429.3,873.3 430.8,870.4 438,856.8 	"/>
				<polygon fill="#070808" points="443.9,858 446.4,875.1 476.4,878.7 	"/>
				<polyline fill="#030202" points="458.1,518.1 456.9,525.1 513.5,526.1 512.1,519 	"/>
				<path fill="#030202" d="M416.4,674.2c33.3,16.3,71.3,22.7,108.1,18.2c1.5-0.2,3.2-0.4,4.3-1.5c1.5-1.4,1.5-3.6,1.5-5.6
					c-0.1-32.7-0.2-65.4-0.3-98.1c-0.1-21.3,1.4-44-17.7-57.9c-37-26.8-73.9,6.9-81.5,43.1C428.9,582.1,410.7,671.4,416.4,674.2z"/>
				<path fill="#4DC7EE" d="M440.3,639.8c29,10.2,59.5,12,89.9,10.2c0-12.3-0.1-24.6-0.1-37c-22.4,5.1-46,6.1-68.7,2.9
					c-12.5-1.8-25.5-5-37.1-10.7c-1.5,8.2-3.1,17.1-4.5,25.7C426.4,634.4,433.2,637.4,440.3,639.8z"/>
				<path fill="#EDC2D3" d="M492.8,424.8c0,0,23.7-1,27.1-26.2c0,0,5.2-35.2-4.8-57c-12.2-26.7-67.2-26.9-69,10.4c0,0,0.6,44.3,14.8,61.9
					C472.8,426.2,482.8,425.1,492.8,424.8z"/>
				<path fill="#030202" d="M473.8,423.4c0,0,15.2,68.6-33.1,76.2c0,0-41.8-6.5-17.1-44.5c0,0-40.9-30.3,7.3-48.5l1.2,0
					c6.3,3,12,5.9,16.9,10.8C454.2,422.8,463.1,428.7,473.8,423.4z"/>
				<circle fill="#030202" cx="432.5" cy="434.6" r="25.6"/>
				<path fill="#6CBD8D" d="M445.7,417.2L445.7,417.2c-1.9,0-3.4-1.6-3.3-3.5l0,0c0-1.9,1.6-3.4,3.5-3.3l0,0c1.9,0,3.4,1.6,3.3,3.5l0,0
					C449.1,415.7,447.5,417.2,445.7,417.2z"/>
				<path fill="none" stroke="#ED3553" stroke-width="2.7706" stroke-miterlimit="10" d="M465.4,392.9l37,5.2C502.4,398.2,484.7,426.6,465.4,392.9z"/>
				<polyline fill="none" stroke="#030202" stroke-width="1.7546" stroke-miterlimit="10" points="487.5,355.2 492.9,390.3 483.3,385.5 483.6,395.5 	"/>

					<ellipse transform="matrix(0.9974 -7.185023e-02 7.185023e-02 0.9974 -24.8331 37.4548)" fill="#030202" cx="508.2" cy="363.9" rx="2.1" ry="6.7"/>
				<path fill="none" stroke="#030202" stroke-width="1.6721" stroke-miterlimit="10" d="M505.2,358.1c-0.1,1.2-0.2,2.6-0.1,4.1c0.3,5.2,1.8,9.3,3.5,9.2c1.6-0.1,2.8-4.3,2.5-9.5c-0.1-1-0.2-2.1-0.3-3
					"/>
				<path fill="none" stroke="#030202" stroke-width="1.7546" stroke-miterlimit="10" d="M496.4,353.1c0,0,10.7-8.6,20-1.3"/>
				<path fill="#030202" d="M436.1,380.4c0,0,97.1-8.1,75.2-90.1C511.3,290.3,403,291.5,436.1,380.4z"/>
			</g>
			<g id="green">
				<path fill="#6CBD8D" d="M62.5,510.4l5.2-32.7l95.6,21.2c0,0,0,0,0,0c9.3,2.1,15.1,11.3,13,20.6c-2.9,10.2-10.7,15.2-20.6,13
					c-0.1,0-0.1,0-0.2,0l0,0.1L62.5,510.4z"/>
				<g>
					<polyline fill="#030202" points="324.2,608.4 312.4,736.1 269.3,736.2 262.4,608.7 		"/>
					<circle fill="#030202" cx="293.3" cy="606.7" r="30.9"/>
					<circle cx="290.9" cy="734.1" r="21.6"/>
				</g>
				<g>
					<polyline points="312.6,735.1 303.1,852.9 270.9,852.6 269.4,731.6 		"/>
					<circle cx="290.9" cy="734.2" r="21.6"/>
				</g>
				<g>
					<polyline fill="#030202" points="275.3,851.4 275.9,869.4 343.3,871.6 299.8,858.9 294.1,846.5 		"/>
					<circle fill="#030202" cx="285.3" cy="851.2" r="10"/>
				</g>
				<g>
					<polyline fill="#030202" points="221,608.1 219.5,736.3 262.3,740.8 282.5,614.7 		"/>
					<circle fill="#030202" cx="251.9" cy="609.5" r="30.9"/>
					<circle cx="241.1" cy="736.5" r="21.6"/>
				</g>
				<g>
					<polyline points="219.5,734.7 214.6,852.8 246.6,856.4 262.8,736.5 		"/>
					<circle cx="241.1" cy="736.4" r="21.6"/>
				</g>
				<g>
					<polyline fill="#030202" points="240.8,852.7 241.6,870.7 174.5,877.9 216.9,862 221.7,849.2 		"/>
					<circle fill="#030202" cx="230.8" cy="853.2" r="10"/>
				</g>
				<path fill="#030202" d="M253.7,644.7l38.3-0.3c17.6-0.1,31.7-14.4,31.7-31.9v-0.4c1.2-65.3-102.2-70-102.2,0.7l0,0
					C221.5,630.5,236,644.8,253.7,644.7z"/>
				<g>
					<polygon fill="#F6E1C8" points="299.3,393.8 298.8,422.7 279.1,421.9 278.6,393 		"/>
					<circle fill="#F6E1C8" cx="288.9" cy="421.6" r="9.9"/>
					<circle fill="#F6E1C8" cx="289" cy="392.8" r="10.4"/>
				</g>
				<path fill="#F6E1C8" d="M322.4,378.3c4.6,6.3,15.5,6.8,20.7,0.9c5.2-5.9,3.2-16.4-3.9-20c-1.9-0.9-4.5-0.4-5.6,1.1
					C328.4,365.5,318.2,372.2,322.4,378.3z"/>
				<path fill="#F6E1C8" d="M267.6,406.5c-7.2-3-10.5-11.7-10.8-19.4c-0.9-21.9-1.3-43.8-1.3-65.6c0-8.2,0.1-16.6,3.4-24.2
					c13.8-32.1,54-20.6,70.8,1c18.8,24.1,6.5,58,2.9,85.6c-1.2,8.8-2.7,18.2-8.7,24.9c-6,6.7-14.2,6-24.9,3.9
					C298.9,412.7,271.3,408.1,267.6,406.5z"/>
				<path d="M344.8,323.2l-6.3,26.9l-3.9,12.4l-3-40.1c-1.2-25-27.7-42.3-27.7-42.3C330,287.1,347.2,309.9,344.8,323.2z"/>
				<path fill="#F6E1C8" d="M256.4,371.3c-6.1,0.6-11.5-4.8-12.6-10.7s1.1-11.9,4-17.2c2-3.6,6.7-7.5,9.7-4.7
					C264.5,345.1,273.1,369.3,256.4,371.3z"/>
				<path d="M251.7,285.3l14.1,26.3l-7.3,57.8l-3.6-14C254.9,355.4,229.2,292.4,251.7,285.3z"/>
				<path d="M251.2,294.1c-0.2-6.7,4.2-22.5,30.3-20.7c0,0,49,4.6,66.3,49.4c0,0-7.7,2.7-19,2.1c0,0-55.4-5.4-74-20.5
					C251.6,301.2,251.3,295.8,251.2,294.1z"/>
				<path fill="#F6E1C8" d="M321.3,566.6c5.1-2.5,11.6,1,14.3,6c2.6,5,2.4,10.9,2.1,16.6c5.4,0,10.9-1,16-2.7c-1,4.6-5.3,8.4-10.1,9
					c4.2,2.3,9,7.1,6.1,10.9c-0.9,1.2-2.3,1.8-3.8,2.2c-9.3,2.6-19.4-2.7-24.6-10.7c-5.1-8-6.1-17.9-5.2-27.3"/>
				<path fill="#F6E1C8" d="M355.1,599.2c8.2-1.1,16.3-2.1,24.5-3.2c-5.3,5.8-13.6,8.8-21.5,7.7c2.2,2.8,6.5,2.7,10.1,2.9s8.1,1.7,8.4,5.3
					c-8.2,1.9-17.1,1.3-24.9-1.7c3,6.1,7.3,11.7,12.4,16.3c0.1,2.5-4.1,2-6.2,0.5c-8.2-5.7-39.2-20.8-31.3-29.4
					c2.9-3.1,15.4-2.6,19.4-3.6c9.1-2.3,17.4-7.1,26.7-8.8C369.4,589.7,361.2,598.4,355.1,599.2z"/>
				<polyline fill="#6CBD8D" points="329.9,551.8 367.4,582.6 340.7,590.4 313.1,571 	"/>
				<path fill="#6CBD8D" d="M406.4,545.1L339.1,588L325,567.6l39.3-38.8C374.6,518.4,400.4,520.5,406.4,545.1z"/>
				<path fill="#6CBD8D" d="M406.4,545.1c0,0-20.7-66.1-64.1-113c0,0-29.1-4.3-32.2,18c-1.4,10.4,1.6,23.9,15.2,39.8
					c13.6,15.9,45,52.8,45,52.8C378.7,552.3,396.7,551.3,406.4,545.1z"/>
				<polygon fill="#6CBD8D" points="342.2,432.1 233.4,418.6 206.2,464.9 221.5,612.8 323.7,612.1 	"/>
				<path fill="#6CBD8D" d="M206.2,427.5c-7.5,12.8-0.1,35.6,1.3,50.4c1.6,16.1,28-9.7,35.5-22.5c7.5-12.8,5.1-34.8-9.6-36.7
					C218.4,416.7,213.7,414.7,206.2,427.5z"/>
				<polygon fill="#6CBD8D" points="306.2,419.1 307.2,440.3 271,433.4 270.8,414.8 	"/>
				<path fill="#6CBD8D" d="M150.4,528.7c-7.9-5.3-10-15.9-4.8-23.8c0,0,0.1-0.1,0.1-0.1l0,0l52.8-82.9c4.1-6.5,12-9.7,19.5-7.9l0,0
					c12.2,2.9,17.8,17.1,10.9,27.6l-54.6,82.4c0,0,0,0,0,0C168.9,531.8,158.3,533.9,150.4,528.7z"/>
				<polyline fill="#6CBD8D" points="66,461.4 52.9,505.3 83.6,514.4 86.4,467.5 	"/>
				<path fill="#F6E1C8" d="M29.2,469.7c5.1-5.1,12.4-8.1,19.7-8.1c2.5,0,5.2,0.5,6.8,2.4c1.6,1.9,1,5.6-1.4,6c2.9-0.7,6.2-1.4,8.7,0.4
					c2.4,1.7,1.8,6.7-1.2,6.5c5.8-0.1,12.8,0.5,15.3,5.7c3,6.4-3.9,13.2-10.5,15.7c-7.6,2.8-16,3.3-23.8,1.6c-3.4-0.8-7-2.2-8.8-5.2
					c-1.7-3-0.2-7.9,3.3-8.2c-4.3,0.3-8.7-0.2-12.9-1.4c0.9-2.7,2.7-5.1,5.1-6.6c-3.5,2.4-8.8-1.6-8.2-5.8c0.6-4.2,5.8-6.7,9.8-5.3"/>
				<polyline fill="none" stroke="#030202" stroke-width="1.6244" stroke-miterlimit="10" points="298.7,346.2 306,386.2 296.6,380.4 296.4,390.2 	"/>
				<path fill="none" stroke="#030202" stroke-width="1.3825" stroke-miterlimit="10" d="M312.9,384c0,0-20.8,19-39.1-8.2"/>
				<path fill="none" stroke="#000000" stroke-width="4.4767" stroke-linecap="round" stroke-miterlimit="10" d="M303.9,354c0,0-3.6-12.4-10-0.6"/>

					<ellipse transform="matrix(0.9947 -0.1027 0.1027 0.9947 -34.726 30.3638)" fill="#FFFFFF" stroke="#000000" stroke-width="4.4767" stroke-miterlimit="10" cx="277.4" cy="352.3" rx="16.5" ry="16.1"/>

					<ellipse transform="matrix(0.9947 -0.1027 0.1027 0.9947 -34.7817 34.7821)" fill="#FFFFFF" stroke="#000000" stroke-width="4.4767" stroke-miterlimit="10" cx="320.3" cy="355.1" rx="16.5" ry="16.1"/>
				<polygon fill="#070808" points="265.4,334.3 292.9,336 293.3,331.2 265.8,329.5 	"/>
				<polygon fill="#070808" points="307.2,334.6 334.7,336.4 335,331.6 307.5,329.8 	"/>
				<line id="pocket_line_1_" fill="none" stroke="#000000" stroke-width="2.0117" stroke-miterlimit="10" x1="257.8" y1="476.9" x2="219.1" y2="480.9"/>
				<g>
					<path fill="#2EC0F0" d="M246.2,477l-2.2-20.9c-0.2-2.4-2.4-4.1-4.8-3.8l0,0c-2.4,0.2-4.2,2.4-3.9,4.7l2.2,20.9"/>
				</g>
				<g>
					<path fill="#ED3553" d="M234.5,478.2l-2.2-20.9c-0.2-2.4-2.4-4.1-4.8-3.8h0c-2.4,0.2-4.2,2.4-3.9,4.7l2.2,20.9"/>
				</g>
				<polygon fill="#050505" points="268.3,458 292.5,459 293.7,435.3 269.5,434.2 	"/>
				<g>
					<polyline fill="#050505" points="280.9,447.5 295.6,569 272.8,610.1 		"/>
					<polyline fill="#050505" points="280.9,447.5 254.2,567.2 272.8,610.1 		"/>
				</g>
			</g>
			<g id="yellow">
				<g>
					<path fill="#030202" d="M925,748.2l-28.5-143.6l71.3-11.3c0,0,9.7,69.8,7.8,149.3"/>
					<circle fill="#030202" cx="949.8" cy="741.4" r="25.8"/>
					<circle fill="#030202" cx="931.7" cy="596.7" r="36.1"/>
				</g>
				<g>
					<path fill="#030202" d="M975.5,742.4c-0.9,37.5-4.3,74.3-12.7,112.5l-17.2,3.7l-21.1-112"/>
					<circle fill="#030202" cx="949.8" cy="741.4" r="25.8"/>
				</g>
				<g>
					<polyline fill="#030202" points="962.8,855 1001.9,865.7 950.9,871.6 950.3,856.3 		"/>
					<circle fill="#030202" cx="956.4" cy="855.4" r="6.2"/>
				</g>
				<g>
					<path fill="#030202" d="M914.3,743.8l5.7-146.3h-72.2c0,0,1.3,70.4,15.7,148.7"/>
					<circle fill="#030202" cx="888.7" cy="741" r="25.8"/>
					<circle fill="#030202" cx="883.9" cy="595.2" r="36.1"/>
				</g>
				<g>
					<path fill="#030202" d="M863.5,746c6.8,36.9,15.9,72.7,30.2,109.2l17.5,1l3.3-113.9"/>
					<circle fill="#030202" cx="888.7" cy="741" r="25.8"/>
				</g>
				<g>
					<polyline fill="#030202" points="893.7,855.1 856.7,871.9 908,869.7 906.2,854.3 		"/>
					<circle fill="#030202" cx="900" cy="854.6" r="6.2"/>
				</g>
				<g>
					<polygon fill="#FAD26A" points="892.8,446.3 870.6,517.6 896.6,559 954.5,558 979.7,504 950.7,442.8 		"/>
					<circle fill="#FAD26A" cx="925.1" cy="549" r="33.5"/>
				</g>
				<g>
					<path fill="#FAD26A" d="M955.7,558.4l-60.8-19.6c0,0-49.3,5.5-62.3,71.7c0,0,68.8,56.7,143,47.1
						C975.5,657.6,994.4,572.1,955.7,558.4z"/>
					<circle fill="#FAD26A" cx="926.7" cy="549" r="33.5"/>
				</g>
				<g>
					<circle fill="#F0DCE3" cx="809.2" cy="461.2" r="14.5"/>
					<path fill="#F0DCE3" d="M805.1,447.3c0,0,9-1.8,19.9-1.6c21,0.4,49.4,30.1-14.3,30L805.1,447.3z"/>
					<polygon fill="#FAD26A" points="886.5,446.5 812.4,444.8 825,479.8 890.2,479 		"/>
					<circle fill="#FAD26A" cx="888.2" cy="462.8" r="16.3"/>
				</g>
				<g>
					<polyline fill="#FAD26A" points="950.9,442.5 1023.9,448.4 1022,476.3 951.7,484.4 950.9,442.5 		"/>
					<circle fill="#FAD26A" cx="950.4" cy="463.5" r="21"/>
				</g>
				<path fill="#FFFFFF" d="M932.4,502.3l-0.1,0c0-0.1,0-0.2,0-0.4c-0.6-11.5-10.4-20.3-21.9-19.7s-20.4,10.8-19.8,22.3l0,0
					c-3.5,31.5-0.2,63.7,9.4,93.9c16-4.6,31.9-9.3,47.9-13.9C939.2,557.9,934,530.2,932.4,502.3z"/>
				<g>
					<g>
						<defs>
							<path id="SVGID_1_" d="M932.4,502.3l-0.1,0c0-0.1,0-0.2,0-0.4c-0.6-11.5-10.4-20.3-21.9-19.7s-20.4,10.8-19.8,22.3l0,0
								c-3.5,31.5-0.2,63.7,9.4,93.9c16-4.6,31.9-9.3,47.9-13.9C939.2,557.9,934,530.2,932.4,502.3z"/>
						</defs>
						<clipPath id="SVGID_2_">
							<use href="#SVGID_1_" overflow="visible"/>
						</clipPath>
						<g id="_x38_Ju9wR_2_" clip-path="url(#SVGID_2_)">
							<g>
								<path fill="#FAD26A" d="M903.5,486.8c0.2-2.3-1.6-4.3-3.9-4.5c-2.3-0.2-4.3,1.6-4.5,3.9c-0.2,2.3,1.6,4.3,3.9,4.5
									C901.4,490.8,903.4,489.1,903.5,486.8z M902.6,503.5c0.1-2.3-1.6-4.3-3.9-4.4c-2.3-0.1-4.3,1.6-4.4,3.9
									c-0.1,2.3,1.6,4.3,3.9,4.4C900.5,507.5,902.5,505.8,902.6,503.5z M901.7,520.3c0.2-2.3-1.6-4.3-3.9-4.5
									c-2.3-0.2-4.3,1.6-4.5,3.9c-0.2,2.3,1.5,4.3,3.9,4.5C899.5,524.3,901.5,522.6,901.7,520.3z M920.8,479.3
									c0.1-2.3-1.6-4.3-3.9-4.4c-2.3-0.1-4.3,1.6-4.4,3.9c-0.1,2.3,1.6,4.3,3.9,4.4C918.7,483.4,920.7,481.6,920.8,479.3z M930,542.7
									c2.3,0.1,4.3-1.6,4.4-4c0.1-2.3-1.7-4.3-4-4.4c-2.3-0.1-4.3,1.7-4.4,4C925.9,540.7,927.6,542.6,930,542.7z M935.3,522
									c0.1-2.3-1.7-4.3-4-4.4c-2.3-0.1-4.3,1.7-4.4,4c-0.1,2.3,1.6,4.3,4,4.4C933.2,526.1,935.2,524.4,935.3,522z M911.8,566.9
									c2.3,0.1,4.2-1.6,4.3-4c0.1-2.3-1.7-4.3-4-4.4c-2.3-0.1-4.3,1.7-4.4,4C907.7,564.9,909.5,566.8,911.8,566.9z M915.1,508.4
									c-2.3-0.2-4.3,1.6-4.5,3.9c-0.2,2.3,1.6,4.3,3.9,4.5c2.4,0.2,4.3-1.5,4.5-3.9C919.1,510.5,917.4,508.5,915.1,508.4z
									 M917.1,546.2c0.1-2.3-1.7-4.3-4-4.4c-2.3-0.1-4.3,1.7-4.4,4c-0.1,2.3,1.6,4.2,4,4.3C915.1,550.3,917,548.5,917.1,546.2z
									 M919.9,496.1c0.1-2.3-1.6-4.3-3.9-4.4c-2.3-0.1-4.3,1.6-4.5,3.9c-0.1,2.3,1.6,4.3,3.9,4.4
									C917.8,500.1,919.7,498.4,919.9,496.1z M896.8,532.5c-2.3-0.1-4.3,1.7-4.4,4c-0.1,2.3,1.6,4.2,4,4.4c2.3,0.1,4.3-1.6,4.4-4
									C900.9,534.6,899.1,532.6,896.8,532.5z M936.2,505.3c0.1-2.3-1.6-4.3-3.9-4.4c-2.3-0.1-4.3,1.6-4.4,3.9
									c-0.1,2.3,1.6,4.3,3.9,4.4C934.1,509.4,936.1,507.7,936.2,505.3z M915.2,579.7c0.1-2.3-1.7-4.3-3.9-4.4c-2.3-0.1-4.3,1.7-4.4,4
									c-0.1,2.3,1.6,4.3,4,4.4C913.1,583.8,915.1,582,915.2,579.7z M914,525.1c-2.3-0.1-4.3,1.7-4.4,4c-0.1,2.3,1.7,4.2,4,4.4
									c2.3,0.1,4.3-1.6,4.4-4C918.1,527.2,916.3,525.2,914,525.1z M899.8,553.8c0.2-2.3-1.6-4.3-3.9-4.5c-2.3-0.2-4.3,1.6-4.5,3.9
									c-0.2,2.3,1.6,4.3,3.9,4.5C897.7,557.8,899.6,556.1,899.8,553.8z M897.9,587.2c0.1-2.3-1.6-4.3-3.9-4.4
									c-2.3-0.1-4.3,1.6-4.4,3.9c-0.1,2.3,1.6,4.3,3.9,4.4C895.9,591.2,897.8,589.5,897.9,587.2z M931.5,589.1
									c0.2-2.3-1.6-4.3-3.9-4.5c-2.3-0.2-4.3,1.6-4.5,3.9c-0.2,2.3,1.6,4.3,3.9,4.4C929.4,593.1,931.4,591.4,931.5,589.1z
									 M932.5,572.3c0.2-2.3-1.6-4.3-3.9-4.5c-2.3-0.2-4.3,1.6-4.5,3.9c-0.2,2.3,1.6,4.3,3.9,4.4
									C930.4,576.3,932.3,574.6,932.5,572.3z M933.4,555.5c0.1-2.3-1.7-4.3-4-4.4c-2.3-0.1-4.3,1.7-4.4,4c-0.1,2.3,1.6,4.3,4,4.4
									C931.4,559.6,933.3,557.8,933.4,555.5z M914.3,596.5c0.1-2.3-1.6-4.3-3.9-4.4c-2.3-0.1-4.3,1.6-4.5,3.9
									c-0.1,2.3,1.6,4.3,3.9,4.4C912.2,600.5,914.1,598.8,914.3,596.5z M898.9,570.4c0.1-2.3-1.7-4.3-4-4.4c-2.3-0.1-4.3,1.7-4.4,4
									c-0.1,2.3,1.6,4.2,4,4.3C896.9,574.4,898.8,572.7,898.9,570.4z M948.8,581.5c0.1-2.3-1.7-4.3-3.9-4.4c-2.3-0.1-4.3,1.7-4.4,4
									c-0.1,2.3,1.7,4.3,4,4.4C946.7,585.6,948.7,583.9,948.8,581.5z M949.7,564.8c0.1-2.3-1.6-4.3-3.9-4.4c-2.3-0.1-4.3,1.7-4.4,4
									c-0.1,2.3,1.7,4.3,4,4.4C947.7,568.9,949.6,567.2,949.7,564.8z"/>
								<path fill="#FAD26A" d="M903.5,486.8c-0.2,2.3-2.1,4-4.5,3.9c-2.3-0.2-4.1-2.1-3.9-4.5c0.2-2.3,2.2-4,4.5-3.9
									C902,482.5,903.7,484.5,903.5,486.8z"/>
								<path fill="#FAD26A" d="M902.6,503.5c-0.1,2.3-2.1,4.1-4.4,3.9c-2.3-0.1-4.1-2.1-3.9-4.4c0.1-2.3,2.1-4.1,4.4-3.9
									C901,499.2,902.7,501.2,902.6,503.5z"/>
								<path fill="#FAD26A" d="M901.7,520.3c-0.2,2.3-2.2,4-4.5,3.9c-2.3-0.2-4-2.2-3.9-4.5c0.2-2.3,2.2-4,4.5-3.9
									C900.1,516,901.8,518,901.7,520.3z"/>
								<path fill="#FAD26A" d="M920.8,479.3c-0.1,2.3-2.1,4.1-4.4,3.9c-2.3-0.1-4.1-2.1-3.9-4.4c0.1-2.3,2.1-4.1,4.4-3.9
									C919.2,475,920.9,477,920.8,479.3z"/>
								<path fill="#FAD26A" d="M930,542.7c-2.3-0.1-4.1-2-4-4.3c0.1-2.3,2.1-4.1,4.4-4c2.3,0.1,4.1,2.1,4,4.4
									C934.2,541.1,932.3,542.8,930,542.7z"/>
								<path fill="#FAD26A" d="M935.3,522c-0.1,2.3-2.1,4.1-4.4,4c-2.3-0.1-4.1-2.1-4-4.4c0.1-2.3,2.1-4.1,4.4-4
									C933.6,517.8,935.4,519.7,935.3,522z"/>
								<path fill="#FAD26A" d="M911.8,566.9c-2.3-0.1-4.1-2-4-4.3c0.1-2.3,2.1-4.1,4.4-4c2.3,0.1,4.1,2,4,4.4
									C916,565.3,914.1,567,911.8,566.9z"/>
								<path fill="#FAD26A" d="M915.1,508.4c2.3,0.2,4,2.2,3.9,4.5c-0.2,2.3-2.1,4-4.5,3.9c-2.3-0.2-4.1-2.1-3.9-4.5
									C910.7,509.9,912.8,508.2,915.1,508.4z"/>
								<path fill="#FAD26A" d="M917.1,546.2c-0.1,2.3-2,4.1-4.4,4c-2.4-0.1-4.1-2-4-4.3c0.1-2.3,2.1-4.1,4.4-4
									C915.4,541.9,917.2,543.9,917.1,546.2z"/>
								<path fill="#FAD26A" d="M919.9,496.1c-0.1,2.3-2.1,4.1-4.4,3.9c-2.3-0.1-4.1-2.1-3.9-4.4c0.1-2.3,2.2-4.1,4.5-3.9
									C918.3,491.8,920,493.8,919.9,496.1z"/>
								<path fill="#FAD26A" d="M896.8,532.5c2.3,0.1,4.1,2.1,4,4.4c-0.1,2.3-2,4.1-4.4,4c-2.3-0.1-4.1-2-4-4.4
									C892.5,534.2,894.5,532.4,896.8,532.5z"/>
								<path fill="#FAD26A" d="M936.2,505.3c-0.1,2.3-2.1,4.1-4.4,3.9c-2.3-0.1-4.1-2.1-3.9-4.4c0.1-2.3,2.1-4.1,4.4-3.9
									C934.6,501.1,936.3,503,936.2,505.3z"/>
								<path fill="#FAD26A" d="M915.2,579.7c-0.1,2.3-2.1,4.1-4.4,3.9c-2.3-0.1-4.1-2.1-4-4.4c0.1-2.3,2.1-4.1,4.4-4
									C913.5,575.4,915.3,577.4,915.2,579.7z"/>
								<path fill="#FAD26A" d="M914,525.1c2.3,0.1,4.1,2.1,4,4.4c-0.1,2.3-2,4.1-4.4,4c-2.3-0.1-4.1-2-4-4.4
									C909.7,526.8,911.7,525,914,525.1z"/>
								<path fill="#FAD26A" d="M899.8,553.8c-0.2,2.3-2.1,4-4.5,3.9c-2.3-0.2-4.1-2.1-3.9-4.5c0.2-2.3,2.2-4.1,4.5-3.9
									C898.2,549.4,900,551.4,899.8,553.8z"/>
								<path fill="#FAD26A" d="M897.9,587.2c-0.1,2.3-2.1,4-4.4,3.9c-2.3-0.1-4.1-2.1-3.9-4.4c0.1-2.3,2.1-4.1,4.4-3.9
									C896.3,582.9,898.1,584.9,897.9,587.2z"/>
								<path fill="#FAD26A" d="M931.5,589.1c-0.2,2.3-2.1,4-4.5,3.9c-2.3-0.2-4.1-2.1-3.9-4.4c0.2-2.3,2.2-4.1,4.5-3.9
									C930,584.8,931.7,586.7,931.5,589.1z"/>
								<path fill="#FAD26A" d="M932.5,572.3c-0.2,2.3-2.1,4-4.5,3.9c-2.3-0.2-4.1-2.1-3.9-4.4c0.2-2.3,2.2-4.1,4.5-3.9
									C930.9,568,932.6,570,932.5,572.3z"/>
								<path fill="#FAD26A" d="M933.4,555.5c-0.1,2.3-2.1,4.1-4.4,3.9c-2.3-0.1-4.1-2.1-4-4.4c0.1-2.3,2.1-4.1,4.4-4
									C931.7,551.2,933.5,553.2,933.4,555.5z"/>
								<path fill="#FAD26A" d="M914.3,596.5c-0.1,2.3-2.1,4-4.4,3.9c-2.3-0.1-4.1-2.1-3.9-4.4c0.1-2.3,2.2-4.1,4.5-3.9
									C912.7,592.2,914.4,594.2,914.3,596.5z"/>
								<path fill="#FAD26A" d="M898.9,570.4c-0.1,2.3-2,4.1-4.4,4c-2.4-0.1-4.1-2-4-4.3c0.1-2.3,2.1-4.1,4.4-4
									C897.2,566.1,899,568.1,898.9,570.4z"/>
								<path fill="#FAD26A" d="M948.8,581.5c-0.1,2.3-2.1,4.1-4.4,3.9c-2.3-0.1-4.1-2.1-4-4.4c0.1-2.3,2.2-4.1,4.4-4
									C947.1,577.2,948.9,579.2,948.8,581.5z"/>
								<path fill="#FAD26A" d="M949.7,564.8c-0.1,2.3-2.1,4.1-4.4,3.9c-2.3-0.1-4.1-2.1-4-4.4c0.1-2.3,2.2-4.1,4.4-4
									C948.1,560.5,949.9,562.5,949.7,564.8z"/>
							</g>
						</g>
					</g>
					<path fill="#6CBD8D" d="M896.4,585.8c1.1,4.3,2.3,8.6,3.7,12.9c16-4.6,31.9-9.3,47.9-13.9c-1.1-3.3-2.1-6.7-3.1-10
						C928.8,578.4,912.6,582,896.4,585.8z"/>
				</g>
				<path fill="#FFFFFF" d="M932.4,502.3c5.7,1.2,12-0.8,16.1-4.9c4.1-4.2,5.8-10.5,4.5-16.2c8.7-3,12.2-14.2,9.8-23
					c-4.1-15-20.1-33.8-36.2-24.3c-4.6,2.7-10.2-0.7-15.5-1.6c-7.4-1.4-15.1,2.5-19.7,8.4c-4.6,6-6.4,13.6-6.6,21.1
					c-8.2,4.4-13.1,14.1-11.9,23.3c1.2,9.2,8.6,17.2,17.7,19.3"/>
				<g>
					<defs>
						<path id="SVGID_3_" d="M926.6,434.2c-4.6,2.7-10.2-0.7-15.5-1.6c-7.4-1.4-15.1,2.5-19.7,8.4s-6.4,13.6-6.6,21.1
							c-8.2,4.4-13.1,14.1-11.9,23.3s8.6,17.2,17.7,19.3c3.6,3.1,29.8-4.9,41.9-2.2c5.7,1.2,12-0.8,16.1-4.9c4.1-4.2,5.8-10.5,4.5-16.2
							c8.7-3,12.2-14.2,9.8-23C958.7,443.5,942.8,424.7,926.6,434.2z"/>
					</defs>
					<clipPath id="SVGID_4_">
						<use href="#SVGID_3_" overflow="visible"/>
					</clipPath>
					<g id="_x38_Ju9wR_5_" clip-path="url(#SVGID_4_)">
						<g>
							<path fill="#FAD26A" d="M904.5,470c0.1-2.3-1.7-4.3-4-4.4c-2.3-0.1-4.3,1.7-4.4,4c-0.1,2.3,1.7,4.3,4,4.4
								C902.4,474.1,904.4,472.3,904.5,470z M905.4,453.3c0.1-2.3-1.6-4.3-3.9-4.4c-2.3-0.1-4.3,1.6-4.4,3.9c-0.1,2.3,1.6,4.3,3.9,4.4
								C903.3,457.4,905.3,455.6,905.4,453.3z M903.5,486.8c0.2-2.3-1.6-4.3-3.9-4.5c-2.3-0.2-4.3,1.6-4.5,3.9
								c-0.2,2.3,1.6,4.3,3.9,4.5C901.4,490.8,903.4,489.1,903.5,486.8z M906.3,436.6c0.2-2.3-1.6-4.3-3.9-4.4
								c-2.3-0.2-4.3,1.6-4.5,3.9c-0.2,2.3,1.6,4.3,3.9,4.5C904.2,440.6,906.2,438.9,906.3,436.6z M902.6,503.5
								c0.1-2.3-1.6-4.3-3.9-4.4c-2.3-0.1-4.3,1.6-4.4,3.9c-0.1,2.3,1.6,4.3,3.9,4.4C900.5,507.5,902.5,505.8,902.6,503.5z
								 M886.3,494.1c0.1-2.3-1.7-4.3-4-4.4c-2.3-0.1-4.3,1.7-4.4,4c-0.1,2.3,1.7,4.2,4,4.3C884.2,498.2,886.2,496.4,886.3,494.1z
								 M920.8,479.3c0.1-2.3-1.6-4.3-3.9-4.4c-2.3-0.1-4.3,1.6-4.4,3.9c-0.1,2.3,1.6,4.3,3.9,4.4C918.7,483.4,920.7,481.6,920.8,479.3
								z M887.2,477.5c0.1-2.3-1.6-4.3-4-4.4c-2.3-0.1-4.3,1.6-4.4,3.9c-0.1,2.3,1.6,4.3,3.9,4.4C885.1,481.5,887.1,479.8,887.2,477.5z
								 M917.8,458.2c-2.3-0.1-4.3,1.7-4.4,4c-0.1,2.3,1.7,4.3,4,4.4c2.3,0.1,4.3-1.6,4.4-4C921.9,460.2,920.1,458.3,917.8,458.2z
								 M884.2,456.3c-2.3-0.2-4.3,1.6-4.5,3.9c-0.2,2.3,1.6,4.3,3.9,4.5c2.3,0.2,4.3-1.6,4.5-3.9C888.3,458.5,886.5,456.5,884.2,456.3
								z M889.1,444c0.1-2.3-1.7-4.3-4-4.4c-2.3-0.1-4.3,1.6-4.4,3.9c-0.1,2.3,1.7,4.3,4,4.4C887,448.1,889,446.3,889.1,444z
								 M922.7,445.8c0.1-2.3-1.6-4.3-4-4.4c-2.3-0.1-4.3,1.7-4.4,4c-0.1,2.3,1.7,4.3,4,4.4C920.6,449.9,922.6,448.1,922.7,445.8z
								 M953.5,497.9c0.1-2.3-1.6-4.3-3.9-4.4c-2.3-0.1-4.3,1.6-4.5,3.9c-0.1,2.3,1.6,4.3,3.9,4.4C951.4,502,953.3,500.2,953.5,497.9z
								 M935.6,442.3c2.3,0.1,4.2-1.7,4.3-4c0.1-2.3-1.7-4.2-4-4.3c-2.3-0.1-4.3,1.7-4.4,4C931.5,440.3,933.3,442.3,935.6,442.3z
								 M933.1,484.2c-2.3-0.1-4.3,1.7-4.4,4c-0.1,2.3,1.7,4.2,4,4.3c2.3,0.1,4.2-1.7,4.3-4C937.2,486.2,935.4,484.3,933.1,484.2z
								 M951.8,451.6c2.3,0.1,4.3-1.6,4.4-3.9c0.1-2.3-1.6-4.3-3.9-4.4c-2.3-0.1-4.3,1.6-4.5,3.9C947.7,449.5,949.5,451.5,951.8,451.6z
								 M954.4,481.1c0.1-2.3-1.6-4.3-4-4.4c-2.3-0.1-4.3,1.7-4.4,4c-0.1,2.3,1.7,4.3,4,4.4C952.4,485.2,954.3,483.5,954.4,481.1z
								 M919.9,496.1c0.1-2.3-1.6-4.3-3.9-4.4c-2.3-0.1-4.3,1.6-4.5,3.9c-0.1,2.3,1.6,4.3,3.9,4.4C917.8,500.1,919.7,498.4,919.9,496.1
								z M936.2,505.3c0.1-2.3-1.6-4.3-3.9-4.4c-2.3-0.1-4.3,1.6-4.4,3.9c-0.1,2.3,1.6,4.3,3.9,4.4
								C934.1,509.4,936.1,507.7,936.2,505.3z M934.7,459.1c2.3,0.1,4.2-1.7,4.3-4c0.1-2.3-1.7-4.2-4-4.3c-2.3-0.1-4.3,1.7-4.4,4
								C930.5,457,932.3,459,934.7,459.1z M951.5,460c-2.3-0.2-4.3,1.6-4.5,3.9c-0.2,2.3,1.6,4.3,3.9,4.5c2.3,0.2,4.3-1.6,4.5-3.9
								C955.5,462.1,953.8,460.2,951.5,460z M938.1,471.9c0.2-2.3-1.6-4.3-3.9-4.5c-2.3-0.2-4.3,1.6-4.5,3.9c-0.2,2.3,1.6,4.3,3.9,4.5
								C935.9,475.9,937.9,474.2,938.1,471.9z"/>
							<path fill="#FAD26A" d="M904.5,470c-0.1,2.3-2.1,4.1-4.4,4c-2.3-0.1-4.1-2.1-4-4.4c0.1-2.3,2.1-4.1,4.4-4
								C902.8,465.7,904.6,467.7,904.5,470z"/>
							<path fill="#FAD26A" d="M905.4,453.3c-0.1,2.3-2.1,4.1-4.4,3.9c-2.3-0.1-4.1-2.1-3.9-4.4c0.1-2.3,2.1-4.1,4.4-3.9
								C903.8,449,905.6,451,905.4,453.3z"/>
							<path fill="#FAD26A" d="M903.5,486.8c-0.2,2.3-2.1,4-4.5,3.9c-2.3-0.2-4.1-2.1-3.9-4.5c0.2-2.3,2.2-4,4.5-3.9
								C902,482.5,903.7,484.5,903.5,486.8z"/>
							<path fill="#FAD26A" d="M906.3,436.6c-0.2,2.3-2.2,4.1-4.5,3.9c-2.3-0.2-4.1-2.2-3.9-4.5c0.2-2.3,2.1-4,4.5-3.9
								C904.8,432.3,906.5,434.3,906.3,436.6z"/>
							<path fill="#FAD26A" d="M902.6,503.5c-0.1,2.3-2.1,4.1-4.4,3.9c-2.3-0.1-4.1-2.1-3.9-4.4c0.1-2.3,2.1-4.1,4.4-3.9
								C901,499.2,902.7,501.2,902.6,503.5z"/>
							<path fill="#FAD26A" d="M886.3,494.1c-0.1,2.3-2.1,4.1-4.4,4c-2.3-0.1-4.1-2-4-4.3c0.1-2.3,2.1-4.1,4.4-4
								C884.6,489.9,886.4,491.8,886.3,494.1z"/>
							<path fill="#FAD26A" d="M920.8,479.3c-0.1,2.3-2.1,4.1-4.4,3.9c-2.3-0.1-4.1-2.1-3.9-4.4c0.1-2.3,2.1-4.1,4.4-3.9
								C919.2,475,920.9,477,920.8,479.3z"/>
							<path fill="#FAD26A" d="M887.2,477.5c-0.1,2.3-2.1,4.1-4.4,3.9c-2.3-0.1-4.1-2.1-3.9-4.4c0.1-2.3,2.1-4.1,4.4-3.9
								C885.6,473.2,887.4,475.2,887.2,477.5z"/>
							<path fill="#FAD26A" d="M917.8,458.2c2.3,0.1,4.1,2.1,4,4.4c-0.1,2.3-2.1,4.1-4.4,4c-2.3-0.1-4.1-2.1-4-4.4
								C913.5,459.8,915.5,458,917.8,458.2z"/>
							<path fill="#FAD26A" d="M884.2,456.3c2.3,0.2,4.1,2.2,3.9,4.5c-0.2,2.3-2.2,4.1-4.5,3.9c-2.3-0.2-4.1-2.1-3.9-4.5
								C879.9,457.9,881.9,456.2,884.2,456.3z"/>
							<path fill="#FAD26A" d="M889.1,444c-0.1,2.3-2.1,4.1-4.4,4c-2.3-0.1-4.1-2.1-4-4.4c0.1-2.3,2.1-4.1,4.4-3.9
								C887.4,439.7,889.2,441.7,889.1,444z"/>
							<path fill="#FAD26A" d="M922.7,445.8c-0.1,2.3-2.1,4.1-4.4,4c-2.3-0.1-4.1-2.1-4-4.4c0.1-2.3,2.1-4.1,4.4-4
								C921,441.5,922.8,443.5,922.7,445.8z"/>
							<path fill="#FAD26A" d="M953.5,497.9c-0.1,2.3-2.1,4.1-4.4,3.9c-2.3-0.1-4.1-2.2-3.9-4.4c0.1-2.3,2.2-4.1,4.5-3.9
								C951.9,493.6,953.6,495.6,953.5,497.9z"/>
							<path fill="#FAD26A" d="M935.6,442.3c-2.3-0.1-4.1-2.1-4-4.3c0.1-2.3,2-4.1,4.4-4c2.3,0.1,4.1,2,4,4.3
								C939.8,440.6,937.9,442.4,935.6,442.3z"/>
							<path fill="#FAD26A" d="M933.1,484.2c2.3,0.1,4.1,2,4,4.3c-0.1,2.3-2,4.1-4.3,4c-2.3-0.1-4.1-2-4-4.3
								C928.9,485.9,930.8,484.1,933.1,484.2z"/>
							<path fill="#FAD26A" d="M951.8,451.6c-2.3-0.1-4.1-2.2-4-4.4c0.1-2.3,2.2-4,4.5-3.9c2.3,0.1,4.1,2.1,3.9,4.4
								C956.1,450,954.2,451.8,951.8,451.6z"/>
							<path fill="#FAD26A" d="M954.4,481.1c-0.1,2.3-2.1,4.1-4.4,4c-2.3-0.1-4.1-2.1-4-4.4c0.1-2.3,2.1-4.1,4.4-4
								C952.8,476.8,954.5,478.8,954.4,481.1z"/>
							<path fill="#FAD26A" d="M919.9,496.1c-0.1,2.3-2.1,4.1-4.4,3.9c-2.3-0.1-4.1-2.1-3.9-4.4c0.1-2.3,2.2-4.1,4.5-3.9
								C918.3,491.8,920,493.8,919.9,496.1z"/>
							<path fill="#FAD26A" d="M936.2,505.3c-0.1,2.3-2.1,4.1-4.4,3.9c-2.3-0.1-4.1-2.1-3.9-4.4c0.1-2.3,2.1-4.1,4.4-3.9
								C934.6,501.1,936.3,503,936.2,505.3z"/>
							<path fill="#FAD26A" d="M934.7,459.1c-2.3-0.1-4.1-2-4-4.3c0.1-2.3,2.1-4.1,4.4-4c2.3,0.1,4.1,2,4,4.3
								C938.9,457.4,937,459.2,934.7,459.1z"/>
							<path fill="#FAD26A" d="M951.5,460c2.3,0.2,4,2.1,3.9,4.5c-0.2,2.3-2.2,4-4.5,3.9c-2.3-0.2-4.1-2.2-3.9-4.5
								C947.1,461.6,949.2,459.8,951.5,460z"/>
							<path fill="#FAD26A" d="M938.1,471.9c-0.2,2.3-2.1,4-4.5,3.9c-2.3-0.2-4.1-2.2-3.9-4.5c0.2-2.3,2.2-4,4.5-3.9
								C936.5,467.6,938.2,469.6,938.1,471.9z"/>
						</g>
					</g>
				</g>
				<path fill="#F0DCE3" d="M791.2,461c-3,9.1,4.6,18.1,12.2,23.9c16,12.4,34.7,20.8,53.3,28.8c6.6,2.9,13.6,5.8,20.8,5.4
					c1.9-0.1,4.5-1.4,3.8-3.2c-0.2-0.5-0.6-0.9-1.1-1.3c-18.6-16.1-32.6-33.4-49.6-50.3C821.4,455.2,797.2,442.5,791.2,461z"/>
				<path id="blue_band_3_" fill="#2EC0F0" d="M874.7,495.2l-24.5,23.2c-1.2,1.1-3,1-4-0.2l0,0c-1-1.2-0.9-3.2,0.3-4.3l24.5-23.2
					c1.2-1.1,3-1,4,0.2l0,0C876,492.2,875.8,494.1,874.7,495.2z"/>
				<path id="blue_band_1_" fill="#ED3553" d="M871.7,503.2l-32.3,9.8c-1.6,0.5-3.1-0.4-3.4-2l0,0c-0.3-1.6,0.7-3.2,2.2-3.7l32.3-9.8
					c1.6-0.5,3.1,0.4,3.4,2l0,0C874.3,501,873.3,502.7,871.7,503.2z"/>
				<path fill="#F0DCE3" d="M864.9,510.1c-6.4,9-2.2,23.6,8.1,27.9c1,0.4,2.1,0.7,3.1,0.6c2.7-0.4,4-3.3,6.2-4.9c2.4-1.7,5.7-1.6,7.9-3.6
					c3.7-3.4,1.3-9.9,3.9-14.2c1.1-1.9,3.2-3.6,2.7-5.7c-0.3-1.2-1.3-2-1.8-3.1c-1.4-3,0.6-8.1-2.6-8.9c-0.7-0.2-1.4,0-2.1,0.1
					c-9.2,2.2-18.2,5.5-26.6,9.9"/>
				<path fill="#F0DCE3" d="M1024.4,480.4c9.3,2.4,17.8-5.7,23.1-13.6c11.3-16.7,18.5-35.8,25.3-54.8c2.4-6.8,4.9-13.9,4-21
					c-0.2-1.9-1.7-4.4-3.5-3.6c-0.5,0.2-0.9,0.7-1.2,1.1c-14.8,19.5-33.6,33.1-49.4,51.1C1014.3,449.4,1005.6,475.6,1024.4,480.4z"/>
				<path fill="#F0DCE3" d="M1099.2,407.7c1.5,0,3.3-0.2,3.8-1.6c0.6-1.6-1.2-3-2.7-3.7c-5.1-2.5-10.5-4.5-15.9-6.1
					c9.5-4,18.1-10.1,24.8-17.8c1.4-1.6,2.7-3.8,1.4-5.5c-8.2,4.3-16.4,8.5-24.7,12.8c5.2-6,10.2-12.3,14.7-18.7
					c1.3-1.8,2.3-4.8,0.3-5.8c-1.6-0.8-3.4,0.5-4.6,1.7c-5.6,5.4-11.1,10.9-16.7,16.3c2.3-8.2,3.5-16.7,3.6-25.2
					c-1.7-1.2-3.7,1.2-4.3,3.2c-2.6,7.9-5.3,15.8-7.9,23.7c-1.8-5.9-4.3-11.6-7.5-16.9c-0.6-1-1.8-2.1-2.8-1.5
					c-0.6,0.4-0.8,1.2-0.8,1.9c-0.8,10.5,0.1,27,8,35.2C1074.6,407.1,1089.8,407.6,1099.2,407.7z"/>
				<g>
					<polygon fill="#F0DCE3" points="913.2,415.5 911.3,440.6 928.4,441.5 931.1,416.5 		"/>
					<circle fill="#F0DCE3" cx="919.9" cy="440.4" r="8.6"/>
					<circle fill="#F0DCE3" cx="922.2" cy="415.5" r="9"/>
				</g>
				<path fill="#FFFFFF" d="M910.1,434.5l10.8,4.5l8-2.4v11.8c0,0-3.6,6.3-20.6-1L910.1,434.5z"/>
				<path fill="#FAD26A" d="M918.4,441.6c2.3,0.1,4.1,2.1,4,4.4c-0.1,2.3-2.1,4.1-4.4,4c-2.3-0.1-4.1-2.1-4-4.4
					C914.1,443.2,916.1,441.4,918.4,441.6z"/>
				<path fill="#F0DCE3" d="M953.8,397.6l4.3-37.2c2.5-21.9-12.3-42.3-34-45.9c-7-1.1-14.4-0.9-22,1.9c-18.7,6.9-29.4,26.7-25.8,46.3
					l6.7,37c0,0,4.7,23.9,30.2,24C937.4,423.8,951.4,418.2,953.8,397.6z"/>
				<path fill="#D9B374" d="M871.9,357.9c0,0,44.9-16.1,91.5,2.4c0,0,10.5-49.1-49.1-49C865.1,311.3,871.9,357.9,871.9,357.9z"/>
				<path fill="none" stroke="#ED3553" stroke-width="1.9332" stroke-miterlimit="10" d="M908.6,406.7l31.1-8.2C939.7,398.5,940.9,427.3,908.6,406.7z"/>
				<path fill="none" stroke="#D9B374" stroke-width="1.6144" stroke-miterlimit="10" d="M951.1,365.8c0,0-3.9-10.5-18.8-5.9"/>

					<ellipse transform="matrix(8.498745e-02 -0.9964 0.9964 8.498745e-02 490.9873 1277.7224)" fill="#030202" cx="941.2" cy="371.5" rx="7.3" ry="2.3"/>
				<path fill="none" stroke="#D9B374" stroke-width="1.6144" stroke-miterlimit="10" d="M883.6,365.7c0,0,4.2-10.4,19-5.4"/>

					<ellipse transform="matrix(0.9983 -5.768354e-02 5.768354e-02 0.9983 -19.9412 52.2485)" fill="#030202" cx="895.1" cy="371.5" rx="2.3" ry="7.3"/>
				<polyline fill="none" stroke="#030202" stroke-width="1.6144" stroke-miterlimit="10" points="915.5,364.4 914.3,397.1 922.8,391.9 922.1,403.1 	"/>
				<g>
					<path fill="#D9B374" d="M917.3,297.1l0.6,45.9c0,0,25.9-0.8,40.6,8.1l-14.5,70.7l37.4,13C981.5,434.9,1026.6,292.5,917.3,297.1z"/>
					<circle fill="#D9B374" cx="918" cy="320" r="23"/>
				</g>
				<g>
					<circle fill="#D9B374" cx="918" cy="320" r="23"/>
					<path fill="#D9B374" d="M917.3,297.1l0.6,45.9c0,0-20.7,1.9-35.5,7.2l11.9,72.4l-39.2,11.6C855.1,434.2,813.2,297,917.3,297.1z"/>
				</g>
				<g id="glass_1_">
					<g>

							<ellipse transform="matrix(0.9985 -5.558283e-02 5.558283e-02 0.9985 -16.8687 50.1781)" fill="none" stroke="#6CBD8D" stroke-width="3.2288" stroke-miterlimit="10" cx="893.6" cy="328.3" rx="15.7" ry="7.3"/>

							<ellipse transform="matrix(0.9985 -5.558283e-02 5.558283e-02 0.9985 -16.6538 52.7495)" fill="none" stroke="#6CBD8D" stroke-width="3.2288" stroke-miterlimit="10" cx="940" cy="325.8" rx="15.7" ry="7.3"/>
						<path fill="none" stroke="#6CBD8D" stroke-width="3.2288" stroke-miterlimit="10" d="M909.3,327.5c0,0,7.7-24.1,15-0.8"/>
						<path fill="none" stroke="#6CBD8D" stroke-width="3.2288" stroke-miterlimit="10" d="M878,329.2"/>
						<line fill="none" stroke="#6CBD8D" stroke-width="3.2288" stroke-miterlimit="10" x1="955.6" y1="324.9" x2="966.8" y2="348.8"/>
					</g>
					<line fill="none" stroke="#6CBD8D" stroke-width="3.2288" stroke-miterlimit="10" x1="878" y1="329.2" x2="875.1" y2="348.7"/>
				</g>
			</g>
			<g id="red">
				<g>
					<polyline points="702.4,851 701.2,876.1 782,879.5 729.8,855 725,846.4 		"/>
					<circle cx="714.3" cy="851.4" r="11.9"/>
				</g>
				<g>
					<path fill="#954A37" d="M633.1,322c-5-1.1-10.1,2.1-13.2,6.2c-3,4.1-4.5,9.4-3.2,14.2c1.4,4.8,6.2,8.8,11.2,8.3
						c4.4-0.5,8-4.2,9.6-8.3s1.7-8.7,1.7-13.2"/>
					<path fill="#954A37" d="M714.6,331.4c4-1.5,8.7,1.1,10.5,5s1.2,8.5-0.5,12.4c-1.4,3.1-3.6,6-6.7,7.5c-3.1,1.5-7.2,1.1-9.5-1.3
						c-2.7-2.8-2.4-7.2-2-11.1"/>
					<path fill="#954A37" d="M633.3,287.4l-2.2,68.4c0,0-5.5,29.8,29.5,37.3c25.5,5.4,50-14.9,51.2-36l10-56.4
						C726.2,255.5,636.7,249,633.3,287.4z"/>
					<path fill="#954A37" d="M660.2,391.3l7.2,26.2c1.6,5.7,7.2,9.3,13.1,8.4l0,0c5.6-0.9,9.7-5.7,9.8-11.3l0.4-27.2
						c0.1-9.7-8.6-17.2-18.2-15.5l0,0C663.4,373.5,657.7,382.5,660.2,391.3z"/>
					<polygon fill="#030202" points="717.6,309 712.2,350.3 734.8,309.2 		"/>
					<path fill="#030202" d="M625.6,296.2l-0.5,35.4l7.2,6.8c0,0,4.6-49.1,4.7-50.3C636.9,286.8,625.6,296.2,625.6,296.2z"/>
					<path fill="#ED3553" d="M606.2,442.2c0,0,41.2-13,31.7,28.9s-18.8,74.4-18.8,74.4c-5.9,20.9-36.5,15.4-34.4-6
						C588.2,503.7,606.2,442.2,606.2,442.2z"/>
					<path fill="#ED3553" d="M584.6,540.6c-4.6,44.3-3.6,87.2,10.1,108.8l44.1-12.7c0,0-16.9-58.3-18.6-94.6
						C618.8,517,587,517,584.6,540.6z"/>
					<path fill="#954A37" d="M605.7,653.5c0.6,7.9-7,13.5-11.9,19.7c-4.5,5.9-6.9,13.3-6.6,20.7c0.1,1.5,0.3,3.2,1.3,4.3
						c1,1.2,3.1,1.5,4,0.3c0.2-6.3,0.7-13.3,5.6-17.3c-1.3,5.7-2,11.6-2,17.4c0,4.5,0.5,9.4,3.9,12.3c0.8,0.7,2.1,1.3,3,0.7
						c1-0.6,1.1-1.9,1-3c-0.4-7.2-2-15.7,3.3-20.6c-0.6,3.8-0.9,7.7-1,11.6c0,4,0.6,8.6,4,10.9c1.2,0.8,3,1,3.8-0.1
						c0.4-0.5,0.4-1.2,0.4-1.8c0-6.5-2.6-13.9,1.6-18.9c-0.4,4.2,0,8.5,1.3,12.5c0.7,2,2.6,4.4,4.5,3.4c0.9-0.5,1.2-1.6,1.4-2.7
						c1.4-8.4-2.7-17.1-0.8-25.5c0.6-2.6,1.7-5,2.4-7.6c1.7-6.7,0.3-13.7-1.7-20.3c-0.6-2-1.3-4.1-2.8-5.5c-1.5-1.5-4.1-2.1-5.7-0.8"/>
					<polygon fill="#FFFFFF" points="636.6,628.5 589.4,644.3 595.5,661.6 643,651.8 		"/>
					<g>
						<polyline points="741.9,743.1 730.8,855.1 698.9,859.5 690.7,744.3 			"/>
						<circle cx="716.3" cy="742.9" r="25.6"/>
					</g>
					<g>
						<polyline points="744.2,620.8 741.9,742.4 691,746.9 674.8,628.8 			"/>
						<circle cx="709.1" cy="621.2" r="35.1"/>
						<circle cx="716.3" cy="742.9" r="25.6"/>
					</g>
					<g>
						<polyline points="668.3,845.5 670,870.6 589.4,876 640.9,850.1 645.5,841.4 			"/>
						<circle cx="656.4" cy="846.2" r="11.9"/>
					</g>
					<g>
						<polyline points="631.2,737.3 639,848.9 670.9,854.8 682.4,739.7 			"/>
						<circle cx="656.8" cy="737.7" r="25.6"/>
					</g>
					<g>
						<polyline points="628,615.7 631.2,737.3 682.2,741.4 697.4,623.2 			"/>
						<circle cx="663.1" cy="615.9" r="35.1"/>
						<circle cx="656.8" cy="737.6" r="25.6"/>
					</g>
					<polygon fill="#FFFFFF" points="630.1,612.3 627.2,634.6 747.3,632 740.9,605.1 		"/>
					<path fill="#ED3553" d="M732.2,428.2l-35-18.7c0,0-53.8,6.9-50.4,4.6c3.4-2.3-40.5,28.1-40.5,28.1l18.7,186.3l122.4-10L732.2,428.2z
						"/>
					<polygon fill="#FAD26A" points="640.1,492.3 620.4,528.1 648.7,554.5 663.7,517.9 		"/>
					<polygon fill="#6CBD8D" points="663.7,517.9 688.1,484.8 721.1,520 692.7,549.2 		"/>
					<g>
						<g>
							<g>
								<g>
									<g>
										<g>

												<rect x="613.3" y="498.7" transform="matrix(0.6851 -0.7284 0.7284 0.6851 -173.0684 614.4207)" fill="none" width="21.6" height="17.3"/>
										</g>

											<rect x="627" y="510" transform="matrix(0.6851 -0.7284 0.7284 0.6851 -174.8596 618.6321)" fill-rule="evenodd" clip-rule="evenodd" fill="#231F20"  width="2.2" height="3.2"/>

											<rect x="622.9" y="506" transform="matrix(0.6851 -0.7284 0.7284 0.6851 -173.3071 614.3354)" fill-rule="evenodd" clip-rule="evenodd" fill="#231F20"  width="2.2" height="3.2"/>

											<rect x="618.7" y="502.1" transform="matrix(0.6851 -0.7284 0.7284 0.6851 -171.7596 610.0528)" fill-rule="evenodd" clip-rule="evenodd" fill="#231F20"  width="2.2" height="3.2"/>
									</g>
								</g>
								<g>
									<g>
										<g>

												<rect x="625.9" y="510.6" transform="matrix(0.6851 -0.7284 0.7284 0.6851 -177.7324 627.3252)" fill="none" width="21.6" height="17.3"/>
										</g>

											<rect x="639.6" y="521.8" transform="matrix(0.6851 -0.7284 0.7284 0.6851 -179.5233 631.5386)" fill-rule="evenodd" clip-rule="evenodd" fill="#231F20"  width="2.2" height="3.2"/>

											<rect x="635.4" y="517.9" transform="matrix(0.6851 -0.7284 0.7284 0.6851 -177.9685 627.2586)" fill-rule="evenodd" clip-rule="evenodd" fill="#231F20"  width="2.2" height="3.2"/>

											<rect x="631.3" y="513.9" transform="matrix(0.6851 -0.7284 0.7284 0.6851 -176.4232 622.9592)" fill-rule="evenodd" clip-rule="evenodd" fill="#231F20"  width="2.2" height="3.2"/>
									</g>
								</g>
								<g>
									<g>
										<g>

												<rect x="638.5" y="522.4" transform="matrix(0.6851 -0.7284 0.7284 0.6851 -182.3958 640.2337)" fill="none" width="21.6" height="17.3"/>
										</g>

											<rect x="652.2" y="533.7" transform="matrix(0.6851 -0.7284 0.7284 0.6851 -184.1848 644.4622)" fill-rule="evenodd" clip-rule="evenodd" fill="#231F20"  width="2.2" height="3.2"/>

											<rect x="648" y="529.7" transform="matrix(0.6851 -0.7284 0.7284 0.6851 -182.6344 640.1483)" fill-rule="evenodd" clip-rule="evenodd" fill="#231F20"  width="2.2" height="3.2"/>

											<rect x="643.9" y="525.8" transform="matrix(0.6851 -0.7284 0.7284 0.6851 -181.0847 635.8826)" fill-rule="evenodd" clip-rule="evenodd" fill="#231F20"  width="2.2" height="3.2"/>
									</g>
								</g>
								<g>
									<g>
										<g>

												<rect x="651.1" y="534.3" transform="matrix(0.6851 -0.7284 0.7284 0.6851 -187.0594 653.1403)" fill="none" width="21.6" height="17.3"/>
										</g>

											<rect x="664.8" y="545.5" transform="matrix(0.6851 -0.7284 0.7284 0.6851 -188.8507 657.3514)" fill-rule="evenodd" clip-rule="evenodd" fill="#231F20"  width="2.2" height="3.2"/>

											<rect x="660.6" y="541.6" transform="matrix(0.6851 -0.7284 0.7284 0.6851 -187.2981 653.0547)" fill-rule="evenodd" clip-rule="evenodd" fill="#231F20"  width="2.2" height="3.2"/>

											<rect x="656.5" y="537.6" transform="matrix(0.6851 -0.7284 0.7284 0.6851 -185.7506 648.772)" fill-rule="evenodd" clip-rule="evenodd" fill="#231F20"  width="2.2" height="3.2"/>
									</g>
								</g>
							</g>
						</g>
					</g>
					<g>
						<g>
							<g>
								<g>
									<g>
										<g>

												<rect x="620.6" y="534" transform="matrix(0.5468 -0.8373 0.8373 0.5468 -169.9058 775.813)" fill="none" width="22.2" height="21.6"/>
										</g>

											<rect x="633.9" y="537.7" transform="matrix(0.5468 -0.8373 0.8373 0.5468 -162.913 776.6962)" fill-rule="evenodd" clip-rule="evenodd" fill="#231F20"  width="4.1" height="2.2"/>

											<rect x="629.9" y="543.9" transform="matrix(0.5468 -0.8373 0.8373 0.5468 -169.9211 776.1179)" fill-rule="evenodd" clip-rule="evenodd" fill="#231F20"  width="4.1" height="2.2"/>

											<rect x="625.8" y="550.1" transform="matrix(0.5468 -0.8373 0.8373 0.5468 -176.9062 775.5414)" fill-rule="evenodd" clip-rule="evenodd" fill="#231F20"  width="4.1" height="2.2"/>
									</g>
								</g>
								<g>
									<g>
										<g>

												<rect x="632.7" y="515.5" transform="matrix(0.5468 -0.8373 0.8373 0.5468 -148.8553 777.5499)" fill="none" width="22.2" height="21.6"/>
										</g>

											<rect x="646" y="519.2" transform="matrix(0.5468 -0.8373 0.8373 0.5468 -141.8653 778.4243)" fill-rule="evenodd" clip-rule="evenodd" fill="#231F20"  width="4.1" height="2.2"/>

											<rect x="642" y="525.4" transform="matrix(0.5468 -0.8373 0.8373 0.5468 -148.8703 777.8552)" fill-rule="evenodd" clip-rule="evenodd" fill="#231F20"  width="4.1" height="2.2"/>

											<rect x="638" y="531.5" transform="matrix(0.5468 -0.8373 0.8373 0.5468 -155.8554 777.2787)" fill-rule="evenodd" clip-rule="evenodd" fill="#231F20"  width="4.1" height="2.2"/>
									</g>
								</g>
								<g>
									<g>
										<g>

												<rect x="644.8" y="496.9" transform="matrix(0.5468 -0.8373 0.8373 0.5468 -127.8044 779.2878)" fill="none" width="22.2" height="21.6"/>
										</g>

											<rect x="658.2" y="500.6" transform="matrix(0.5468 -0.8373 0.8373 0.5468 -120.8147 780.1617)" fill-rule="evenodd" clip-rule="evenodd" fill="#231F20"  width="4.1" height="2.2"/>

											<rect x="654.1" y="506.8" transform="matrix(0.5468 -0.8373 0.8373 0.5468 -127.8227 779.5833)" fill-rule="evenodd" clip-rule="evenodd" fill="#231F20"  width="4.1" height="2.2"/>

											<rect x="650.1" y="512.9" transform="matrix(0.5468 -0.8373 0.8373 0.5468 -134.8046 779.0161)" fill-rule="evenodd" clip-rule="evenodd" fill="#231F20"  width="4.1" height="2.2"/>
									</g>
								</g>
								<g>
									<g>
										<g>

												<rect x="644.8" y="496.9" transform="matrix(0.5468 -0.8373 0.8373 0.5468 -127.8044 779.2878)" fill="none" width="22.2" height="21.6"/>
										</g>

											<rect x="649.5" y="512.6" transform="matrix(0.5468 -0.8373 0.8373 0.5468 -134.7948 778.4124)" fill-rule="evenodd" clip-rule="evenodd" fill="#231F20"  width="4.1" height="2.2"/>

											<rect x="653.6" y="506.4" transform="matrix(0.5468 -0.8373 0.8373 0.5468 -127.7909 778.9787)" fill-rule="evenodd" clip-rule="evenodd" fill="#231F20"  width="4.1" height="2.2"/>

											<rect x="657.6" y="500.3" transform="matrix(0.5468 -0.8373 0.8373 0.5468 -120.8015 779.5673)" fill-rule="evenodd" clip-rule="evenodd" fill="#231F20"  width="4.1" height="2.2"/>
									</g>
								</g>
								<g>
									<g>
										<g>

												<rect x="632.7" y="515.5" transform="matrix(0.5468 -0.8373 0.8373 0.5468 -148.8553 777.5499)" fill="none" width="22.2" height="21.6"/>
										</g>

											<rect x="637.4" y="531.2" transform="matrix(0.5468 -0.8373 0.8373 0.5468 -155.8522 776.6534)" fill-rule="evenodd" clip-rule="evenodd" fill="#231F20"  width="4.1" height="2.2"/>

											<rect x="641.4" y="525" transform="matrix(0.5468 -0.8373 0.8373 0.5468 -148.8443 777.2319)" fill-rule="evenodd" clip-rule="evenodd" fill="#231F20"  width="4.1" height="2.2"/>

											<rect x="645.5" y="518.9" transform="matrix(0.5468 -0.8373 0.8373 0.5468 -141.8524 777.8299)" fill-rule="evenodd" clip-rule="evenodd" fill="#231F20"  width="4.1" height="2.2"/>
									</g>
								</g>
								<g>
									<g>
										<g>

												<rect x="620.6" y="534" transform="matrix(0.5468 -0.8373 0.8373 0.5468 -169.9058 775.813)" fill="none" width="22.2" height="21.6"/>
										</g>

											<rect x="625.3" y="549.8" transform="matrix(0.5468 -0.8373 0.8373 0.5468 -176.8998 774.9254)" fill-rule="evenodd" clip-rule="evenodd" fill="#231F20"  width="4.1" height="2.2"/>

											<rect x="629.3" y="543.6" transform="matrix(0.5468 -0.8373 0.8373 0.5468 -169.891 775.5067)" fill-rule="evenodd" clip-rule="evenodd" fill="#231F20"  width="4.1" height="2.2"/>

											<rect x="633.3" y="537.4" transform="matrix(0.5468 -0.8373 0.8373 0.5468 -162.9032 776.0926)" fill-rule="evenodd" clip-rule="evenodd" fill="#231F20"  width="4.1" height="2.2"/>
									</g>
								</g>
							</g>
						</g>
					</g>
					<g>
						<g>
							<g>
								<g>
									<g>
										<g>

												<rect x="664.6" y="493.1" transform="matrix(0.7181 -0.696 0.696 0.7181 -159.1647 611.6973)" fill="none" width="21.6" height="18.3"/>
										</g>

											<rect x="678.4" y="505.2" transform="matrix(0.7181 -0.696 0.696 0.7181 -161.2376 615.8024)" fill-rule="evenodd" clip-rule="evenodd" fill="#231F20"  width="2.2" height="3.4"/>

											<rect x="674.1" y="500.8" transform="matrix(0.7181 -0.696 0.696 0.7181 -159.3829 611.6167)" fill-rule="evenodd" clip-rule="evenodd" fill="#231F20"  width="2.2" height="3.4"/>

											<rect x="669.9" y="496.5" transform="matrix(0.7181 -0.696 0.696 0.7181 -157.5386 607.4116)" fill-rule="evenodd" clip-rule="evenodd" fill="#231F20"  width="2.2" height="3.4"/>
									</g>
								</g>
								<g>
									<g>
										<g>

												<rect x="677.4" y="506.3" transform="matrix(0.7181 -0.696 0.696 0.7181 -164.7326 624.2955)" fill="none" width="21.6" height="18.3"/>
										</g>

											<rect x="691.2" y="518.4" transform="matrix(0.7181 -0.696 0.696 0.7181 -166.8054 628.4006)" fill-rule="evenodd" clip-rule="evenodd" fill="#231F20"  width="2.2" height="3.4"/>

											<rect x="686.9" y="514" transform="matrix(0.7181 -0.696 0.696 0.7181 -164.9507 624.2151)" fill-rule="evenodd" clip-rule="evenodd" fill="#231F20"  width="2.2" height="3.4"/>

											<rect x="682.7" y="509.6" transform="matrix(0.7181 -0.696 0.696 0.7181 -163.1043 620.0261)" fill-rule="evenodd" clip-rule="evenodd" fill="#231F20"  width="2.2" height="3.4"/>
									</g>
								</g>
								<g>
									<g>
										<g>

												<rect x="690.2" y="519.5" transform="matrix(0.7181 -0.696 0.696 0.7181 -170.3004 636.8937)" fill="none" width="21.6" height="18.3"/>
										</g>

											<rect x="703.9" y="531.6" transform="matrix(0.7181 -0.696 0.696 0.7181 -172.3733 640.9988)" fill-rule="evenodd" clip-rule="evenodd" fill="#231F20"  width="2.2" height="3.4"/>

											<rect x="699.7" y="527.2" transform="matrix(0.7181 -0.696 0.696 0.7181 -170.5197 636.8047)" fill-rule="evenodd" clip-rule="evenodd" fill="#231F20"  width="2.2" height="3.4"/>

											<rect x="695.4" y="522.8" transform="matrix(0.7181 -0.696 0.696 0.7181 -168.671 632.6331)" fill-rule="evenodd" clip-rule="evenodd" fill="#231F20"  width="2.2" height="3.4"/>
									</g>
								</g>
								<g>
									<g>
										<g>

												<rect x="702.9" y="532.6" transform="matrix(0.7181 -0.696 0.696 0.7181 -175.868 649.494)" fill="none" width="21.6" height="18.3"/>
										</g>

											<rect x="716.7" y="544.7" transform="matrix(0.7181 -0.696 0.696 0.7181 -177.94 653.6061)" fill-rule="evenodd" clip-rule="evenodd" fill="#231F20"  width="2.2" height="3.4"/>

											<rect x="712.4" y="540.3" transform="matrix(0.7181 -0.696 0.696 0.7181 -176.0896 649.3854)" fill-rule="evenodd" clip-rule="evenodd" fill="#231F20"  width="2.2" height="3.4"/>

											<rect x="708.2" y="536" transform="matrix(0.7181 -0.696 0.696 0.7181 -174.2389 645.2315)" fill-rule="evenodd" clip-rule="evenodd" fill="#231F20"  width="2.2" height="3.4"/>
									</g>
								</g>
							</g>
						</g>
					</g>
					<g>
						<g>
							<g>
								<g>
									<g>
										<g>

												<rect x="671" y="527.4" transform="matrix(0.5569 -0.8306 0.8306 0.5569 -145.0679 804.4699)" fill="none" width="20.8" height="21.6"/>
										</g>

											<rect x="683.6" y="531.5" transform="matrix(0.5569 -0.8306 0.8306 0.5569 -138.6144 805.413)" fill-rule="evenodd" clip-rule="evenodd" fill="#231F20"  width="3.9" height="2.2"/>

											<rect x="679.8" y="537.3" transform="matrix(0.5569 -0.8306 0.8306 0.5569 -145.0883 804.7723)" fill-rule="evenodd" clip-rule="evenodd" fill="#231F20"  width="3.9" height="2.2"/>

											<rect x="675.9" y="543" transform="matrix(0.5569 -0.8306 0.8306 0.5569 -151.5494 804.1077)" fill-rule="evenodd" clip-rule="evenodd" fill="#231F20"  width="3.9" height="2.2"/>
									</g>
								</g>
								<g>
									<g>
										<g>

												<rect x="682.6" y="510.1" transform="matrix(0.5569 -0.8306 0.8306 0.5569 -125.609 806.4327)" fill="none" width="20.8" height="21.6"/>
										</g>

											<rect x="695.2" y="514.3" transform="matrix(0.5569 -0.8306 0.8306 0.5569 -119.1555 807.3765)" fill-rule="evenodd" clip-rule="evenodd" fill="#231F20"  width="3.9" height="2.2"/>

											<rect x="691.3" y="520" transform="matrix(0.5569 -0.8306 0.8306 0.5569 -125.6291 806.7357)" fill-rule="evenodd" clip-rule="evenodd" fill="#231F20"  width="3.9" height="2.2"/>

											<rect x="687.5" y="525.8" transform="matrix(0.5569 -0.8306 0.8306 0.5569 -132.0905 806.0713)" fill-rule="evenodd" clip-rule="evenodd" fill="#231F20"  width="3.9" height="2.2"/>
									</g>
								</g>
								<g>
									<g>
										<g>

												<rect x="694.2" y="492.9" transform="matrix(0.5569 -0.8306 0.8306 0.5569 -106.1499 808.3961)" fill="none" width="20.8" height="21.6"/>
										</g>

											<rect x="706.8" y="497" transform="matrix(0.5569 -0.8306 0.8306 0.5569 -99.6965 809.34)" fill-rule="evenodd" clip-rule="evenodd" fill="#231F20"  width="3.9" height="2.2"/>

											<rect x="702.9" y="502.8" transform="matrix(0.5569 -0.8306 0.8306 0.5569 -106.1699 808.6991)" fill-rule="evenodd" clip-rule="evenodd" fill="#231F20"  width="3.9" height="2.2"/>

											<rect x="699.1" y="508.5" transform="matrix(0.5569 -0.8306 0.8306 0.5569 -112.6269 808.0476)" fill-rule="evenodd" clip-rule="evenodd" fill="#231F20"  width="3.9" height="2.2"/>
									</g>
								</g>
							</g>
						</g>
					</g>
					<g>
						<polygon fill="#ED3553" points="736,431.2 791.3,488 768.8,505 708.6,466.3 			"/>
						<circle fill="#ED3553" cx="720.5" cy="447.4" r="22.5"/>
						<circle fill="#ED3553" cx="780.1" cy="496.5" r="14"/>
					</g>
					<g>
						<path fill="#ED3553" d="M771.7,485.3c0,0-19.9,17.9-57,35.4c0,0,3.4,22.6,21.3,37c0,0,42-25.6,56.9-55.2L771.7,485.3z"/>
						<circle fill="#ED3553" cx="780.1" cy="496.5" r="14"/>
					</g>
					<polygon fill="#FFFFFF" points="746.5,558.8 722.1,515.5 706.2,524.7 724.8,569.6 		"/>
					<path fill="#954A37" d="M686.3,550.9c0.5,2,2.4,3.6,2.4,5.7c0,1.1-0.5,2.2-0.3,3.3c0.4,2,3.1,2.6,4,4.4c0.9,1.8,0,4,0.6,5.9
						c0.9,2.7,4.4,3.4,7.2,2.9c2.8-0.5,5.6-1.6,8.3-1c0.8,0.2,1.6,0.5,2.4,0.6c2.6,0.4,5-1.7,6.5-3.9c7.6-11.4,0.8-32.7-11.4-38.8
						c-6.1-3.1-9.1-1.8-11.9,4.3c-1.5,3.2-0.3,4.9-2.7,8.5C689.8,545.6,685.3,546.6,686.3,550.9z"/>
					<polygon fill="#FFFFFF" points="676.2,405.2 682,393.4 703.9,393.7 703.4,427.7 676.1,413.2 656.2,430.9 651.1,400.8 670.6,393.2
						"/>

						<ellipse transform="matrix(1.866697e-02 -0.9998 0.9998 1.866697e-02 278.9319 1031.957)" fill="none" stroke="#030202" stroke-width="1.798" stroke-miterlimit="10" cx="665.2" cy="373.9" rx="8.5" ry="3.9"/>
					<polyline fill="none" stroke="#030202" stroke-width="2.0594" stroke-miterlimit="10" points="666.1,308.2 657.4,352.6 671.1,348 664,367.1 		"/>

						<ellipse transform="matrix(0.234 -0.9722 0.9722 0.234 216.7418 919.7568)" fill="#030202" cx="692.1" cy="322.3" rx="7.9" ry="2.5"/>

						<rect x="687.3" y="303.4" transform="matrix(0.8381 0.5454 -0.5454 0.8381 280.4143 -332.1469)" fill="#030202" width="25" height="6.1"/>

						<ellipse transform="matrix(0.234 -0.9722 0.9722 0.234 189.1493 873.8196)" fill="#030202" cx="649.1" cy="316.9" rx="7.9" ry="2.5"/>

						<rect x="636.9" y="294.4" transform="matrix(-0.998 6.339084e-02 -6.339084e-02 -0.998 1316.3944 553.0157)" fill="#030202" width="25" height="6.1"/>
					<path fill="#030202" d="M616.7,275.8c-2.4,5.5-3.1,12-0.6,17.5c2.5,5.5,8.7,9.4,14.6,8c8.3-1.9,11.4-12,12.7-20.4
						c13.5,6.3,31.2,1.1,39.1-11.5c-0.5,8.3,5.3,16,12.6,20.1s15.8,5.3,24,6.4c-7.4,3.7-9.9,14.3-5.4,21.3c4.5,6.9,14.6,9.2,22,5.4
						s11.4-12.7,10.1-20.9c-0.9-5.9-5.7-11.9-11.6-11.2c7.4-11.4,4.8-28-5.6-36.7s-27.3-8.1-37.1,1.2c-7.2-9.3-19.8-14-31.3-12
						c-11.2,2-16.1,8.9-23,16.8c-4.8,5.5-2.7,2.6-9.2,5.4C622.9,267.3,619,270.7,616.7,275.8z"/>
				</g>
			</g>
			<g id="models">
				<g>
					<g>
						<g>
							<path fill="#EE3850" d="M479.6,107.9h89c0,23.6-9.4,46.2-26.1,63s-39.3,26.1-62.9,26.1L479.6,107.9z"/>
							<path fill="#63BF83" d="M479.6,107.9h-89c0,48.9,40.2,89,89,89c0.1,0,0.1,0,0.1,0L479.6,107.9z"/>
							<path fill="#30C2EE" d="M479.6,107.9h-89c0-48.9,40.2-89,89-89c0.1,0,0.1,0,0.1,0L479.6,107.9z"/>
							<path fill="#FAD363" d="M479.6,107.9h89c0-48.9-40.2-89-89-89c-0.1,0-0.1,0-0.1,0L479.6,107.9z"/>
							<g>
								<circle fill="none" stroke="#FFFFFF" stroke-width="0.1018" stroke-miterlimit="10" cx="479.4" cy="107.7" r="22.1"/>
								<ellipse fill="none" stroke="#FFFFFF" stroke-width="0.1018" stroke-miterlimit="10" cx="479.2" cy="107.3" rx="67.2" ry="67.2"/>
								<circle fill="none" stroke="#FFFFFF" stroke-width="0.1018" stroke-miterlimit="10" cx="479.1" cy="107.9" r="44.4"/>
							</g>
						</g>
					</g>
					<polygon fill="none" stroke="#231F20" stroke-width="4" stroke-miterlimit="10" points="428.3,63.4 453,143.7 509.2,143.7 509.2,75.2 		"/>
				</g>
				<g>
					<g>
						<g>
							<path fill="#EE3850" d="M248.3,108.3h88.6c0,23.5-9.4,46-26,62.7s-39.1,26-62.6,26L248.3,108.3z"/>
							<path fill="#63BF83" d="M248.4,108.3h-88.6c0,48.6,40,88.6,88.6,88.6c0.1,0,0.1,0,0.1,0L248.4,108.3z"/>
							<path fill="#30C2EE" d="M248.3,108.3h-88.6c0-48.6,40-88.6,88.6-88.6c0.1,0,0.1,0,0.1,0L248.3,108.3z"/>
							<path fill="#FAD363" d="M248.4,108.3H337c0-48.6-40-88.6-88.6-88.6c-0.1,0-0.1,0-0.1,0L248.4,108.3z"/>
							<g>
								<circle fill="none" stroke="#FFFFFF" stroke-width="0.1018" stroke-miterlimit="10" cx="248.1" cy="108.1" r="22"/>
								<ellipse fill="none" stroke="#FFFFFF" stroke-width="0.1018" stroke-miterlimit="10" cx="247.9" cy="107.7" rx="66.9" ry="66.9"/>
								<circle fill="none" stroke="#FFFFFF" stroke-width="0.1018" stroke-miterlimit="10" cx="247.8" cy="108.3" r="44.2"/>
							</g>
						</g>
					</g>
					<polygon fill="none" stroke="#231F20" stroke-width="4" stroke-miterlimit="10" points="201.8,159.4 281.7,134.9 281.7,79 213.6,79 		"/>
				</g>
				<g>
					<g>
						<g>
							<path fill="#EE3850" d="M704.6,108.2h88.7c0,23.5-9.4,46.1-26,62.7c-16.6,16.7-39.2,26-62.7,26L704.6,108.2z"/>
							<path fill="#63BF83" d="M704.7,108.2H616c0,48.7,40,88.7,88.7,88.7c0.1,0,0.1,0,0.1,0L704.7,108.2z"/>
							<path fill="#30C2EE" d="M704.6,108.3h-88.7c0-48.7,40-88.7,88.7-88.7c0.1,0,0.1,0,0.1,0L704.6,108.3z"/>
							<path fill="#FAD363" d="M704.7,108.3h88.7c0-48.7-40-88.7-88.7-88.7c-0.1,0-0.1,0-0.1,0L704.7,108.3z"/>
							<g>
								<circle fill="none" stroke="#FFFFFF" stroke-width="0.1018" stroke-miterlimit="10" cx="704.4" cy="108" r="22"/>
								<ellipse fill="none" stroke="#FFFFFF" stroke-width="0.1018" stroke-miterlimit="10" cx="704.2" cy="107.6" rx="66.9" ry="66.9"/>
								<circle fill="none" stroke="#FFFFFF" stroke-width="0.1018" stroke-miterlimit="10" cx="704.1" cy="108.2" r="44.2"/>
							</g>
						</g>
					</g>
					<polygon fill="none" stroke="#231F20" stroke-width="4" stroke-miterlimit="10" points="754.8,152.5 730.3,72.6 674.3,72.6 674.3,140.8 		"/>
				</g>
				<g>
					<g>
						<g>
							<path fill="#EE3850" d="M931.1,108.5h89.4c0,23.7-9.4,46.4-26.2,63.2s-39.5,26.2-63.2,26.2L931.1,108.5z"/>
							<path fill="#63BF83" d="M931.2,108.5h-89.4c0,49.1,40.4,89.4,89.4,89.4c0.1,0,0.1,0,0.1,0L931.2,108.5z"/>
							<path fill="#30C2EE" d="M931.1,108.5h-89.4c0-49.1,40.4-89.4,89.4-89.4c0.1,0,0.1,0,0.1,0L931.1,108.5z"/>
							<path fill="#FAD363" d="M931.2,108.5h89.4c0-49.1-40.4-89.4-89.4-89.4c-0.1,0-0.1,0-0.1,0L931.2,108.5z"/>
							<g>
								<circle fill="none" stroke="#FFFFFF" stroke-width="0.1018" stroke-miterlimit="10" cx="930.9" cy="108.3" r="22.2"/>
								<ellipse fill="none" stroke="#FFFFFF" stroke-width="0.1018" stroke-miterlimit="10" cx="930.7" cy="107.9" rx="67.5" ry="67.5"/>
								<circle fill="none" stroke="#FFFFFF" stroke-width="0.1018" stroke-miterlimit="10" cx="930.6" cy="108.5" r="44.6"/>
							</g>
						</g>
					</g>
					<polygon fill="none" stroke="#231F20" stroke-width="4" stroke-miterlimit="10" points="878.2,63.9 899.8,138.9 963,138.9 981.8,63.9 		"/>
				</g>
			</g>
			</svg>

    )
	}

}

export default BrainyBunch;
