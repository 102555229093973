import React from 'react';
import PropTypes from 'prop-types'

import styles from './styles.css'
import {Colors} from '../colors';

export default class Tabs extends React.Component {
  constructor(props) {
    super(props);
    this._changeTab = this._changeTab.bind(this)

    const active = props.active || Object.keys(this.props.tabs[0] || {})[0];

    this.state = {
      active
    };
  }

  _changeTab(active) {
    this.setState({
      active
    });
    if(this.props.onChange){
      this.props.onChange(active)
    }
  }

  _getTabsList(){
    return this.props.tabs[0]
  }

  _renderLabels() {

    const label = Object.keys(this._getTabsList()).map((value, index) => {
      if (value === this.state.active) {
        var active = {
          borderBottom: '2px solid' + Colors[this.props.highlightColor]
        };
      }

        return (
          <p style={active}
          onClick={e => this._changeTab(value)}
            key={value}
          >
            {this._getTabsList()[value].label}
          </p>
        )
    });
    return label;
  }

  render(props) {
    var divStyle = {
      maxWidth: this.props.maxWidth,
    }

    let borderStyle
    if (this.props.contentBorder == false){
      borderStyle = {
        borderColor: "white",
      }
    }

    return (
      <div style={divStyle} className={styles.tabsWrapper}>
        <div className={styles.tabLabels}>
          {this._renderLabels()}
        </div>
        <div style={borderStyle} className={styles.content}>
        <div data-qa-id="content">{this._getTabsList()[this.state.active].content}</div>
        </div>
      </div>
    )
  }
}

Tabs.propTypes = {
  maxWidth:PropTypes.number,
  onChange:PropTypes.func,
  highlightColor:PropTypes.oneOf([
    'red', 'green', 'blue', 'yellow', 'navy'])
}
