import React from 'react';
import {Profile} from '../profile'
import {ProfilePolygon} from '../profile-polygon'
import cx from 'classnames'
import PropTypes from 'prop-types';

import styles from './styles.css'

export default function ProfileMap(props){
  return (
    <div className={cx(styles.profileMapWrapper, props.isLocked && styles.blur)} >

      <Profile maxWidth={500} >
      {
        !props.isLocked && (
          <Profile.Element x={-100} y={-100} width={1000} height={1000} viewBox={"0 0 500 500"}>
            { props.profileScores &&
            <ProfilePolygon strokeWidth={props.strokeWidth} scores={props.profileScores} />}
            { props.stressScores && <ProfilePolygon strokeWidth={props.strokeWidth} strokeDasharray={5} scores={props.stressScores} />}
          </Profile.Element>
        )
      }
      </Profile>

    </div>
  )
}

ProfileMap.propTypes = {
  profileScores:PropTypes.object,
  strokeWidth:PropTypes.number,
  isLocked:PropTypes.bool
}
