import cx from 'classnames'
import React from 'react';

import styles from './styles.css'

export default function (props) {

  const data = [
    {id:1, name: 'Dave', age:50},
    {id:2, name: 'Kellie', age:42},
    {id:3, name: 'Max', age:12},
    {id:2, name: 'Jack', age:12}
  ]

  const listItems = data.map((d) =>
      <tr>
        <td>{d.id}</td>
        <td>{d.name}</td>
        <td>{d.age}</td>
      </tr>);

    return (
      <table className={styles.table}>
        <tbody>
          {listItems }
        </tbody>
      </table>
    );
}
