import React from 'react'
/**
  Renders the basic profile graphic with properly cacluated tick marks and axis.
  It's basically a glorified svg builder, but done in code to ensure the graphics
  match up with math done in profile drawing components like ProfilePolygon
*/

class Background extends React.Component{

  _axis(){
    return (
      <g>
        <line strokeWidth=".25" stroke="black" x1="0" x2="0" y1="130" y2="-130" ></line>
        <line strokeWidth=".25" stroke="black" y1="0" y2="0" x1="130" x2="-130" ></line>
      </g>
    )
  }

  _circles(){
    let circles = []
    for(let i=0; i<130; i+=33){
      circles.push(<circle key={`${i}_cir`} cx="0" cy="0" r={i} stroke="white" opacity="0.50" fill="none" />)
    }
    return circles
  }

  _ticks(){
    let horTicks = []
    let verTicks = []
    for(let i=-130; i<=130; i+=10){
      horTicks.push(<line key={`${i}_hor`} x1="-2" y1={i} x2="2" y2={i} strokeWidth=".25" stroke="black" />)
      verTicks.push(<line key={`${i}_ver`} x1="-2" y1={i} x2="2" y2={i} strokeWidth=".25" stroke="black" />)
    }
    return (
      <g transform="rotate(-45)">
        {horTicks}
        {this._tickLabels()}
        <g transform="rotate(90)">
          {verTicks}
        </g>
        {this._axis()}
      </g>
    )
  }

  _tickLabels(){
    let tickLabels = []

    for(let i=-130; i<0; i+=10){
      let x = -10
      let y = i + 2
      tickLabels.push(
        <text key={`${i}_lab`} style={{fontWeight: "bold"}} transform={`rotate(45, ${x}, ${y})`} x={x} y={y} fontSize="6.5" fill="black">{i*-1}</text>
      )
    }

    return tickLabels
  }

  constructor(props){
    super()
    this.showTicks = props.showTicks == undefined ? true : props.showTicks
  }

  render(){
    let ticks = this._ticks()

    return (
      <svg viewBox="-130 -130 260 260" width="100" height="100" fill="lightgray">
        <svg width="100%" x="-130" y="-130" viewBox="0 0 700 700">
          <g>
          	<path fill="#2AC0F0" d="M350.5,351.6L0.5,353c0-0.5,0-0.9,0-1.4c0-192,158-350,350-350c0.5,0,1,0,1.4,0L350.5,351.6z"/>
          	<path fill="#FDD431" d="M350.5,353l350,1.4c0-0.5,0-0.9,0-1.4c0-191.6-157.4-349.5-349.1-350L350.5,353z"/>
          	<path fill="#56BC7A" d="M350.5,351.1l-350-1.4c0,0.5,0,0.9,0,1.4c0,192,158,350,350,350c0.5,0,1,0,1.4,0L350.5,351.1z"/>
          	<path fill="#EF3C50" d="M350.5,351.1l350-1.4c0,0.5,0,0.9,0,1.4c0,191.6-157.4,349.5-349.1,350L350.5,351.1z"/>
          </g>
        </svg>
        {this._circles()}
        {this.showTicks && this._ticks()}
        <svg x="-130" y="-130" viewBox="-100 -100 200 200" width="100%">
          {this.props.children}
        </svg>
      </svg>
    )
  }
}

function Circle(props) {
  return(
    <svg x={props.x} y={props.y} viewBox="0 0 100 100" width={props.width} height={props.width} >
      <circle cx="50" cy="50" r="50" fill="white" opacity="0.6" stroke="rgba(0,0,0,0.25)" />
      {props.children}
    </svg>
  )
}

function Element(props) {
  return(
    <svg x={props.x} y={props.y} viewBox={props.viewBox} width={props.width} height={props.height}>
      {props.children}
    </svg>
  )
}

export class Profile extends React.Component {
  static Background = Background;
  static Circle = Circle;
  static Element = Element;

  render(props){

    let maxWidth
    if (this.props.maxWidth) {
      maxWidth = { maxWidth: this.props.maxWidth + 'px', width: '100%' }
    } else {
      maxWidth = { maxWidth: '100%', width: '100%' }
    }

    return (
      <div style={maxWidth}>
        <svg viewBox="0 0 100 100" width="100%">
        <Background>
           { this.props.children }
        </Background>
        </svg>
      </div>
    )
  }
}
