import cx from 'classnames'
import React from 'react';
import PropTypes from 'prop-types';
import FontAwesomeIcon from '@fortawesome/react-fontawesome'
import {faSun, faMoon, faSignInAlt, faSignOutAlt} from '@fortawesome/fontawesome-free-solid'

import styles from './styles.css'

export default function Scale(props) {

  function renderCircle(initials) {
    if (initials) {
      return (
        <p className={styles.initialCircle}> {initials} </p>
      )
    } else {
      return (
        <div className={styles.dot}></div>
      )
    }
  }

  let segments = Array.from(Array(props.segments).keys())

  let maxWidth = {maxWidth: props.maxWidth+'px'}

  return (
    <div className={styles.energyWrapper}>

      { props.type == "day" &&
        <FontAwesomeIcon icon={["fas","moon"]} className={styles.icon}/>
      }

      { props.type == "intro" &&
        <FontAwesomeIcon icon={["fas","sign-in-alt"]} className={styles.icon}/>
      }

      <div style={maxWidth} className={styles.bar}>
        {
          segments.map ( x => {
            return (
              <div className={cx(styles.segment, props.showSegments == true && styles.segmentSections)}>
                {props.thinkerA != undefined && props.thinkerA.score == x &&
                  <>{renderCircle(props.thinkerA.initials)} </>
                }
                {props.thinkerB != undefined && props.thinkerB.score == x &&
                  <>{renderCircle(props.thinkerB.initials)} </>
                }
              </div>
            )
          })
        }
      </div>

      { props.type == "day" &&
        <FontAwesomeIcon icon={["fas","sun"]} className={styles.icon}/>
      }

      { props.type == "intro" &&
        <FontAwesomeIcon icon={["fas","sign-out-alt"]} className={styles.icon}/>
      }

    </div>
  )
}

Scale.propTypes = {
  percentage:PropTypes.number,
  maxWidth:PropTypes.number,
  maxHeight:PropTypes.number,
  visibility:PropTypes.string,
  title:PropTypes.string
}
