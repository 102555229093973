import React from 'react';
import styles from './styles.css'

const chart_colors = {
  "EXTRA_LIGHT_BLUE": "B3E3F5",
  "LIGHT_BLUE": "80D3F1",
  "BLUE": "2CC3F0",
  "DARK_BLUE": "0F8FB0",
  "EXTRA_LIGHT_GREEN":"CBE7D3",
  "LIGHT_GREEN": "9FD5B3",
  "GREEN": "63C084",
  "DARK_GREEN": "28A462",
  "EXTRA_LIGHT_RED": "F1BBC3",
  "LIGHT_RED": "F38595",
  "RED": "EF354E",
  "DARK_RED": "BA2031",
  "EXTRA_LIGHT_YELLOW": "FCF7CB",
  "LIGHT_YELLOW": "FBE98E",
  "YELLOW": "FDD742",
  "DARK_YELLOW": "C7A52E",
  "NAVY": "214263",
  "LIGHT_NAVY": "C6D9EC",
  "LIGHT_GREY": "CBCBCA",
  "GREY": "9D9D9D",
  "DARK_GREY": "4E504F",
  "BLACK": "000000"
}

export const ChartColors = {
  A:{
    default: chart_colors.BLUE,
    0: chart_colors.DARK_BLUE,
    1: chart_colors.BLUE,
    2: chart_colors.LIGHT_BLUE,
    3: chart_colors.EXTRA_LIGHT_BLUE
  },
  B:{
    default: chart_colors.GREEN,
    0: chart_colors.DARK_GREEN,
    1: chart_colors.GREEN,
    2: chart_colors.LIGHT_GREEN,
    3: chart_colors.EXTRA_LIGHT_GREEN
  },
  C:{
    default: chart_colors.RED,
    0: chart_colors.DARK_RED,
    1: chart_colors.RED,
    2: chart_colors.LIGHT_RED,
    3: chart_colors.EXTRA_LIGHT_RED
  },
  D:{
    default: chart_colors.YELLOW,
    0: chart_colors.DARK_YELLOW,
    1: chart_colors.YELLOW,
    2: chart_colors.LIGHT_YELLOW,
    3: chart_colors.EXTRA_LIGHT_YELLOW
  }
}

export const Colors = {
    green: "#" + chart_colors.GREEN,
    red: "#" + chart_colors.RED,
    blue: '#' + chart_colors.BLUE,
    yellow: "#" + chart_colors.YELLOW,
    navy: "#" + chart_colors.NAVY,
    lightNavy: "#" + chart_colors.LIGHT_NAVY,
    grey: "#" + chart_colors.DARK_GREY,
    white: "#FFF"
  }

export const ColorsByQuadrant = {
  A: Colors.blue,
  B: Colors.green,
  C: Colors.red,
  D: Colors.yellow
}

export default function ColorExamples() {

  return (
    <div className={styles.colorsWrapper}>
    {
      Object.keys(chart_colors).map(colors => {

        var fill = {
          backgroundColor: '#' + chart_colors[colors]
        }

        return (
          <div className={styles.colorHex}>
            <div className={styles.color} style={fill}></div>
            <p>#{chart_colors[colors]}</p>
          </div>
        )
      })
    }
  </div>
)

}
