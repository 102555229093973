import cx from 'classnames'
import React from 'react';
import PropTypes from 'prop-types'

import styles from './styles.css'
import {ColorsByQuadrant} from '../colors';
import QuadIcon from '../quadrant-icons'

export default function Tile (props) {

  var tileStyles = {
    borderColor: ColorsByQuadrant[props.quad],
    maxWidth: props.maxWidth + "px"
  }

  return (
    <div className={cx(styles.tile, props.className)} style={tileStyles}>
      { props.quad &&
        <div className={styles.tileFlexWrapper}>
          <div className={styles.quadWrapper}>
            <h3>{props.quad}</h3>
            <QuadIcon quad={props.quad} maxWidth={20}/>
          </div>
          <div className={styles.content}>
          {props.children}
          </div>
        </div>
      }
      { !props.quad &&
        <div>
          {props.children}
        </div>
      }
    </div>
  )
}

Tile.propTypes = {
  maxWidth:PropTypes.number,
  quad:PropTypes.oneOf([
    'A', 'B', 'C', 'D'])
}
